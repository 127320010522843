import axios from "axios";

const baseURL = process.env.REACT_APP_API_PRECIOS_BACK_PROD;
// const baseURL = process.env.REACT_APP_API_PRECIOS_BACK;

const apiInstancePrecios = axios.create({
	baseURL,
});

export default apiInstancePrecios;
