import React, { useContext, useEffect, useState } from "react";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { getHorarioBolsa } from "../api/mercadoApi";
import { AuthContext } from "../context/authContext/AuthContext";
import { TipoAccount } from "../enums/enums";
import { UserContext } from "../context/userContext/UserContext";
import { getUserData } from "../api/userApi";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";
import { TitularConEmpresa } from "../interfaces/datosPersonalesInterface";

const useCargarInfoHomeHook = () => {

    const [loadingData, setLoadingData] = useState(true)
    const [errorDatosPersonales, setErrorDatosPersonales] = useState(false);

    const { accountType, accountSearched } = useContext(AuthContext);
    const { onChangeHorariosExterior, onChangeHorariosLocal } = useContext(MercadoContext)
    const { setUserData } = useContext(UserContext)

    useEffect(() => {
        const cargarInfo = async () => {
            const tokenStorage = localStorage.getItem("token");
            const accountTypeStorage = localStorage.getItem("accountType");
            if (tokenStorage) {
                try {
                    setErrorDatosPersonales(false)
                    setLoadingData(true)
                    let resp
                    if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                        const accountFormateada = formatearAccountSearched(accountSearched)
                        resp = await Promise.all([getUserData(tokenStorage, accountFormateada), getHorarioBolsa(tokenStorage)])
                        // respUserData = await getUserData(tokenStorage, accountFormateada);
                        // horariosBolsa = await getHorarioBolsa(tokenStorage);
                    } else {
                        resp = await Promise.all([getUserData(tokenStorage), getHorarioBolsa(tokenStorage)])
                    }
                    setUserData(resp[0]?.data?.body);
                    onChangeHorariosLocal(
                        resp[1].data.body[0].opening,
                        resp[1].data.body[0].closing,
                    );
                    onChangeHorariosExterior(
                        resp[1].data.body[1].opening,
                        resp[1].data.body[1].closing,
                    );
                } catch (error) {
                    setErrorDatosPersonales(true)
                    console.log(error);
                } finally {
                    setLoadingData(false)
                }
            }
        }
        cargarInfo()
    }, [accountSearched])

    return {
        loadingData,
        errorDatosPersonales,
    };
};

export default useCargarInfoHomeHook;
