import { useContext } from 'react'
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMercadoHook } from '../../../hooks/useMercadoHook';
import { useEspeciesHook } from '../../../hooks/useEspeciesHook';
import { TipoMercado } from '../../../enums/enums';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import CardEspecieLocal from '../CardEspecieLocal/CardEspecieLocal';
import CardEspecieExterior from '../CardEspecieExterior/CardEspecieExterior';
import { useMenuEspeciesHook } from '../../../hooks/useMenuEspeciesHook';
import { useSearchMercadoHook } from '../../../hooks/useSearchMercadoHook';
import {
    Container,
    ContainerBuscador,
    ContainerEspecies,
    ContainerEspeciesOpciones,
    ContainerNoFavoritos,
    ContainerOpciones,
    ContainerSinEspeciesOpciones,
    ContainerSinFavoritos,
    ContainerTitle,
    EspecieBuscador,
    StarTitle,
    TextoNoEspeciesOpciones,
    TextoNoFavoritos,
    Title,
    TituloEspecie
} from './elements';
import Lottie from 'lottie-react';
import MenuEspeciesOpcionesSearchBar from '../MenuEspeciesOpcionesSearchBar';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import { Theme } from '../../../enums/theme';
import { ThemeContext as ContextTheme } from '../../../context/themeContext/ThemeContext';
import MenuPopUp from '../../MenuPopUp/MenuPopUp';
import useEspecieHookListaEspecie from '../../../hooks/useEspecieHookListaEspecie';

export const CardListaEspecies = () => {

    const { actualMenuEspecies, listaMenuEspecies, setActualMenuEspecies } = useMercadoHook();
    // const { hayFavoritos, filtrado, dataOpciones, loadingEspecies } = useEspeciesHook(actualMenuEspecies);
    const { hayFavoritos, filtrado, dataOpciones, loadingEspecies } = useEspecieHookListaEspecie(actualMenuEspecies)
    const { tipoMercado, especieOpciones } = useContext(MercadoContext)
    const { open, handleClick, handleClose, anchorEl } = useMenuEspeciesHook(setActualMenuEspecies);

    const { width } = useWindowDimensions();

    const { primaryColor } = useContext(ThemeContext);
    
    const { theme } = useContext(ContextTheme);

    const {
        filterDataOpciones,
        openMenuOpciones,
        anchorElMenuOpciones,
        handleCloseMenuOpciones,
        handleOpenMenuOpciones,
        actualMenuOpciones
    } = useSearchMercadoHook(dataOpciones);

    

    return (
        <Container>
            <ContainerTitle>
                <StarTitle>
                    {
                        actualMenuEspecies === 'FAVORITOS' ?
                            <StarRoundedIcon sx={{ color: primaryColor }} />
                            :
                            <CircleOutlinedIcon sx={{ color: primaryColor }} />
                    }
                    <Title>{actualMenuEspecies === 'FAVORITOS' ? 'Favoritos' : actualMenuEspecies}</Title>
                </StarTitle>
                <div>
                    <div onClick={handleClick}>
                        <KeyboardArrowRightIcon
                            sx={{
                                color: primaryColor,
                                marginRight: '0.6em',
                                marginTop: '0.2em',
                                cursor: 'pointer',
                                fontSize:'32px',
                            }} />
                    </div>
                    <MenuPopUp 
                        open={open}
                        anchorEl={anchorEl}
                        handleCloseMenu={handleClose}
                        listaMenu={listaMenuEspecies}
                        actualMenu={''}
                    />
                </div>
            </ContainerTitle>
            {
                actualMenuEspecies === "OPCIONES" ?
                    <ContainerOpciones>
                        <TituloEspecie>ESPECIE</TituloEspecie>
                        <ContainerBuscador >
                            <EspecieBuscador onClick={(e) => handleOpenMenuOpciones(e)}
                            >{actualMenuOpciones}</EspecieBuscador>
                            <MenuEspeciesOpcionesSearchBar
                                open={openMenuOpciones}
                                anchorEl={anchorElMenuOpciones}
                                handleClose={handleCloseMenuOpciones}
                                listaMenuEspecies={especieOpciones ? especieOpciones.map(e => e.especie) : []}
                            />
                        </ContainerBuscador>
                        {
                            !filterDataOpciones.length ?
                                <ContainerSinEspeciesOpciones>
                                    <TextoNoEspeciesOpciones>No hay opciones de esta especie</TextoNoEspeciesOpciones>
                                </ContainerSinEspeciesOpciones>
                                :
                                <ContainerEspeciesOpciones>
                                    {filterDataOpciones.map(especie =>
                                        <CardEspecieLocal
                                            key={especie.especie}
                                            especie={especie}
                                            actualMenuEspecies={actualMenuEspecies}
                                        />)}
                                </ContainerEspeciesOpciones>
                        }
                    </ContainerOpciones>
                    :
                    null
            }

            {actualMenuEspecies === 'FAVORITOS' ?
                !hayFavoritos() ?
                    <ContainerSinFavoritos>
                        <ContainerNoFavoritos>
                            <TextoNoFavoritos>Todavía no tenés favoritos</TextoNoFavoritos>
                        </ContainerNoFavoritos>
                    </ContainerSinFavoritos>
                    :
                    loadingEspecies ?
                        <div style={{ overflow: 'hidden', display: 'flex', flexGrow: width > 992 ? 1 : undefined, height: width > 992 ? '1em' : undefined}}>
                            <Lottie
                                animationData={
                                    theme === Theme.Light 
                                    ? require('../../../jsons-animation/loading-pagina-completa.json') 
                                    : require('../../../jsons-animation/loading-pagina-completa-dark.json')}
                                autoPlay={true}
                                loop={true}
                                style={{ height: width > 992 ? '100%' : 'auto', width: '100%' }}
                            />
                        </div>
                        :
                        <ContainerEspecies>
                            {filtrado?.map(especie =>
                                tipoMercado === TipoMercado.Local ? (
                                    <CardEspecieLocal
                                        key={especie.especie}
                                        especie={especie}
                                        actualMenuEspecies={actualMenuEspecies}
                                    />
                                ) : (
                                    <CardEspecieExterior
                                        key={especie.especie}
                                        especie={especie}
                                    />
                                )
                            )}
                        </ContainerEspecies>
                :
                loadingEspecies ?
                    <div style={{ overflow: 'hidden', display: 'flex', flexGrow: width > 992 ? 1 : undefined, height: width > 992 ? '1em' : undefined }}>
                        <Lottie
                            animationData={
                                theme === Theme.Light 
                                ? require('../../../jsons-animation/loading-pagina-completa.json') 
                                : require('../../../jsons-animation/loading-pagina-completa-dark.json')}
                            autoPlay={true}
                            loop={true}
                            style={{ height: width > 992 ? '100%' : 'auto', width: '100%' }}
                        />
                    </div>
                    :
                    actualMenuEspecies !== "OPCIONES" ?
                        <ContainerEspecies>
                            {filtrado?.map(especie =>
                                tipoMercado === TipoMercado.Local ? (
                                    <CardEspecieLocal
                                        key={especie.especie}
                                        especie={especie}
                                        actualMenuEspecies={actualMenuEspecies}
                                    />
                                ) : (
                                    <CardEspecieExterior
                                        key={especie.especie}
                                        especie={especie}
                                    />
                                )
                            )}
                        </ContainerEspecies>
                        : null
            }
        </Container>
    )
}