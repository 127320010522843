import styled from "styled-components";


export const Container = styled.div`
    margin-top: 0.5em;
`

export const ContainerEspecies = styled.div`
    // max-height: 43vh;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    // overflow-y: scroll;	
    padding-bottom: 0em;
	margin-top: 0.2em;
    background-color: ${({ theme }) => theme.tabBarColor};
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`


export const TituloRenta = styled.h3`
	color: ${({ theme }) => theme.primaryColor};
	margin: 0em 0.5em 0.5em;
    font-size: 16px;
    font-weight: normal;
`

export const SecondaryContainer = styled.div`
    display: grid;
    // grid-template-columns: 1.5fr repeat(2, 0.8fr) repeat(2, 1fr) 0.8fr 1fr 1.5fr;
    grid-template-columns: 1fr 0.8fr 1fr 0.8fr 1fr 1.5fr;
    text-align: center;
`

export const SubtotalTexto = styled.p`
    color: ${({ theme }) => theme.primaryColor};
	margin: 0.5em 0em 0em;
    text-align: end;
    font-size: 16px;
`
