import { useContext } from 'react'
import { ContainerTitulos, ContainterCirculo, TextoDatos } from './elements'
import { ThemeContext } from 'styled-components';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { Order } from '../../../interfaces/ordersInterface';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import CircleIcon from '@mui/icons-material/Circle';
import { useModalHook } from '../../../hooks/useModalHook';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import useCancelarOrdenDelDia from '../../../hooks/useCancelarOrdenDelDia';
import { LoadingContainer } from '../../../elements/loadingContainer';
import { CircularProgress } from '@mui/material';

interface Props {
    data: Order[] | undefined;
    formatEstado: (estado: string) => string;
    loading: boolean;
}

const OrdenesDelDia = ({ data, formatEstado, loading }: Props) => {

    const {
        primaryBackgroundColor,
        alterBackgroundCardsHome,
        rojoPorcentaje,
        buyColor,
        amarilloCirculoMercado,
        loadingColor
    } = useContext(ThemeContext);

    const { cancelarOrden, setEspecieACancelar, setNroOrdenACancelar, loadingCancelOrder, nroOrdenACancelar } = useCancelarOrdenDelDia();

    const {
        open,
        handleOpen,
        handleClose,
        handleAceptarModal
    } = useModalHook(cancelarOrden)

    return (
        <div>
            {
                loading
                    ? <LoadingContainer style={{ height: '100%', marginTop: '1.5em' }}>
                        <CircularProgress style={{ color: loadingColor, width: '20px', height: '20px' }} />
                    </LoadingContainer>
                    : 
                    data &&
                    data.map((d, i: number) =>
                        <ContainerTitulos
                            key={i}
                            style={{
                                backgroundColor: i % 2 !== 0
                                    ? primaryBackgroundColor
                                    : alterBackgroundCardsHome
                            }}
                        >
                            <TextoDatos
                                style={{
                                    textAlign: 'start',
                                    fontWeight: 700,
                                }}
                            >
                                {formatEspeciesExterior(d.ticker)}
                            </TextoDatos>
                            <TextoDatos style={{ textAlign: 'center' }}>{d.tipoOrden}</TextoDatos>
                            <TextoDatos>{separacionDecimal(Number(d.cantidad))}</TextoDatos>
                            <TextoDatos>{formatCurrency(d.precio)}</TextoDatos>

                            <ContainterCirculo>
                                <div
                                    onClick={() => {
                                        if (formatEstado(d.estado) === 'E/P'){
                                            handleOpen()
                                            setEspecieACancelar(d.especie)
                                            setNroOrdenACancelar(d.nroOrden)
                                        }
                                    }}
                                >
                                    {
                                        loadingCancelOrder && nroOrdenACancelar === d.nroOrden
                                            ? <LoadingContainer style={{ height: "100%", margin: 0, }}>
                                                <CircularProgress style={{ color: loadingColor, width: "13px", height: "13px", marginLeft: '0.5em' }} />
                                            </LoadingContainer>
                                            : <CircleIcon
                                                style={{
                                                    color: formatEstado(d.estado) === 'C'
                                                        ? rojoPorcentaje
                                                        : formatEstado(d.estado) === 'T'
                                                            ? buyColor
                                                            : amarilloCirculoMercado,
                                                    fontSize: '12px',
                                                    cursor: formatEstado(d.estado) === 'E/P' ? "pointer" : undefined,
                                                    marginLeft: '0.8em'
                                                }}
                                            />
                                    }
                                </div>
                            </ContainterCirculo>
                            <ModalConfirmacion
                                open={open}
                                handleClose={handleClose}
                                handleConfirmarModal={handleAceptarModal}
                                tituloModal={'¿Seguro que desea cancelar la orden en proceso?'}
                                // textoModal={'El proceso puede tardar hasta 96hs. hábiles.'}
                                textoConfirmarModal={'Aceptar'}
                            />
                        </ContainerTitulos>
                    )}
        </div>
    )
}

export default OrdenesDelDia