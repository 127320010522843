import styled from "styled-components";

export const SecondaryContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr  1.4fr 0.8fr repeat(4, 1.6fr) 2fr;
  @media (max-width: 1400px) {
  grid-template-columns: 2fr  1.4fr 0.8fr repeat(4, 1.6fr) 2.5fr;
  }
`;

export const Texto = styled.p`
  margin: 0;
  padding: 0 0em 0 0.3em;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`;

export const Numeros = styled.p`
  margin: 0;
  padding: 0 1em 0 0.5em;
  color: ${({ theme }) => theme.primaryColor};
  text-align: end;
  font-size: 14px;
  cursor: pointer;
  align-content: center;
`;

export const Variacion = styled.p`
  margin: 0;
  padding: 0 1.2em 0 0em;
  color: ${({ theme }) => theme.primaryColor};
  text-align: end;
  font-size: 14px;
`;

export const ContenedorElementosCentradosCompraVenta = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  background-color: ${(props) => props.color || props.theme.backgroundMercadoNuevo}  ;
  transition: background-color 0.5s;
`;

export const ContenedorElementosCentrados = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const Hora = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.primaryColor};
  text-align: end;
  font-size: 12px;
  align-content: center;
  padding-right: 0.2em;
`;
