import { useContext, useState } from "react";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { AuthContext } from "../context/authContext/AuthContext";
import { UserContext } from "../context/userContext/UserContext";
import { TipoAccount, TipoMercado } from "../enums/enums";
import { deletOrders } from "../api/userApi";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";

const useCancelarOrdenDelDia = () => {
    
    const { tipoMercado, dataExterior, dataLocal } = useContext(MercadoContext);
    const { token, accountType, accountSearched } = useContext(AuthContext);
    const { cancelOrder } = useContext(UserContext);
    const [especieACancelar, setEspecieACancelar] = useState("");
    const [nroOrdenACancelar, setNroOrdenACancelar] = useState("");
    const [loadingCancelOrder, setLoadingCancelOrder] = useState(false);


    async function cancelarOrden() {
        setLoadingCancelOrder(true);
        const data = tipoMercado === TipoMercado.Local ? dataLocal : dataExterior
        const accountTypeStorage = localStorage.getItem("accountType");
        try {
            const especie = data?.filter(e => e.especie === especieACancelar);
            let nombreEspecie = '';
            if (especie && especie.length === 1) {
                nombreEspecie = especie[0].especie_completa ?? especie[0].especie;
            }
            if (token && nombreEspecie) {
                let accountSearchFormateada
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
                    accountSearchFormateada = formatearAccountSearched(accountSearched)
                }
                const resp = await deletOrders(token, nombreEspecie, nroOrdenACancelar, especieACancelar, accountSearchFormateada);
                if (!resp.data.error) {
                    cancelOrder(nroOrdenACancelar);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setEspecieACancelar("")
            setNroOrdenACancelar("")
            setLoadingCancelOrder(false);
        }
    }
    
    return {
        cancelarOrden,
        setEspecieACancelar,
        setNroOrdenACancelar,
        loadingCancelOrder,
        nroOrdenACancelar,
    };
};

export default useCancelarOrdenDelDia;
