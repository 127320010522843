export const arrMenuListMercadoLocal = [
    {
        title: 'FAVORITOS',
    },
    {
        title: 'CEDEARS',
        panel: 'CED',
    },
    {
        title: 'LÍDER',
        panel: 'LID',
    },
    {
        title: 'GENERAL',
        panel: 'GEN',
    },
    {
        title: 'OPCIONES',
        panel: 'OPC',
    },
    {
        title: 'BONOS',
        panel: 'BON',
    },
    // {
    //   title: 'ROFEX',
    //   panel: 'ROFEX',
    // },
];

export const arrMenuListMercadoLocalSoloTitulos = [
    'FAVORITOS',
    'LÍDER',
    'GENERAL',
    'CEDEARS',
    'BONOS',
    "OPCIONES",
    // 'ROFEX',
];

export const arrMenuListMercadoLocalSinFavoritos = [
    'CEDEARS',
    'LÍDER',
    'GENERAL',
    "OPCIONES",
    'BONOS',
    // 'ROFEX',
];

export const arrMenuListMercadoInternacional = [
    {
        title: 'FAVORITOS',
    },
    {
        title: 'GLOBAL',
        panel: 'GLOBAL',
    },
    {
        title: '30 del DOW',
        panel: 'DOW30',
    },
    {
        title: "ETF",
        panel: 'ETF',
    },
    {
        title: "ADR",
        panel: 'ADRARG',
    },
];

export const arrMenuListMercadoInternacionalSoloTitulos = [
    'FAVORITOS',
    // 'GLOBAL',
    '30 del DOW',
    "ETF",
    "ADR",
];

export const arrMenuListMercadoInternacionalSinFavoritos = [
    'GLOBAL',
    '30 del DOW',
    "ETF",
    "ADR",
];
