import axios from "axios";

// const baseURL = 'http://localhost:3000/api';
const baseURL = process.env.REACT_APP_API_HOST;

const apiBackInstance = axios.create({
	baseURL,
});

export default apiBackInstance;

