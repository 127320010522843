import { useContext, useEffect, useState } from "react";
import { arrMenuListMercadoInternacionalSoloTitulos, arrMenuListMercadoLocalSoloTitulos } from "../helpers/arrayEspecies";
import { TipoMercado } from "../enums/enums";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";


const useMercadoNuevoHook = () => {

    const { tipoMercado } = useContext(MercadoContext)
    const [actualMenuEspecies, setActualMenuEspecies] = useState('FAVORITOS');

    const [listaMenuEspecies, setListaMenuEspecies] = useState(
        tipoMercado === TipoMercado.Local
            ? arrMenuListMercadoLocalSoloTitulos
            : arrMenuListMercadoInternacionalSoloTitulos,
    );

    useEffect(() => {
        // setea el menu en Favoritos cuando cambia el mercado
            setActualMenuEspecies('FAVORITOS');
        }, [tipoMercado]);
        // Cambia el menu segun lo que este seleccionado o el tipo de mercado
        useEffect(() => {
            setListaMenuEspecies(
                tipoMercado === TipoMercado.Local
                    ? arrMenuListMercadoLocalSoloTitulos
                    : arrMenuListMercadoInternacionalSoloTitulos,
            );
        }, [actualMenuEspecies, tipoMercado]);

    return {
        setActualMenuEspecies, 
        actualMenuEspecies, 
        listaMenuEspecies, 
    };
};

export default useMercadoNuevoHook;
