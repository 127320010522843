import { useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import moment from "moment";
import { NotificacionContext } from "../context/notificacionContext/NotificacionContext";
import { AuthContext } from "../context/authContext/AuthContext";
import { UserContext } from "../context/userContext/UserContext";
import { getSentNotifications } from "../api/notificacionesApi";
import { getAlerts } from "../api/userApi";
import { getUserId } from "../api/alertasApi";

export const useSocketConnection = () => {
  const { setLogoOn } = useContext(NotificacionContext);
  const { notifications, sentAlerts, setNotifications, setSentAlerts } =
    useContext(UserContext);
  const { token } = useContext(AuthContext);

  const uriApp = "wss://www.app-api.rava.tv";
  const uriCrm = process.env.REACT_APP_CRM_SOCKET_LOCAL;

  // SOCKET

  useEffect(() => {
    const socket = io(uriApp, {
      transports: ["websocket"],
      path: "/socket.io",
      autoConnect: false,
    });

    socket.connect();

    socket.on("connect", () => {
      console.log("WebSocket connected!!");
    });

    socket.on("alerta", (arg1) => {
      setLogoOn(true);
    });
    // if (uriCrm) {
    //     notificacionesSocketConnection(uriCrm)
    // }
    return () => {
      socket.disconnect();
      socket.off("connect", () => {
        console.log("WebSocket disconnected!");
      });
    };
  }, [uriApp, uriCrm]);

    // const notificacionesSocketConnection = (uri: string) => {
  const notificacionesSocketConnection = (uri: string) => {
    const socket = io(uri, {
      transports: ["websocket"],
      path: "/socket.io",
      autoConnect: false,
    });

    //     const socket = io(uri, { transports: ["websocket"], path: "/socket.io", autoConnect: false });

    //     socket.connect()
    socket.connect();

    //     // console.log(`Connecting crm socket...`);
    //     socket.on("notificacionWebApp", (arg1) => {
    //         setLogoOn(true);
    //     });
    // console.log(`Connecting crm socket...`);
    socket.on("notificacionWebApp", (arg1) => {
      setLogoOn(true);
    });

    return () => {
      socket.off();
    };
  };

  // NOTIFICACIONES NO VISTAS

  const [userId, setUserId] = useState("");

  useEffect(() => {
    checkNotifications();
  }, [notifications.length === 0, sentAlerts.length === 0, userId]);

  const checkNotifications = async () => {
    await getId();
    await getData();

    if (sentAlerts.length !== 0) {
      const today = moment().format();
      sentAlerts.forEach((e) => {
        if (e.seen === false && moment(today).diff(e.date, "hours") <= 24) {
          setLogoOn(true);
        }
      });
    }

    if (notifications.length !== 0 && userId !== "") {
      notifications.forEach((e) => {
        let id = false;
        e.seen.forEach((e) => {
          if (e.userId === userId) {
            id = true;
          }
        });
        if (id === false) {
          setLogoOn(true);
        }
      });
    }
  };

  const getId = async () => {
    if (token) {
      try {
        let userId = await getUserId(token);
        setUserId(userId.data.body);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getData = async () => {
    if (token) {
      try {
        const resNotification = await getSentNotifications(token);
        const resAlerts = await getAlerts(token);
        setNotifications(resNotification.data.body.sent);
        setSentAlerts(resAlerts.data.body.alertsSentList);
      } catch (error) {
        console.log(error);
      }
    }
  };
};

// const notificacionesSocketConnection = (uri: string, opts?: Partial<ManagerOptions & SocketOptions | undefined>) => {

//     const socket = io(uri, opts);

//     socket.connect()

//     console.log(`Connecting crm socket...`);
//     socket.on("notificacionWebApp", (arg1) => {
//         setLogoOn(true);
//     });

//     return () => {
//         socket.off();
//     }
// };
