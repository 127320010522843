import React, { useContext } from 'react'
import { ContainerExterior, Texto, TextoEspecie } from './elements';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import useDetalleEspecieTenencias from '../../../hooks/useDetalleEspecieTenencias';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';

interface Props {
    especie: string;
    precio: number;
    cantidad: number;
    importe: string | number;
    key: string;
    pintar: boolean;
    costoHistorico: number;
    variacionDia: number;
    variacionHistorico: number | null;
}


const CardDetalleEspecieHomeExterior = ({
    especie,
    precio,
    cantidad,
    importe,
    pintar,
    costoHistorico,
    variacionDia,
    variacionHistorico,
}: Props) => {

    const { width } = useWindowDimensions();

    const { primaryBackgroundColor, alterBackgroundCardsHome, rojoPorcentaje } = useContext(ThemeContext);

    const { onClickEspecie } = useDetalleEspecieTenencias();

    return (
        <ContainerExterior
            style={{
                backgroundColor: width > 992 && pintar ? alterBackgroundCardsHome : primaryBackgroundColor,
            }}
        >


            <div style={{ display: 'flex' }}>
                <TextoEspecie style={{ textAlign: 'start', marginLeft: '1em' }}
                    onClick={() => onClickEspecie(especie)}
                >
                    {formatEspeciesExterior(especie)}
                </TextoEspecie>
            </div>

            {
                variacionDia === 0 || variacionDia === null ?
                    <Texto style={{ textAlign: 'end' }}>-</Texto>
                    : variacionDia > 0 ?
                        <Texto style={{ textAlign: 'end', color: '#03DAC5', }}>{formatPorcentaje(variacionDia)}</Texto>
                        :
                        <Texto style={{ textAlign: 'end', color: rojoPorcentaje, }}>{formatPorcentaje(variacionDia)}</Texto>

            }
            {/* {
                variacionHistorico === 0 || variacionHistorico === null ?
                    <Texto style={{ textAlign: 'end' }}>-</Texto>
                    : variacionHistorico > 0 ?
                        <Texto style={{ textAlign: 'end', color: '#03DAC5' }}>{formatPorcentaje(variacionHistorico)}</Texto>
                        :
                        <Texto style={{ textAlign: 'end', color: rojoPorcentaje }}>{formatPorcentaje(variacionHistorico)}</Texto>

            }
            <Texto>{`USD ${formatCurrency(costoHistorico)}`}</Texto> */}
            <Texto style={{ marginRight: '0em' }}>{separacionDecimal(cantidad)}</Texto>
            <Texto style={{ marginRight: '0em' }}>{`USD ${formatCurrency(precio)}`}</Texto>
            <Texto style={{ marginRight: '1em' }}>{`USD ${formatCurrency(importe)}`}</Texto>

        </ContainerExterior>
    )
}

export default CardDetalleEspecieHomeExterior