import React, { useContext } from 'react'
import { ContainerSecundario, Titulo, ContainerTitulos, TitulosSecundarios, SinInfo, ContainerSinInfo, Container } from './elements'
import { ThemeContext } from 'styled-components';
import ResultadoDelDia from '../ResultadoDelDia/ResultadoDelDia';
import OrdenesDelDia from '../OrdenesDelDia/OrdenesDelDia';
import { useNavigationHook } from '../../../hooks/useNavigationHook';
import { TipoInfoMercado, TipoNavigate } from '../../../enums/enums';
import CachedIcon from '@mui/icons-material/Cached';
import { TenenciaMercadoExterior, TenenciaMercadoLocal } from '../../../interfaces/tenenciaInterface';
import { Order } from '../../../interfaces/ordersInterface';

interface Props {
    titulo: TipoInfoMercado;
    subtitulos: string[];
    confirmarDatos?: boolean;
    cargarDatosOperar?: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    tenenciaHook: TenenciaMercadoLocal[] | TenenciaMercadoExterior[] | undefined
    loadingTenencia: boolean;
    ordenesOrdenadasFiltradas: Order[]
    formatEstado: (estado: string) => string
    loadingRefresher: boolean
    refrescarTenencia: () => Promise<void>
    errorTenencia?: boolean;
    errorOrders?: boolean;
}

const CardInfoMercadoNuevo = ({
    titulo,
    subtitulos,
    confirmarDatos = false,
    cargarDatosOperar,
    tenenciaHook,
    loadingTenencia,
    ordenesOrdenadasFiltradas,
    formatEstado,
    loadingRefresher,
    refrescarTenencia,
    errorTenencia,
    errorOrders,
}: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext);

    const { handleNavigate } = useNavigationHook();

    return (
        <Container>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                <Titulo
                    onClick={() => {
                        titulo === TipoInfoMercado.Ordenes &&
                            handleNavigate(TipoNavigate.Ordenes)
                    }}
                    style={{ cursor: titulo === TipoInfoMercado.Ordenes ? 'pointer' : undefined }}
                >
                    {titulo}
                </Titulo>
                {titulo === TipoInfoMercado.Resultados
                    ? <CachedIcon
                        onClick={refrescarTenencia}
                        sx={{
                            color: primaryColor,
                            fontSize: '1.2em',
                            alignContent: 'center',
                            cursor: 'pointer',
                            marginRight: '0.3em',
                        }}
                    />
                    : <></>
                }
            </div>
            <ContainerSecundario>
                {errorTenencia && !loadingTenencia && titulo === TipoInfoMercado.Resultados
                    ? <ContainerSinInfo>
                        <SinInfo>No se puede mostrar la tenencia en este momento.</SinInfo>
                    </ContainerSinInfo>
                    : (tenenciaHook && !tenenciaHook.length) && titulo === TipoInfoMercado.Resultados
                        ? <ContainerSinInfo>
                            <SinInfo>No tiene tenencia en su cartera.</SinInfo>
                        </ContainerSinInfo>
                        : errorOrders && !loadingTenencia && titulo === TipoInfoMercado.Ordenes
                            ? <ContainerSinInfo>
                                <SinInfo>No se pueden mostrar las órdenes en este momento.</SinInfo>
                            </ContainerSinInfo>
                            : (!ordenesOrdenadasFiltradas || !ordenesOrdenadasFiltradas.length) && !loadingTenencia && titulo === TipoInfoMercado.Ordenes
                                ? <ContainerSinInfo>
                                    <SinInfo>No hay órdenes disponibles.</SinInfo>
                                </ContainerSinInfo>
                                :
                                <>
                                    <div style={{ position: 'sticky', top: '0em', backgroundColor: primaryBackgroundColor }}>
                                        <ContainerTitulos style={{ paddingTop: '0.2em' }}>
                                            {subtitulos.map(t =>
                                                <TitulosSecundarios
                                                    key={t}
                                                    style={{
                                                        textAlign: t === 'Especie'
                                                            ? 'start'
                                                            : t === 'Tipo' || t === 'Estado'
                                                                ? 'center'
                                                                : undefined
                                                    }}
                                                >
                                                    {t}
                                                </TitulosSecundarios>
                                            )}
                                        </ContainerTitulos>
                                        <hr style={{ margin: '0.2em 0 0em' }} />
                                    </div>
                                    {
                                        titulo === TipoInfoMercado.Resultados
                                            ? <ResultadoDelDia
                                                data={tenenciaHook}
                                                confirmarDatos={confirmarDatos}
                                                cargarDatosOperar={cargarDatosOperar}
                                                loadingRefresher={loadingRefresher}
                                                loading={loadingTenencia}
                                            />
                                            : <OrdenesDelDia
                                                data={ordenesOrdenadasFiltradas}
                                                formatEstado={formatEstado}
                                                loading={loadingTenencia}
                                            />
                                    }
                                </>
                }
            </ContainerSecundario>
        </Container>
    )
}

export default CardInfoMercadoNuevo