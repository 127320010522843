import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { BotonBuscar } from './elements';
import { MenuItem, TextField } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Moneda } from '../../../interfaces/monedasMovimientos';
import AutocompleteEspecies from '../AutocompleteEspecies/AutocompleteEspecies';
import CalendarioFechas from '../../../elements/CalendarioFechas';
import { TiposObjeto } from '../../../interfaces/MovimientosInterface';

interface Props {
    fechaDesde: dayjs.Dayjs | null;
    fechaHasta: dayjs.Dayjs | null;
    setFechaDesde: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
    setFechaHasta: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
    buscarMovimientos: () => void;
    monedas?: Moneda[];
    tipos?: TiposObjeto;
    handleMenus?: (value: Moneda | string, menu: string) => void;
    especies: string[];
    setEspecieSeleccionada: React.Dispatch<React.SetStateAction<string>>;
}
const HeaderMovimientos = ({
    fechaDesde,
    fechaHasta,
    setFechaDesde,
    setFechaHasta,
    buscarMovimientos,
    monedas,
    tipos,
    handleMenus,
    especies,
    setEspecieSeleccionada,
}: Props) => {

    const { primaryColor, primaryBackgroundColor, alternativeBorderColor } = useContext(ThemeContext)

    const propsSelect = {
        "& .MuiSvgIcon-root": {
            color: primaryColor,
        }
    }

    const propsInput = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: primaryColor, // Border color when not focused
            },
            '&:hover fieldset': {
                borderColor: primaryColor, // Border color on hover
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${primaryColor}`
            },
        },
        '& .MuiInputBase-input': {
            color: primaryColor, // Text color
        },
        '& .MuiInputLabel-outlined': {
            color: primaryColor,
            '&.Mui-focused': {
                color: primaryColor,
            }
        },
    }

    const propsMenu = {
        '& .MuiPaper-root': {
            backgroundColor: primaryBackgroundColor, // Background color of the menu
        },
        '& .MuiMenuItem-root': {
            color: primaryColor, // Text color of the menu items
            '&.Mui-selected': {
                backgroundColor: alternativeBorderColor, // Background color when selected
                color: primaryColor, // Text color when selected
            }
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                gap: '1em',
                marginLeft: '1em',
            }} >
            {
                monedas &&
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Moneda"
                    size='small'
                    defaultValue={'Pesos'}
                    sx={{
                        width: '10em',
                        ...propsInput
                    }}
                    SelectProps={{
                        IconComponent: KeyboardArrowRightRoundedIcon,
                        sx: propsSelect,
                        MenuProps: {
                            sx: propsMenu,
                        }
                    }}
                >
                    {
                        monedas.map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                                onClick={() => { handleMenus && handleMenus(option, 'Moneda') }}
                            >
                                {option}
                            </MenuItem>
                        ))
                    }
                </TextField>
            }
            <CalendarioFechas
                fechaPrimaria={fechaDesde}
                fechaSecundariaDesde={fechaHasta}
                seteoFecha={setFechaDesde}
                tituloCalendario='Fecha desde'
            />
            <CalendarioFechas
                fechaPrimaria={fechaHasta}
                fechaSecundariaHasta={fechaDesde}
                seteoFecha={setFechaHasta}
                tituloCalendario='Fecha hasta'
            />
            {
                tipos &&
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Tipo"
                    defaultValue={'Todos'}
                    size='small'
                    sx={{
                        width: '11em',
                        ...propsInput
                    }}
                    SelectProps={{
                        IconComponent: KeyboardArrowRightRoundedIcon,
                        sx: propsSelect,
                        MenuProps: {
                            sx: propsMenu,
                        }
                    }}
                >
                    {
                        Object.keys(tipos).map((option) => (
                            <MenuItem
                                key={option}
                                value={option}
                                onClick={() => { handleMenus && handleMenus(option, 'Tipo') }}
                            >
                                {option}
                            </MenuItem>
                        ))
                    }
                </TextField>
            }
            <AutocompleteEspecies
                data={especies}
                setDataSeleccionada={setEspecieSeleccionada}
                title="Especies"
            />
            <BotonBuscar
                onClick={buscarMovimientos}
            >BUSCAR</BotonBuscar>
        </div >
    )
}

export default HeaderMovimientos