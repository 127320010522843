import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/userContext/UserContext';
import { Order } from '../interfaces/ordersInterface';
import { getOrders } from '../api/userApi';
import { AuthContext } from '../context/authContext/AuthContext';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { TipoAccount, TipoMercado, TipoNavigate } from '../enums/enums';
import { NavigationContext } from '../context/navigationContext/NavigationContext';
import useWindowDimensions from './useWindowDimensions';
import { formatearAccountSearched } from '../helpers/formatearAccountSearched';

const arrMenuList = [
  'Todas',

  'En proceso',

  'Terminada',

  'Rechazada',

  'Cancelada',
];

export const useOrdenesHook = () => {
  const { orders, setOrders } = useContext(UserContext);
  const { setToken, accountSearched, accountType } = useContext(AuthContext);
  const [especies, setEspecies] = useState<Order[]>([]);
  const [especiesFilt, setEspeciesFilt] = useState<Order[]>([]);
  const [name, setName] = useState('');
  const [actualMenuEstado, setActualMenuEstado] = useState('Todas');
  const { tipoMercado } = useContext(MercadoContext);
  const [loading, setLoading] = useState(false);
  const [errorOrders, setErrorOrders] = useState(false);
  const { setSelectedNavigate } = useContext(NavigationContext);
  const { width } = useWindowDimensions();


  useEffect(() => {
    const refresh = async () => {
      try {
        setLoading(true);
        const tokenStorage = localStorage.getItem("token");
        const accountTypeStorage = localStorage.getItem("accountType");
        tokenStorage && setToken(tokenStorage);
        if (tokenStorage) {
          let resp
          if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
            resp = await getOrders(tokenStorage, formatearAccountSearched(accountSearched));
          } else {
            resp = await getOrders(tokenStorage);
          }
          setOrders(resp.data.body.orders);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setErrorOrders(true);
        setLoading(false)
      }
    };
    refresh();
    setSelectedNavigate(TipoNavigate.Ordenes)
  }, [accountSearched]);

  useEffect(() => {
    filtrarPorMercado();

  }, [orders, tipoMercado]);

  function filtrarPorMercado() {
    let ordenesPorMercado;
    if (tipoMercado === TipoMercado.Exterior) {
      ordenesPorMercado = orders.filter(e => esExterior(e.especie));
    } else {
      ordenesPorMercado = orders.filter(e => !esExterior(e.especie));
    }
    setEspecies(ordenesPorMercado);
    setEspeciesFilt(ordenesPorMercado);
  }
  function esExterior(nombre: string): boolean {
    return nombre.endsWith('_US');
  }

  useEffect(() => {
    filtEspecies();
  }, [name]);

  const [listaMenuEstado, setlistaMenuEstado] = useState(
    'Todas',
  );

  const [openEstado, setOpenEstado] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleOpenEstado = (event: React.MouseEvent<HTMLElement>) => {
    setOpenEstado(event.currentTarget);
  };

  const handleClickEstado = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setlistaMenuEstado(arrMenuList[index]);
    filtPorEstado(arrMenuList[index])
    setOpenEstado(null);
  };

  const handleCloseEstado = (op?: string) => {
    if (op) {
      setlistaMenuEstado(op);
      filtPorEstado(op)
    }
    setOpenEstado(null);
  };


  const filtEspecies = () => {
    let filtered = especies.filter(e =>
      e.especie.toLowerCase().includes(name.toLowerCase()),
    );
    setEspeciesFilt(filtered);
    if (name.length === 0) filtrarPorMercado();
  };

  useEffect(() => {
    filtPorEstado(actualMenuEstado)
  }, [actualMenuEstado])


  const filtPorEstado = (estado: string) => {
    width < 992 && setActualMenuEstado(estado);
    estado = estado.toLowerCase();


    if (estado === 'todas') {
      setEspeciesFilt(especies);
    } else {
      let filtered = especies.filter(e =>
        formatEstado(e.estado).toLowerCase().includes(estado),
      );

      setEspeciesFilt(filtered);
    }
  };

  function formatEstado(estado: string) {
    let formatValue;
    switch (estado) {
      case 'Pendiente Operador':
        formatValue = 'EN PROCESO';
        break;
      case 'Recibida':
        formatValue = 'EN PROCESO';
        break;
      case 'Anulada':
        formatValue = 'CANCELADA';
        break;
      case 'Cumplida':
        formatValue = 'TERMINADA';
        break;
      default:
        formatValue = estado.toUpperCase();
        break;
    }
    return formatValue;
  }

  return {
    especiesFilt,
    formatEstado,
    listaMenuEstado,
    filtPorEstado,
    actualMenuEstado,
    setActualMenuEstado,
    name,
    setName,
    handleOpenEstado,
    handleClickEstado,
    handleCloseEstado,
    openEstado,
    selectedIndex,
    arrMenuList,
    loading,
    errorOrders,
  };
};
