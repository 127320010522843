import { useContext, useEffect, useState } from 'react'
import { LimiteOperacion, TipoMercado, TipoOperacion } from '../enums/enums';
import { OperarContext } from '../context/operarContext/OperarContext';
import { useSearchEspecieOperarHook } from './useSearchEspecieOperarHook';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from './useWindowDimensions';
import { InstrumentosGallo, Tipo } from '../interfaces/nombreEspeciesInterfaces';
import { formatCurrency } from '../helpers/formatCurrency';
import { getDataEspecie } from '../api/mercadoApi';

export const useValidacionDatosOperar = () => {

    const [error, setError] = useState('');
    const {
        precio,
        textoTitulos,
        cantidad,
        actualMenuLimit,
        setEspecieOperada,
        setDeskOperar,
        choiced,
        setImporte,
        importe,
        setChoiced,
        setCantidad,
        setPrecio,
    } = useContext(OperarContext)
    const { tipoMercado } = useContext(MercadoContext);
    const { searchEspecie } = useSearchEspecieOperarHook();

    const { width } = useWindowDimensions();

    const navigate = useNavigate();

    useEffect(() => {
        width < 992 && calcImporte(precio, cantidad, choiced);
    }, [cantidad, precio, choiced, tipoMercado]);

    useEffect(() => {
        setError('');
    }, [tipoMercado]);

    function cumpleCondicionLimitMarket() {
        if (actualMenuLimit === LimiteOperacion.Limit && (precio === '0' || precio === '')) {
            return false;
        } else {
            return true;
        }
    }

    function contieneSoloNumeros(texto: string) {
        if (texto === '') return true
        return texto.match(/^\d+$/) !== null;
    }

    function contieneNumerosYComas(texto: string) {
        if (texto === '') return true
        return texto.match(/^\d+(,\d+)*$/) !== null;
    }


    function calcImporte(precio: string, cantidad: string, especieOper: InstrumentosGallo | null): void {
        let str = tipoMercado === TipoMercado.Local ? '$-' : 'USD-'
        let total = 0;
        const precioConDecimales = precio.replaceAll(',', '.');
        if (precio && cantidad) {
            if (especieOper && especieOper.tipo === Tipo.Bono) {
                //si es bono divide todo por 100
                total = (parseFloat(precioConDecimales) * parseInt(cantidad)) / 100;
            } else if (especieOper && especieOper.tipo === Tipo.Opcion) {
                //poner opciones en la validacion
                //si es opcion lo multiplica por 100
                total = parseFloat(precioConDecimales) * parseInt(cantidad) * 100;
            } else {
                total = parseFloat(precioConDecimales) * parseInt(cantidad);
            }
            str =
                tipoMercado === TipoMercado.Local
                    ? '$' + formatCurrency(total)
                    : 'USD ' + formatCurrency(total)
        }
        width < 992
            ? setImporte(str)
            : setImporte(total.toString())
    }


    const validarPrecioCantidadImporte = () => {
        if ((precio !== '' || cantidad !== '') && importe !== '') {
            if (width > 992) {
                setError('No puede ingresar importe con precio o cantidad');
                return false;
            }
        } else if (precio === '' && cantidad === '' && importe === '') {
            setError('Ingrese la cantidad y el precio o  ingrese el importe.')
            return false
        } else if ((cantidad !== '') && !cumpleCondicionLimitMarket()) {
            setError('El precio no puede ser 0');
            return false
        } else if (cantidad === '' && precio !== '') {
            setError('Tiene que ingresar la cantidad junto con el precio.');
            return false
        } else if (!contieneNumerosYComas(precio)) {
            setError('El precio ingresado presenta errores.');
            return false;
        } else if (!contieneSoloNumeros(cantidad)) {
            setError('La cantidad ingresada presenta errores.');
            return false;
        } else if (!contieneNumerosYComas(importe)) {
            setError('El importe ingresado presenta errores.');
            return false;
        }
        return true
    }

    const calcularImporteSinPrecio = async (especieOper: InstrumentosGallo | null, tipoOperacion: TipoOperacion) => {
        const tokenStoragePublico = localStorage.getItem("tokenPublico");
        try {
            // traigo la data de la especie en especifico (ultimo precio)
            const dataEspecie = (tokenStoragePublico && especieOper) && await getDataEspecie(tokenStoragePublico, [especieOper.especie]);
            if (dataEspecie && dataEspecie.data.body) {
                let precioNuevo = '';
                let precioDivision = 0;
                const importeConDecimales = importe.replaceAll(',', '.');
                if (tipoMercado === TipoMercado.Local) {
                    if (tipoOperacion === TipoOperacion.Compra) {
                        precioNuevo = String(dataEspecie.data.body[0].preciocompra);
                    } else {
                        precioNuevo = String(dataEspecie.data.body[0].precioventa);
                    }
                    if (especieOper) {
                        if (especieOper.tipo === Tipo.Bono) {
                            precioDivision = Number(precioNuevo) / 100;
                        } else if (especieOper.tipo === Tipo.Opcion) {
                            precioDivision = Number(precioNuevo) * 100;
                        } else {
                            precioDivision = Number(precioNuevo);
                        }
                    }
                    if (Number(importeConDecimales) < precioDivision) {
                        setError(`El importe no puede ser menor al precio de compra o venta. Precio actual: ${precioNuevo}`)
                        return false;
                    } else {
                        // seteo los precios y cantidades correctas
                        let cantidadCalculada = String(Math.floor(Number(importeConDecimales) / precioDivision));
                        setCantidad(cantidadCalculada)
                        setPrecio(precioNuevo);
                        calcImporte(precioNuevo, cantidadCalculada, especieOper)
                        return true;
                    }
                } else {
                    precioNuevo = String(dataEspecie.data.body[0].ultimo);
                    if (cumpleCondicionLimitMarket()) {
                        calcImporte(String(precioNuevo), cantidad, especieOper)
                        return true;
                    } else {
                        if (Number(importeConDecimales) < Number(precioNuevo)) {
                            setError(`El importe no puede ser menor al precio de compra o venta. Precio actual: ${precioNuevo}`)
                            return false;
                        }
                        let cantidadCalculada = String(Math.floor(Number(importeConDecimales) / Number(precioNuevo)));
                        setCantidad(cantidadCalculada);
                        setPrecio(precioNuevo)
                        calcImporte(precioNuevo, cantidadCalculada, especieOper)
                        return true;
                    }
                }
            } else {
                setError(`No es posible cargar información sobre la especie ingresada.`)
                return false;
            }
        } catch (error) {
            console.log(error);
        }
    }

    // valida que los datos esten cargados correctamente
    async function validaciones(tipoOperacion: TipoOperacion) {
        let especieOper = searchEspecie(textoTitulos);
        setChoiced(especieOper)
        if (tipoMercado === TipoMercado.Local) {
            if (especieOper === null) {
                setError(
                    'La especie ingresada presenta errores, hacé click en el buscador y seleccioná el ticker correcto.',
                );
                return false;
            }
        }
        if (validarPrecioCantidadImporte()) {
            if (precio && cantidad && precio !== '0') {
                calcImporte(precio, cantidad, especieOper)
            } else if (await calcularImporteSinPrecio(especieOper, tipoOperacion)) {
                setEspecieOperada(especieOper);
                return true;
            } else {
                return false;
            }
            setEspecieOperada(especieOper);
            return true;
        }
        return false
    }

    const continuar = async (tipoOperacion: TipoOperacion) => {
        if (await validaciones(tipoOperacion)) {
            setError('');
            if (width > 992) {
                setDeskOperar(true);
            } else {
                navigate('/operar/comprarScreen')
            }
        }
    };

    return {
        error,
        continuar,
        setError,
    }
}
