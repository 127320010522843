import { useContext, useEffect, useState } from 'react';
import { deletOrders } from '../api/userApi';
import { AuthContext } from '../context/authContext/AuthContext';
import { UserContext } from '../context/userContext/UserContext';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { DatosClaveEspecie, InstrumentosGallo } from '../interfaces/nombreEspeciesInterfaces';
import { TipoAccount, TipoMercado } from '../enums/enums';
import { formatearAccountSearched } from '../helpers/formatearAccountSearched';

interface Props {
  nombre: string;
  nroOrden: string;
}

export const useCardEspecieOrdenesHook = ({ nombre, nroOrden }: Props) => {
  const { token, accountSearched, accountType } = useContext(AuthContext);
  const [data, setData] = useState<InstrumentosGallo[] | null>(null);
  const { tipoMercado, instrumentosGalloLocal, instrumentosGalloExterior } = useContext(MercadoContext);
  const { cancelOrder } = useContext(UserContext);
  const [loadingCancelarOrden, setLoadingCancelarOrden] = useState<boolean>(false);
  const [error, setError] = useState<{ open: boolean; mensaje: string; }>({ open: false, mensaje: "" })

  useEffect(() => {
    if (tipoMercado === TipoMercado.Local) {
      setData(instrumentosGalloLocal);
    } else {
      setData(instrumentosGalloExterior);
    }
  }, [tipoMercado, instrumentosGalloLocal, instrumentosGalloExterior]);

  function esExterior(): boolean {
    return nombre.endsWith('_US');
  }

  function formatTipoAccion(accion: string) {
    let formatValue = '';
    switch (accion) {
      case 'COPE OFF':
        formatValue = 'COMPRA';
        break;
      case 'VOPE OFF':
        formatValue = 'VENTA';
        break;
      default:
        formatValue = accion;
        break;
    }
    return formatValue;
  }

  async function cancelarOrden() {
    try {
      setLoadingCancelarOrden(true)
      const accountTypeStorage = localStorage.getItem("accountType");
      const especie = data?.filter(e => e.especie === nombre);
      let nombreEspecie = '';
      if (especie) {
        nombreEspecie = especie[0].especie_completa ?? especie[0].especie;
      }
      if (token && nombreEspecie) {
        let accountSearchFormateada
        if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
          accountSearchFormateada = formatearAccountSearched(accountSearched)
        }
        const resp = await deletOrders(token, nombreEspecie, nroOrden, nombre, accountSearchFormateada);
        if (resp.data.error || resp.data.body.Resultado.Success === "false") {
          setError({open: true, mensaje: resp.data.body.Resultado.Detalle})
        } else {
          cancelOrder(nroOrden);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCancelarOrden(false)
    }
  }

  function formatHour(hora: string) {
    const [hours, minutes] = hora.split(':');
    return `${hours}:${minutes}`;
  }

  const handleCloseAlertError = () => {
    setError({open: false, mensaje: ""})
  }

  return {
    formatTipoAccion,
    formatHour,
    esExterior,
    cancelarOrden,
    loadingCancelarOrden,
    handleCloseAlertError,
    error,
  };
};
