import styled from "styled-components";


export const ContainerSaldos = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5em;
    margin: 0em;
    @media (min-width: 992px) {
        padding: 0;
	}
`

export const ContainerInsideSaldos = styled.div`
    display: flex; 
    align-items: center;
    @media (min-width: 992px){
        margin-left: 1em; 
    }
`

export const Saldos = styled.div`
    margin-left: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px;
    width: 100%;
    @media (min-width: 992px) {
        height: 90px;
		margin-left: 0;
        justify-content: space-around;
	}
`

export const SaldosDolares = styled.div`
    margin-left: 0.5em;
    display: flex;
    width: 100%;
    @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-evenly;
        margin-left: 0;
	}
`

export const Dolares = styled.p`
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    @media (min-width: 992px) {
        font-size: 20px;
	}
`

export const TipoMercadoText = styled.p`
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 15px;
    margin: 0px;
    letter-spacing: 1.835px;
`

export const ErrorText = styled.p`
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 15px;
    margin: 0px;
    @media (min-width: 992px) {
        font-size: 17px;
	}
`

export const Pesos = styled.p`
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    @media (min-width: 992px) {
        font-size: 20px;
	}
`

export const DolaresLocal = styled.p`
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    @media (min-width: 992px) {
        font-size: 20px;
	}
`

export const SecondContainer = styled.div`
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 992px){
        flex-direction: row;
    }
`

export const ContainerPorcentajes = styled.div`
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    // margin-right: 0em;
    @media (min-width: 992px) {
        margin-right: 2em;
        width: 8em;
	}
`


export const PorcentajesCero = styled.div`
    text-align: center;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    background-color: rgba(0, 56, 110, 0.37); 
    padding: 0.1em 0.4em 0.1em 0.4em;
    width: auto;
    display: flex;
    @media (min-width: 992px) {
        align-self: center;
        justify-content: center;
        margin-bottom: 0em;
        background-color: transparent;
        flex-direction: column;
        width: auto;
	}
`

export const PorcentajesPositivos = styled.div`
    text-align: center;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    padding: 0.1em 0.4em 0.1em 0.4em;
    display: flex;
    width: auto;
    background-color: rgba(3, 218, 197, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: 992px) {
        display: flex;
        align-self: center;
        justify-content: center;
        margin-bottom: 0em;
        background-color: transparent;
        border: none;
        flex-direction: column;
        width: auto;
	}
`

export const PorcentajesNegativos = styled.div`
    text-align: center;
    border-radius: 15px;
    align-items: center;
    white-space: nowrap;
    display: flex;
    padding: 0.1em 0.4em 0.1em 0.4em;
    width: auto;
    justify-content: center;
    background-color: rgba(176, 0, 32, 0.3);
    // border: 1px solid rgba(0, 0, 0, 0.1);
    @media (min-width: 992px) {
        display: flex;
        align-self: center;
        justify-content: center;
        margin-bottom: 0em;
        background-color: transparent;
        border: none;
        width: auto;
        flex-direction: column;
	}
`

export const TextoPorcentajePositivo = styled.p`
    color: ${({ theme }) => theme.buyColor};
    font-size: 16px;
    margin: 0px;
    line-height: 1.5;
    @media (min-width: 992px) {
        font-size: 20px;
        font-weight: bold;
	}
`


export const TextoPorcentajeNegativo = styled.p`
    color: ${({ theme }) => theme.rojoPorcentaje};
    font-size: 1rem;
    margin: 0px;
    line-height: 1.5;
    @media (min-width: 992px) {
        font-size: 20px;
        font-weight: bold;
	}
`

export const Visibility = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.2em;
    @media (min-width : 992px){
        margin-left: 1em;
    }
`

export const ImgDesk = styled.img`
    width: 2.5em; 
    height: 2.5em;
    margin-bottom: 1.5em;
    align-self: self-end;
    margin-right: 2em;
`
export const Img = styled.img`
    width: 1.2em; 
    height: 1.2em;
`