import styled from "styled-components";

export const Container = styled.div`
background-color: ${({theme}) => theme.primaryBackgroundColor};
margin-right: 1em;
margin-left: 1em;
margin-top: 0.5em;
border-radius: 15px;
box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
display: flex;
flex-direction: column;
@media (min-width: 992px) {
    width: 100%;
    margin-top: 1em;
    margin-right: 0em;
}
`

export const ContainerTitulos = styled.div`
display: flex;
justify-content: space-between;
margin-left: 0.5em;
margin-right: 0.5em;
@media (min-width: 992px) {
    justify-content: space-evenly;
    width: 100%;
    margin-left: 0em;
    margin-right: 0em;
    border-radius: 15px;
}
`
export const ContainerSinOfertas = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const TextoSinOfertas = styled.p`
font-weight: 600;
font-size: 16px;
color: ${({theme}) => theme.alternativeAccentColor};
`

export const Titulos = styled.p`
margin: 0px;
font-weight: 600;
font-size: 13px;
color: ${({theme}) => theme.primaryColor};
width: 5.2em;
margin-top: 0.2em;
margin-bottom: 0.2em;
text-align: right;
@media (min-height: 730px) {
    margin-top: 1em;
}
@media (min-width: 992px) {
    font-size: 16px;
    margin-top: 0.5em;
}
`


export const Cantidad = styled.p`
margin: 0px;
margin-bottom: 0.1em;
font-weight: 400;
font-size: 16px;
width: 4.2em;
text-align: right;
color: ${({theme}) => theme.primaryColor};
cursor: pointer;
@media (min-width: 992px) {
    width: 5em;
}
`

export const PrecioCompra = styled.p`
margin: 0px;
font-weight: 400;
font-size: 16px;
width: 4.2em;
text-align: right;
color: ${({theme}) => theme.buyColor};
cursor: pointer;
@media (min-width: 992px) {
    width: 4.9em;
}
`

export const PrecioVenta = styled.p`
margin: 0px;
font-weight: 400;
font-size: 16px;
width: 4.2em;
color: ${({theme}) => theme.rojoPorcentaje};
text-align: right;
cursor: pointer;
@media (min-width: 992px) {
    width: 4.9em;
}
`