import { useContext, useEffect, useState } from 'react'
import { getDocumentoMovimiento, getMovimientos, getMovimientosTitulos } from '../api/userApi'
import { AuthContext } from '../context/authContext/AuthContext'
import { Movimiento, TiposObjeto } from '../interfaces/MovimientosInterface'
import dayjs, { Dayjs } from 'dayjs'
import { Moneda, tipoMonedas } from '../interfaces/monedasMovimientos'
import { MercadoContext } from '../context/mercadoContext/MercadoContext'
import { formatearFecha } from '../helpers/formatearFecha'
import { TipoAccount, TipoMercado, TipoNavigate } from '../enums/enums'
import useWindowDimensions from './useWindowDimensions'
import { NavigationContext } from '../context/navigationContext/NavigationContext'
import { formatearAccountSearched } from '../helpers/formatearAccountSearched'
import * as XLSX from "xlsx";
// @ts-ignore
import { saveAs } from "file-saver";
import { useSearchEspecieOperarHook } from './useSearchEspecieOperarHook'
import { formatCurrency } from '../helpers/formatCurrency'
import { separacionDecimal } from '../helpers/separacionDecimal'

export const useMovimientosHook = (tipo: string) => {

    const { token, accountType, accountSearched } = useContext(AuthContext);
    const { dataLocal, dataExterior, tipoMercado } = useContext(MercadoContext);
    const { setSelectedNavigate } = useContext(NavigationContext);
    const [movimientos, setMovimientos] = useState<Movimiento[]>([])
    const [movimientosFiltrados, setMovimientosFiltrados] = useState<Movimiento[]>([])
    const [loadingMovimientos, setLoadingMovimientos] = useState(false);
    const [loadingDescarga, setLoadingDescarga] = useState(false);
    const [fechaDesde, setFechaDesde] = useState<Dayjs | null>(dayjs());
    const [fechaHasta, setFechaHasta] = useState<Dayjs | null>(dayjs());
    const [dataEspecies, setDataEspecies] = useState<string[]>([])
    const monedas: Moneda[] = ['Pesos', 'Dólar', 'Dólar billete'];

    const [mensajeTitulos, setMensajeTitulos] = useState('');

    const { width } = useWindowDimensions();

    const [movimientoSeleccionado, setMovimientoSeleccionado] = useState('Movimientos del día')
    const listaTipoMovimientos = ['Movimientos del día', 'Movimientos últimos 7 días'];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { searchEspecieFromName } = useSearchEspecieOperarHook()

    useEffect(() => {
        const refresh = async () => {
            const tokenStorage = localStorage.getItem('token');
            if (tokenStorage) {
                setSelectedNavigate(TipoNavigate.Movimientos)
                if (dataLocal && dataExterior) {
                    const dataReducidaLocal = dataLocal.filter(m => m.tipo !== 'opcion').map(m => m.especie)
                    const dataReducidaExterior = dataExterior.map(m => m.especie)
                    const dataCombinada = [...dataReducidaLocal, ...dataReducidaExterior];
                    const dataOrdenada = dataCombinada.sort();
                    setDataEspecies(dataOrdenada)
                }
                buscarMovimientos(tokenStorage)
            }

        }
        refresh();
    }, [accountSearched])



    const handleOpenMenuMovimientos = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenuMovimientos = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            setMovimientoSeleccionado(op);
        }
    };

    function getFormattedDate(daysAgo: number): string {
        const currentDate = new Date();
        // le quita la cantidad de dias que le pase a la fecha
        currentDate.setDate(currentDate.getDate() - daysAgo);

        // padStart es para que todos los numeros tengan dos digitos, ej: 09
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();

        return `${day}/${month}/${year}`;
    }
    function exportarAExcel() {
        // Crear una copia del array excluyendo las propiedades
        const datosProcesados = movimientosFiltrados.map(({ numeroOperacion, clave, ...resto }) => {
            const { importe, precio, saldo, cantidad, ...otros } = resto;

            return {
                ...otros,
                numeroOperacion,
                clave,
                cantidad: cantidad && separacionDecimal(parseFloat(cantidad)),
                importe: formatCurrency(importe),
                precio: formatCurrency(precio),
                saldo: formatCurrency(saldo),
            };
        });

        // Crear hoja de trabajo a partir del array procesado
        let ws = XLSX.utils.json_to_sheet(datosProcesados);

        // Modificar encabezados para que inicien con mayúscula
        const headers = Object.keys(datosProcesados[0]).reduce((acc, key) => {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1); // Capitaliza la primera letra
            acc[key] = capitalizedKey;
            return acc;
        }, {} as Record<string, string>);

        // Actualizar los encabezados en la hoja de trabajo
        ws['!ref'] = XLSX.utils.encode_range({
            s: { r: 0, c: 0 },
            e: { r: datosProcesados.length, c: Object.keys(headers).length - 1 },
        });

        Object.entries(headers).forEach(([key, capitalizedKey], index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index }); // Dirección de celda del encabezado
            ws[cellAddress] = { t: 's', v: capitalizedKey }; // Cambiar el valor de la celda
        });

        // Definir el ancho de las columnas
        const columnWidths = Object.keys(headers).map(() => ({ wpx: 80 })); // Ajusta el ancho (wpx) a tu necesidad

        ws['!cols'] = columnWidths; // Agregar el ancho de columnas a la hoja

        // Crear un libro de trabajo
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Movimientos");

        // Exportar el archivo
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(data, "movimientos.xlsx");
    }


    useEffect(() => {
        if (width < 992) {
            const fechaDesde = movimientoSeleccionado === 'Movimientos del día' ? getFormattedDate(0) : getFormattedDate(7);
            const fechaHasta = getFormattedDate(0);
            const moneda = tipoMercado === TipoMercado.Local ? 'peso' : 'dolar'
            traerMovimientos(moneda, fechaDesde, fechaHasta)
        }
    }, [movimientoSeleccionado, tipoMercado])


    const tiposObjeto: TiposObjeto = {
        'Compra/Venta': [
            'COMPRA TRADING',
            'COMPRA',
            'VENTA',
            'VENTA SENEBI',
            'COMPRA X EJERCICIO PRIMAS',
            'VENTA X EJERCICIO PRIMAS',
            'COMPRA CABLE',
            'VENTA CABLE',
            'VENTA TRADING',
            'COMPRA TRADING PARIDAD',
            'VENTA TRADING PARIDAD',
            'COMPRA EN DOLARES',
            'VENTA EN DOLARES',
            'VENTA EJERCICIO TRADING',
            'COMPRA EJERCICIO TRADING',
        ],
        'Opciones': [
            'COMPRA PRIMA',
            'VENTA PRIMA',
        ],
        'Cauciones': [
            'COMPRA CAUCION CONTADO',
            'VENTA CAUCION TERMINO',
            'VENTA CAUCION CONTADO',
            'COMPRA CAUCION TERMINO',
            'CPRA CAUC. PARIDAD',
            'VTA. CAUC. PARIDAD',
        ],
        'Senebi': [
            'VENTA SENEBI',
        ],
        'Cobros y pagos': [
            'RECIBO DE COBRO',
            'ORDEN DE PAGO',
        ],
        'Dividendos y Rentas': [
            'RENTA Y AMORTZ',
            'DIVIDENDOS',
        ],
        'Débitos y Créditos': [

        ],
        'Resultados índices': [
            'COMPRA INDICE',
            'VENTA INDICE',
            'DEBITO PERDIDA INDICE',
            'CREDITO GANANCIA INDICE',
        ],
        'Préstamos y valores': [
            'PRESTAMO VAL.TOM.CONTADO',
            'PRESTAMO VAL.TOM.TERMINO',
            'ENT. GTIA PRESTAMO TITULOS $',
            'DEV. GTIA. PRESTAMO TITULOS $',
        ],
        'Garantías': [
            'ENT. GAR. OPC. PESOS',
            'DEV. GAR. OPC. PESOS',
            'ENT. GTIA. INDICE PESOS',
            'DEV. GTIA. INDICE PESOS',
        ],
        'Todos': [],
    }

    const [monedaSeleccionada, setMonedaSeleccionada] = useState<Moneda>('Pesos')
    const [tipoMovimientoSeleccionado, settipoMovimientoSeleccionado] = useState("Todos")
    const [especieSeleccionada, setEspecieSeleccionada] = useState('');
    const [especieBuscada, setEspecieBuscada] = useState('');

    useEffect(() => {
        tipo === 'Movimientos cuenta corriente' && setMovimientosFiltrados(filtrarPorEspecieYTipo(movimientos))
    }, [especieSeleccionada, tipoMovimientoSeleccionado]);

    const filtrarPorTipos = (movimientosParaFiltrar: Movimiento[]) => {
        if (tipoMovimientoSeleccionado === 'Todos') {
            return movimientosParaFiltrar;
        } else {
            const filtradoPorTipos =
                movimientosParaFiltrar.filter(
                    m => {
                        const arraySinEspacios = tiposObjeto[tipoMovimientoSeleccionado as keyof TiposObjeto].map(t => t.replaceAll(' ', ''));
                        return arraySinEspacios.includes(m.operacion.replaceAll(' ', ''))
                    })
            return filtradoPorTipos
        }
    }


    const filtrarPorEspecieYTipo = (movimientosParaFiltrar: Movimiento[]) => {
        const movimientosFiltradosPorTipos = filtrarPorTipos(movimientosParaFiltrar);
        return !especieSeleccionada.length
            ? movimientosFiltradosPorTipos
            : movimientosFiltradosPorTipos.filter(m => m.especie.toLocaleUpperCase().startsWith(especieSeleccionada.toLocaleUpperCase()))
    }

    const buscarMovimientos = (tokenStorage?: string) => {
        const moneda = tipoMonedas[monedaSeleccionada]
        const fechaDesdeFormateada = formatearFecha(fechaDesde);
        const fechaHastaFormateada = formatearFecha(fechaHasta);
        tipo === 'Movimientos cuenta corriente' && traerMovimientos(moneda, fechaDesdeFormateada, fechaHastaFormateada, tokenStorage);
        tipo === 'Movimientos titulos' && traerMovimientosTitulos(fechaDesdeFormateada, fechaHastaFormateada, especieSeleccionada, tokenStorage);
    }


    const formatearCadena = (cadena: string): string => {
        const indiceParentesis = cadena.indexOf('(');
        if (indiceParentesis !== -1) {
            // Si se encuentra un paréntesis, se corta la cadena hasta ese punto
            return cadena.substring(0, indiceParentesis);
        } else {
            // Si no se encuentra un paréntesis, se devuelve la cadena sin cambios
            return cadena;
        }
    }

    const traerDocumentos = async (id: string) => {
        setLoadingDescarga(true)
        try {
            const resp = await getDocumentoMovimiento(token, id);

            const blob = new Blob([resp.data], {
                type: 'application/pdf',
            });

            // Crear una URL para el Blob
            const blobUrl = window.URL.createObjectURL(blob);

            // Crear un elemento <a> para la descarga
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'comprobante.pdf'; // Nombre del archivo que se descargará

            // Simular un clic en el enlace
            document.body.appendChild(link);
            link.click();

            // Eliminar el enlace después de la descarga
            document.body.removeChild(link);

            // Revocar el objeto URL
            window.URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingDescarga(false);
        }

    }

    const traerMovimientos = async (moneda: string, fechaDesde: string, fechaHasta: string, tokenStorage?: string) => {
        setLoadingMovimientos(true);
        const accountTypeStorage = localStorage.getItem("accountType")
        try {
            let resp;
            if (tokenStorage) {
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                    resp = await getMovimientos(tokenStorage, moneda, fechaDesde, fechaHasta, formatearAccountSearched(accountSearched))
                } else {
                    resp = await getMovimientos(tokenStorage, moneda, fechaDesde, fechaHasta)
                }
            } else {
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                    resp = await getMovimientos(token, moneda, fechaDesde, fechaHasta, formatearAccountSearched(accountSearched))
                } else {
                    resp = await getMovimientos(token, moneda, fechaDesde, fechaHasta)
                }
            }
            const movimientos = moneda === "peso"
                ? resp.data.body.movimientos.filter(m => !m.operacion.includes("EXTERIOR"))
                : resp.data.body.movimientos
            // if (moneda === "peso") {
            //     movimientos = resp.data.body.movimientos.filter(m => !m.operacion.includes("EXTERIOR"))
            // } else {
            //     movimientos = resp.data.body.movimientos
            // }
            setMovimientos(movimientos)
            setMovimientosFiltrados(filtrarPorEspecieYTipo(movimientos))
        } catch (error) {
            console.log(error);
            setMovimientosFiltrados([])
        } finally {
            setLoadingMovimientos(false);
        }
    }

    const traerMovimientosTitulos = async (fechaDesde: string, fechaHasta: string, especie: string, tokenStorage?: string) => {
        setMovimientosFiltrados([])
        if (!especie.endsWith("_US") && !searchEspecieFromName(especie)) {
            setMensajeTitulos('Seleccione una especie para buscar.');
            return
        }
        const accountTypeStorage = localStorage.getItem("accountType")
        setLoadingMovimientos(true);
        setMensajeTitulos('')
        try {
            let resp
            if (tokenStorage) {
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                    resp = await getMovimientosTitulos(tokenStorage, fechaDesde, fechaHasta, especie.toLocaleUpperCase(), formatearAccountSearched(accountSearched));
                } else {
                    resp = await getMovimientosTitulos(tokenStorage, fechaDesde, fechaHasta, especie.toLocaleUpperCase());
                }
            } else {
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                    resp = await getMovimientosTitulos(token, fechaDesde, fechaHasta, especie.toLocaleUpperCase(), formatearAccountSearched(accountSearched));
                } else {
                    resp = await getMovimientosTitulos(token, fechaDesde, fechaHasta, especie.toLocaleUpperCase());
                }
            }
            console.log(resp);

            setMovimientosFiltrados(resp.data.body.movimientos)
            setEspecieBuscada(especie.toLocaleUpperCase())
        } catch (error) {
            console.log(error);
            setMovimientosFiltrados([])
        } finally {
            setLoadingMovimientos(false)
        }
    }

    const handleMenus = (value: Moneda | string, menu: string) => {
        menu === 'Moneda' && setMonedaSeleccionada(value as Moneda);
        menu === 'Tipo' && settipoMovimientoSeleccionado(value);
    }

    return {
        loadingMovimientos,
        formatearCadena,
        traerDocumentos,
        loadingDescarga,
        fechaDesde,
        setFechaDesde,
        fechaHasta,
        setFechaHasta,
        buscarMovimientos,
        monedas,
        handleMenus,
        dataEspecies,
        setEspecieSeleccionada,
        movimientosFiltrados,
        tiposObjeto,
        especieBuscada,
        movimientoSeleccionado,
        listaTipoMovimientos,
        anchorEl,
        open,
        handleOpenMenuMovimientos,
        handleCloseMenuMovimientos,
        mensajeTitulos,
        exportarAExcel,
    }
}

