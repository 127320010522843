import { useContext } from 'react'
import { Container, ContainerTexto, Texto } from './elements';
import { ThemeContext } from 'styled-components';
import { TitulosOrdenarPor } from '../../../interfaces/TitulosIntreface';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface Props {
    titulos: string[];
    ordenarPorTitulos: (titulo: string) => void
    ordenarPor: { titulo: keyof TitulosOrdenarPor, ascendente: boolean };
    filtraOpciones: React.MutableRefObject<boolean>
    actualMenu: string
}

const CardTitulos = ({ titulos, ordenarPorTitulos, ordenarPor, filtraOpciones, actualMenu }: Props) => {

    const { backgroundMercadoNuevo, primaryColor } = useContext(ThemeContext);

    const esVentaCompra = (texto: string) => {
        return texto.includes('Compra') || texto.includes('Venta');
    }


    const estaOrdenadoPor = (titulo: string) => {
        return ordenarPor.titulo === titulo ?
            ordenarPor.ascendente
                ? <ArrowDropUpIcon
                    sx={{
                        color: primaryColor,
                        fontSize: '0.8em',
                        opacity: 1,
                        transition: 'transform 0.5s ease, opacity 0.5s ease',
                        transform: 'rotate(0deg)',
                    }}
                />
                : <ArrowDropUpIcon
                    sx={{
                        color: primaryColor,
                        fontSize: '0.8em',
                        opacity: 1,
                        transition: 'transform 0.5s ease, opacity 0.5s ease',
                        transform: 'rotate(180deg)',
                    }}
                />
            : <ArrowDropUpIcon
                sx={{
                    color: primaryColor,
                    fontSize: '0.8em',
                    opacity: 0,
                    transition: 'transform 0.5s ease',
                    }}
            />
    }

    return (
        <Container>
            {titulos.map((t) =>
                <ContainerTexto
                    key={t}
                    style={{
                        backgroundColor: esVentaCompra(t) ? backgroundMercadoNuevo : undefined,
                        borderRadius: t === 'Cant. Compra' ? '10px 0 0 0' : t === 'Cant. Venta' ? '0 10px 0 0' : undefined,
                        justifyContent: t === 'Especie' ? "flex-start" : undefined,
                        paddingRight: t === 'Volumen' ? '2em' : 0,
                    }}
                    onClick={ () =>{
                        
                        if (filtraOpciones.current || actualMenu !== "OPCIONES"){
                            ordenarPorTitulos(t)
                        }
                    }}
                >
                    <Texto style={{
                        fontWeight: t === 'Especie' ? 'bold' : 600,
                        paddingLeft: t === 'Especie' ? '3.4em' : undefined,
                        textAlign: t === 'Especie' ? 'start' : undefined,
                    }}>
                        {t}
                    </Texto>
                    {estaOrdenadoPor(t)}

                </ContainerTexto>
            )}
        </Container>
    )
}

export default CardTitulos