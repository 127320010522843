import { TenenciaMercadoLocal } from '../../../interfaces/tenenciaInterface'
import CardDetalleEspecieHome from '../CardDetalleEspecieHome/CardDetalleEspecieHome'
import { changeColor } from '../../../helpers/changeColor'
import { Container, ContainerEspecies, SecondaryContainer, SubtotalTexto, TituloRenta } from './elements'
import { formatCurrency } from '../../../helpers/formatCurrency'
import { formatPorcentaje } from '../../../helpers/formatPorcentaje'

interface Props {
    title?: string,
    tenencia: TenenciaMercadoLocal[],
    subtotal: number;
    porcentaje: number;
    openModalDola?: boolean
    handleOpenModalDola?: () => void
    handleCloseModalDola?: () => void
    handleAceptarModalDola?: () => void
    setTipoDolarImporte?: React.Dispatch<React.SetStateAction<{
        cantidad: number;
        especie: string;
    }>>
    dolarRenta?: {
        dolar7000: boolean;
        dolar10000: boolean;
    }
    setTipoConversion7000?: React.Dispatch<React.SetStateAction<string>>
    tipoConversion7000?: string
}

const DetalleRentas = ({
    title,
    tenencia,
    subtotal,
    porcentaje,
    openModalDola,
    handleAceptarModalDola,
    handleCloseModalDola,
    handleOpenModalDola,
    setTipoDolarImporte,
    dolarRenta,
    setTipoConversion7000,
    tipoConversion7000,
}: Props) => {

    


    return (
        <Container>

            {title && <TituloRenta>Renta {title}</TituloRenta>}

            <ContainerEspecies
                style={{
                    overflowY: dolarRenta ? 'visible' : 'scroll',
                }}
            >
                {tenencia.map((t, i) => (
                    <CardDetalleEspecieHome
                        especie={t.ticker}
                        nombreEspecie={t.nombreEspecie}
                        precio={t.precioActual}
                        cantidad={t.cantidad}
                        importe={t.importe}
                        porcentaje={t.porcentajeTenencia}
                        key={t.nombreEspecie}
                        pintar={changeColor(i)}
                        costoHistorico={t.costoCompra}
                        variacionDia={t.variacionDia}
                        variacionHistorico={t.variacionCosto}
                        openModalDola={openModalDola}
                        handleAceptarModalDola={handleAceptarModalDola}
                        handleCloseModalDola={handleCloseModalDola}
                        handleOpenModalDola={handleOpenModalDola}
                        setTipoDolarImporte={setTipoDolarImporte}
                        dolarRenta={dolarRenta}
                        setTipoConversion7000={setTipoConversion7000}
                        tipoConversion7000={tipoConversion7000}
                    />
                ))}
            </ContainerEspecies>
            <SecondaryContainer>
                <SubtotalTexto style={{ textAlign: 'start', marginLeft: '1em' }}>Subtotal</SubtotalTexto>
                <SubtotalTexto></SubtotalTexto>
                {/* <SubtotalTexto></SubtotalTexto> */}
                <SubtotalTexto>{formatPorcentaje(porcentaje)}%</SubtotalTexto>
                {/* <SubtotalTexto></SubtotalTexto> */}
                <SubtotalTexto></SubtotalTexto>
                <SubtotalTexto></SubtotalTexto>
                <SubtotalTexto style={{ marginRight: '1em' }}>${formatCurrency(subtotal)}</SubtotalTexto>
            </SecondaryContainer>

        </Container>
    )
}

export default DetalleRentas