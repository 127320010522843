import { useContext, useEffect, useState } from 'react'
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { DatosClaveEspecie } from '../interfaces/nombreEspeciesInterfaces';
import { TipoMercado } from '../enums/enums';
import { addUs } from '../helpers/formatEspeciesExterior';

export const useSearchEspecieOperarHook = () => {

    const [data, setData] = useState<DatosClaveEspecie[] | null>(null);
    const [filtrado, setFiltrado] = useState<DatosClaveEspecie[]>([]);
    const { tipoMercado, dataExterior, dataLocal } = useContext(MercadoContext);

    //setea la variable data segun mercado
    useEffect(() => {
        if (tipoMercado === TipoMercado.Local) {
            setData(dataLocal);
        } else {
            setData(dataExterior);
        }
    }, [tipoMercado, dataLocal, dataExterior]);

    useEffect(() => {
        setFiltrado([]);
    }, [tipoMercado])

    //busca la especie en la data
    const searchEspecie = (textoTitulos: string) => {

        if (textoTitulos === '') {
            return null;
        }

        let textoABuscar =
            tipoMercado === TipoMercado.Exterior ? addUs(textoTitulos) : textoTitulos;
        const found = data?.find(element => element.especie === textoABuscar);
        if (found) {
            return found;
        } else {
            return null;
        }
    };

    const searchEspecieFromName = (nombreEspecie: string) => {
        let textoABuscar =
            tipoMercado === TipoMercado.Exterior ? addUs(nombreEspecie) : nombreEspecie;
        const datosEspecie = data?.filter(element => element.especie === textoABuscar);
        return datosEspecie ? datosEspecie[0] : null
    }

    const filtrar = (value: string | undefined) => {
        if (value) {
            let especieBuscada = value;
            if (data) {
                const filteredItems = data.filter(
                    e =>
                        e.especie.toLocaleLowerCase().startsWith(especieBuscada.toLocaleLowerCase())
                );
                setFiltrado(filteredItems.slice(0, 50))
            }
        }
    };

    return {
        data,
        searchEspecie,
        filtrar,
        filtrado,
        searchEspecieFromName,
    }
}
