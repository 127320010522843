import { useContext, useEffect, useRef, useState } from "react";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { TipoMercado } from "../enums/enums";
import { DataEspecie } from "../interfaces/mercadoInferfaces";
import { ThemeContext } from "styled-components";
import { TitulosOrdenarPor } from "../interfaces/TitulosIntreface";
import {
  getDataEspecie,
  getDataPanel,
  getDataPanelOpciones,
} from "../api/mercadoApi";
import { AuthContext } from "../context/authContext/AuthContext";
import {
  arrMenuListMercadoInternacional,
  arrMenuListMercadoLocal,
} from "../helpers/arrayEspecies";
import { getFavourites } from "../api/userApi";

export const useEspeciesHook = (actualMenuEspecies: string) => {

    const { tipoMercado } = useContext(MercadoContext);
    const { tokenPublico } = useContext(AuthContext);
    const { verdeCambioPrecios, rojoCambioPrecios, azulCambioCantidades } = useContext(ThemeContext);

    const [dataListaEspecies, setDataListaEspecies] = useState<DataEspecie[] | null>(null);
    const [colorMap, setColorMap] = useState({});
    const [favoritos, setFavoritos] = useState<DataEspecie[] | null | undefined>([]);
    const [filtrado, setFiltrado] = useState<DataEspecie[] | null | undefined>(favoritos);
    const [noHayData, setNoHayData] = useState<boolean>(false);
    const [errorSocket, setErrorSocket] = useState(false)
    const [loadingEspecies, setLoadingEspecies] = useState(true);
    const [resultadoAnterior, setResultadoAnterior] = useState<DataEspecie[] | null>(null);
    const [textoSearchBar, setTextoSearchBar] = useState<string>('');
    const prevMensajeSocketRef = useRef<DataEspecie[] | null | undefined>(null);
    const [mensajeSocket, setMensajeSocket] = useState<DataEspecie[] | null | undefined>([]);
    const [checkedOperadas, setCheckedOperadas] = useState<boolean>(true);
    const [ordenarPor, setOrdenarPor] = useState<{ titulo: keyof TitulosOrdenarPor, ascendente: boolean }>({ titulo: 'Especie', ascendente: true })

    const OrdenarPorTitulo: TitulosOrdenarPor = {
        'Especie': 'especie',
        'Precio': 'ultimo',
        '% día': 'variacion',
        'Cant. Compra': 'cantcompra',
        'Precio Compra': 'preciocompra',
        'Precio Venta': 'precioventa',
        'Cant. Venta': 'cantventa',
        'Volumen': 'volefectivo',
    }

    const formatRutasPaneles = (panel: string) => {
        switch (panel) {
            case "CEDEARS":
                return "precios_cedears"
            case "GENERAL":
                return "precios_general"
            case "LÍDER":
                return "precios_lider"
            case "BONOS":
                return "precios_bonos"
            case "OPCIONES":
                return "precios_opciones"
            case "FAVORITOS":
                return "precios_favoritos"
            case "30 del DOW":
                return "precios_dow"
            case "ETF":
                return "precios_etf"
            case "ADR":
                return "precios_adr"
            default:
                break;
        }
    }

    const primerIngresoOpciones = useRef(true);
    const envioUndefinedRef = useRef(false)
    const dataRef = useRef<DataEspecie[]>([]); // Almacenar datos sin perderlos por re-renderizados

    useEffect(() => {
        //seteo de flags y conexion del source event
        setErrorSocket(false)
        setNoHayData(false)
        // setSkipFiltrar(false)
        const menu = formatRutasPaneles(actualMenuEspecies)
        let source: EventSource
        // para la conexion de favoritos le enviamos el userId por params
        if (actualMenuEspecies === "FAVORITOS") {
            const userIdStorage = localStorage.getItem("userId")
            if (userIdStorage) {
                const queryParams = new URLSearchParams({ clientId: userIdStorage })
                source = new EventSource(`${process.env.REACT_APP_API_PRECIOS_BACK_PROD}/${menu}?${queryParams}`)
                // source = new EventSource(`${process.env.REACT_APP_API_PRECIOS_BACK}/${menu}?${queryParams}`)
            } else {
                setErrorSocket(false)
                setLoadingEspecies(false)
                return
            }
        } else {
            source = new EventSource(`${process.env.REACT_APP_API_PRECIOS_BACK_PROD}/${menu}`);
            // source = new EventSource(`${process.env.REACT_APP_API_PRECIOS_BACK}/${menu}`);
        }
        primerIngresoOpciones.current = true;
        // .onmessage para escuchar al socket
        source.onmessage = (event) => {
            const receivedData = event.data;
            if (receivedData === "[END]") {
                envioUndefinedRef.current = true
                return;
            }
            try {
                const parsedData = JSON.parse(receivedData);
                // el panel de opciones llega particionado, se envia info de a 2000 especies hasta recibir un undefined
                if (actualMenuEspecies === "OPCIONES") {
                    if (primerIngresoOpciones.current) {
                        // si es primer ingreso de opciones directamente guarda la data en el dataRef
                        dataRef.current = parsedData;
                        primerIngresoOpciones.current = false;
                    } else {
                        // si no es el primer ingreso, inserta o reemplaza la nueva info.
                        const dataMap = new Map(dataRef.current.map(item => [item.especie, item]));
                        // Insertamos o actualizamos los datos nuevos
                        parsedData.forEach((newItem: any) => {
                            dataMap.set(newItem.especie, newItem); // Reemplaza si ya existe
                        });
                        // Convertimos el `Map` de vuelta a un array
                        dataRef.current = Array.from(dataMap.values());
                    }
                } else if (actualMenuEspecies === "FAVORITOS") {
                    // favoritos envia info de mercado local y exterior juntos, aca se filtra
                    dataRef.current = parsedData.filter((m: any) => {
                        return tipoMercado === TipoMercado.Local
                            ? !m.especie?.includes('_US')
                                ? m
                                : undefined
                            : m.especie?.includes('_US')
                                ? m
                                : undefined
                    })
                } else {
                    dataRef.current = parsedData;
                }
                // setea el mensaje del socket
                setMensajeSocket([...dataRef.current]);
                setLoadingEspecies(false);
            } catch (error) {
                console.error("Error al parsear JSON:", error);
            }
        };

        // .onerror maneja los errores de conexion al event source
        source.onerror = (error) => {
            console.error("Error en la conexión SSE:", error);
            setErrorSocket(true)
            setNoHayData(true)
            setLoadingEspecies(false)
            source.close();
        };

        return () => {
            // cierra la conexion al sorce y limpia y resetea todos los filtros y estados
            source.close()
            setMensajeSocket([])
            setResultadoAnterior(null)
            // setSkipFiltrar(true)
            setTextoSearchBar('')
            setLoadingEspecies(true);
            envioUndefinedRef.current = false
            setOrdenarPor({ titulo: 'Especie', ascendente: true })
        }
    }, [actualMenuEspecies, tipoMercado])


    const filtrarDataSocket = (mensaje: DataEspecie[] | null | undefined) => {
        // aca comienza a filtrar la data que se va recibiendo.
        let operadas = mensaje
        // primero filtra por el check de operadas, esto es si tiene precio (ultimo) o no
        if (checkedOperadas) {
            operadas = operadas?.filter((d: DataEspecie) => d.ultimo)
        }
        // aca setea el estado filtrado, que es el que finalmente se muestra en el componente, previamente pasa por la funcion ordenamientos
        setFiltrado(revisarOrdenamientos(operadas))
        setLoadingEspecies(false);
    }

    useEffect(() => {
        // useEffect que se ejecuta cuando cambia el mensaje socket
        // si la data es exactamente igual a la que tenia previamente guarda, se corta la ejecucion
        if (JSON.stringify(prevMensajeSocketRef.current) === JSON.stringify(mensajeSocket)) {
            return;
        }
        // sino se actualiza el mensaje previo
        prevMensajeSocketRef.current = mensajeSocket
        if (prevMensajeSocketRef.current?.length) {
            filtrarDataSocket(prevMensajeSocketRef.current);
        }
    }, [mensajeSocket])

    useEffect(() => {
        // useEffect utlizado para crear el "mapa de colores" de las especies que cambian
        // si hay info en filtrado y en resultado anterior
        if (filtrado && resultadoAnterior) {
            const newColorMap: any = {};
            // recorre el arreglo de filtrado y busca si esa especie esta en el resultado anterior
            filtrado.forEach((datoActual) => {
                const datoAnterior = resultadoAnterior?.find(r => r.especie === datoActual.especie);
                // Si encuentra la especie, valida si hay cambios en los campos específicos ()
                if (datoAnterior) {
                    const cambios = [
                        // datoActual.ultimo !== datoAnterior.ultimo,
                        datoActual.cantcompra !== datoAnterior.cantcompra,
                        datoActual.cantventa !== datoAnterior.cantventa,
                        datoActual.preciocompra !== datoAnterior.preciocompra,
                        datoActual.precioventa !== datoAnterior.precioventa,
                    ];
                    const haCambiado = cambios.some(cambio => cambio);

                    if (haCambiado) {
                        newColorMap[datoActual.especie] = {};

                        if (datoActual.cantcompra !== datoAnterior.cantcompra) {
                            newColorMap[datoActual.especie].cantCompra = azulCambioCantidades;
                        }
                        if (datoActual.cantventa !== datoAnterior.cantventa) {
                            newColorMap[datoActual.especie].cantVenta = azulCambioCantidades;
                        }
                        if (datoActual.preciocompra !== datoAnterior.preciocompra) {
                            newColorMap[datoActual.especie].precioCompra =
                                datoActual.preciocompra > datoAnterior.preciocompra
                                    ? verdeCambioPrecios
                                    : rojoCambioPrecios;
                        }
                        if (datoActual.precioventa !== datoAnterior.precioventa) {
                            newColorMap[datoActual.especie].precioVenta =
                                datoActual.precioventa > datoAnterior.precioventa
                                    ? verdeCambioPrecios
                                    : rojoCambioPrecios;
                        }
                    }
                }
            });
            setColorMap(newColorMap);
            // Mantener el color durante medio segundo
            setTimeout(() => {
                setColorMap({})
            }, 500);
        }
        // actualiza el resultado anterior con filtrado
        filtrado && setResultadoAnterior([...filtrado]);
    }, [filtrado]);

  const ordenarPorTitulos = (titulo: string) => {
    if (titulo === ordenarPor.titulo) {
      setOrdenarPor({ titulo, ascendente: !ordenarPor.ascendente });
    } else {
      setOrdenarPor({
        titulo: titulo as keyof TitulosOrdenarPor,
        ascendente: true,
      });
    }
  };

    const ordenarMensaje = (mensaje: DataEspecie[] | null | undefined) => {
        return mensaje?.sort((a: DataEspecie, b: DataEspecie) => {
            const key = OrdenarPorTitulo[ordenarPor.titulo] as keyof DataEspecie;
            const valorA = a[key];
            const valorB = b[key];
            let numA = typeof valorA === 'number' ? valorA : Number(valorA) || 0;
            let numB = typeof valorB === 'number' ? valorB : Number(valorB) || 0;
            // Si la clave es "Especie" (tipo string), hacer comparación normal
            if (ordenarPor.ascendente) {
                if (ordenarPor.titulo === "Especie") {
                    return String(valorA).localeCompare(String(valorB)); // Comparación de strings
                }
                return numA - numB
            } else {
                if (ordenarPor.titulo === "Especie") {
                    return String(valorB).localeCompare(String(valorA)); // Comparación de strings
                }
                return numB - numA
            }
        })
    }

    const filtrarPorTextoSearchBar = (data: DataEspecie[] | null | undefined) => {
        return data?.filter((d: DataEspecie) => d?.especie?.toLocaleLowerCase().startsWith(textoSearchBar?.toLocaleLowerCase()))
    }

    const revisarOrdenamientos = (data: DataEspecie[] | null | undefined) => {
        // comienza los ordenamientos de la data que envian
        // si es opciones y todavia no se envio el undefined(corte final de opciones), solo filtra por el texto, no ordena la data
        if (actualMenuEspecies === "OPCIONES" && !envioUndefinedRef.current) {
            return filtrarPorTextoSearchBar(data)
        }
        // si ya hay data en filtrado, compara ambos arreglos y los ordena de la misma forma que el filtrado, luego los filtra por texto
        if (filtrado?.length) {
            const dataOrdenada: DataEspecie[] | undefined = []
            filtrado?.forEach(f => {
                let dataActualizada = data?.find((d: DataEspecie) => f.especie === d.especie)
                if (dataActualizada) {
                    dataOrdenada.push(dataActualizada)
                }
            })
            return filtrarPorTextoSearchBar(dataOrdenada)
        }
        return filtrarPorTextoSearchBar(data)
    }

    useEffect(() => {
        // se ejecuta cuando cambia alguno de los filtros
        // utilizo la data guardada en mensajeSocket
        let filtradas = mensajeSocket
        // filtro el checkOperadas
        if (checkedOperadas) {
            filtradas = mensajeSocket?.filter((d: DataEspecie) => d.ultimo)
        }
        // ordeno mensaje por el ordenamiento de titulos
        filtradas = ordenarMensaje(filtradas)
        // filtro por texto
        setFiltrado(filtrarPorTextoSearchBar(filtradas))
    }, [textoSearchBar, checkedOperadas, ordenarPor])

    const handleChangeTextoSearchBar = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTextoSearchBar(e.target.value.toLocaleUpperCase());
    }

    function searchPanelParam(panel: string) {
        const found =
            tipoMercado === TipoMercado.Local
                ? arrMenuListMercadoLocal.filter(e => e.title === panel)
                : arrMenuListMercadoInternacional.filter(e => e.title === panel);
        return found[0].panel;
    }

    const cargarPreciosSinSocket = async (actualMenu: string) => {
        setLoadingEspecies(true);
        setNoHayData(false)
        if (tokenPublico) {
            try {
                const param = searchPanelParam(actualMenu);
                let resp;
                if (actualMenu === "FAVORITOS") {
                    resp = await getFavourites(tokenPublico);
                    let arrLocal: string[] = [];
                    let arrExterior: string[] = [];
                    resp.data.body.favouriteList.forEach(element => {
                        if (element.tipoMercado === 'LOCAL') {
                            arrLocal.push(element.especie);
                        } else {
                            arrExterior.push(element.especie);
                        }
                    });
                    if (tipoMercado === TipoMercado.Local) {
                        if (arrLocal?.length !== 0) {
                            let resp = await getDataEspecie(
                                tokenPublico,
                                arrLocal,
                            );
                            setFavoritos(resp.data.body);
                            setMensajeSocket(resp.data.body)
                        } else {
                            if (arrExterior?.length !== 0) {
                                let resp = await getDataEspecie(
                                    tokenPublico,
                                    arrExterior,
                                );
                                setFavoritos(resp.data.body);
                                setMensajeSocket(resp.data.body)
                            }
                        }
                    }
                } else if (param !== 'OPC') {
                    resp = await getDataPanel(tokenPublico, param!, tipoMercado);
                    setMensajeSocket(resp.data.body);
                } else {
                    resp = await getDataPanelOpciones(tokenPublico);
                    setMensajeSocket(resp.data.body)
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingEspecies(false);
            }
        }
    }

    return {
        dataListaEspecies,
        filtrado,
        setFavoritos,
        setFiltrado,
        setDataListaEspecies,
        loadingEspecies,
        noHayData,
        colorMap,
        handleChangeTextoSearchBar,
        setCheckedOperadas,
        checkedOperadas,
        ordenarPorTitulos,
        ordenarPor,
        cargarPreciosSinSocket,
        errorSocket,
        envioUndefinedRef,
    }
}

