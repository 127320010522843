import styled from "styled-components";

export const Container = styled.div`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
    flex-grow: 1;
`

export const ContainerSecundario = styled.div`
    position: sticky;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};   
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
    top: 8vh;
`

export const ContainerTitulos = styled.div`
    display: grid;
    // grid-template-columns: 1.5fr repeat(2, 0.8fr) repeat(2, 1fr) 0.8fr 1fr 1.5fr;
    grid-template-columns: 1fr 0.8fr 1fr 0.8fr 1fr 1.5fr;
    text-align: center;
`

export const ContainerTitulosExterior = styled.div`
    display: grid;
    // grid-template-columns: 1.3fr repeat(2, 0.8fr) 1fr 0.8fr 1fr 1.5fr;
    grid-template-columns: 1fr repeat(2, 0.8fr) 1fr 1.5fr;
    text-align: center;
`

export const TextoTitulos = styled.p`
    font-size: 14px;
    color: ${({ theme }) => theme.primaryColor};
    font-weight: 600;
    align-self: center;
    margin-top: 0em;
    margin-bottom: 0em;
`