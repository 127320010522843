import styled from "styled-components";
import { colors } from "../../theme/Themes";


export const Container = styled.div`
flex: 1;
height: 100vh;
background-color:  ${({ theme }) => theme.secondaryBackgroundColor};
@media (min-width: 992px) {
    margin-top: 8vh;
    margin-left: 12vw;
    height: 92vh;
    width: 87vw;
    padding: 0vh 0.5vw 0 0.5vw;
}
`

export const SecondContainer = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    margin: 1em;
    margin-top: 2em;
    border-radius: 10px;
    @media (min-width: 992px) {
        margin-top: 1.2em;
    }
`

export const Titulo = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    font-size: 20px;
    font-weight: 600;
    padding: 0.5em;
    margin: 0;
    // border-bottom: 1px solid ${({ theme }) => theme.primaryBorderColor};
    @media (min-width: 992px) {
        font-size: 22px;
    }
`

export const Texto = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    font-size: 18px;
    padding: 0.5em;
    margin 0;
    // border-bottom: 1px solid ${({ theme }) => theme.primaryBorderColor};
`
export const TextoSaldos = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    padding-left: 0.5em;
    font-size: 16px;
    margin 0;
    // border-bottom: 1px solid ${({ theme }) => theme.primaryBorderColor};
`

export const ContainerTipoMoneda = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    border-top: 1px solid ${({ theme }) => theme.primaryBorderColor};
    border-bottom: 1px solid ${({ theme }) => theme.primaryBorderColor};
    cursor: pointer;
`

export const InputField = styled.input`
    width: -webkit-fill-available;
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    border: none;
    padding: 0.8em;
    border-radius: 10px;
    outline: none;
    font-size: 16px;
    color: ${({ theme }) => theme.primaryColor};
`

export const ButtonConfirmar = styled.button`
    background-color: ${colors.blueRava};
    letter-spacing: 1.25px;
    margin: 1em;
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: 2px solid ${colors.blueRava};
    border-radius: 5px;
    width: stretch;
    height: 2.5em;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 15px;
        letter-spacing: 0px;
        height: 3em;
    }
    @media (min-width: 1490px) {
        font-size: 18px;
        letter-spacing: 1.25px;
        height: 2.5em;
        white-space:normal !important;
        word-wrap:break-word;
    }
`


export const ButtonCancelar = styled.button`
    background-color: ${({ theme }) => theme.backgroundWhiteToGray};
    letter-spacing: 1.25px;
    margin: 1em;
    color: ${({ theme }) => theme.accentColor};
    font-size: 18px;
    font-weight: 600;
    border: 2px solid ${colors.grayRava};
    border-radius: 5px;
    width: stretch;
    height: 2.5em;
    @media (min-width: 992px) {
        font-size: 15px;
        letter-spacing: 0px;
        height: 3em;
    }
    @media (min-width: 1490px) {
        font-size: 18px;
        letter-spacing: 1.25px;
        height: 2.5em;
        white-space:normal !important;
        word-wrap:break-word;
    }
`