import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.primaryBackgroundColor};   
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    padding-top: 0.5em;
    @media (min-width: 992px) {
        flex-direction: row;
        align-items: center;
        height: 5em;
        margin-top: 0.8em;
        padding-top: 0em;
	}
`

export const Titulo = styled.p`
    margin: 0px;    
    margin-left: 1em;
    padding-top: 0.6em;
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 14px;
    font-weight: 700;
    @media (min-width: 992px) {
        padding-top: 0em;
        font-size: 18px;
	}
`

export const Pesos = styled.p`
    margin: 0px;
    margin-left: 1.25em;
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 14px;
    font-weight: 700;
    margin-right: 1em;
    @media (min-width: 992px) {
        font-size: 18px;
        text-align: end;
	}
`

export const Vencido = styled.p`
    margin: 0px;
    margin-left: 1.25em;
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 10px;
    font-weight: 500;
    @media (min-width: 992px) {
        font-size: 14px;
        margin-right: 1em;
        text-align: end;
	}
`
export const Veinticuatro = styled.p`
    margin: 0px;
    margin-left: 1.25em;
    color: ${({ theme }) => theme.errorColor};;
    font-size: 10px;
    font-weight: 500;
    @media (min-width: 992px) {
        font-size: 14px;
        margin-right: 1em;
        text-align: end;
	}
`

export const Dolares = styled.p`
    margin: 0px;
    margin-left: 1.25em;
    color: ${({ theme }) => theme.accentColor};;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 0.5em;
    margin-right: 1em;
    @media (min-width: 992px) {
        font-size: 18px;
        padding-bottom: 0px;
        text-align: end;
	}
`

export const DolaresExterior = styled.p`
    margin: 0px;
    margin-left: 1.25em;
    color: ${({ theme }) => theme.primaryColor};;
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 0.5em;
    margin-right: 1em;
    @media (min-width: 992px) {
        font-size: 18px;
        padding: 0;
        margin-left: 0;
        text-align: end;
	}
`