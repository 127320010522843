import React, { useContext } from "react";
import "./App.css";
import { Theme } from "./enums/theme";
import { Themes } from "./theme/Themes";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { AppRouter } from "./router/AppRouter";
import { UserProvider } from "./context/userContext/UserContext";
import { AuthProvider } from "./context/authContext/AuthContext";
import { MercadoProvider } from "./context/mercadoContext/MercadoContext";
import { NavigationProvider } from "./context/navigationContext/NavigationContext";
import { EspeciesProvider } from "./context/especiesFavoritasContext/EspeciesFavoritasContext";
import { DetalleProvider } from "./context/detalleEspecieContext/DetalleContext";
import { NotificacionProvider } from "./context/notificacionContext/NotificacionContext";
import { OperarProvider } from "./context/operarContext/OperarContext";
import { ThemeContext } from "./context/themeContext/ThemeContext";
import { RetirarDineroProvider } from "./context/retirarDineroContext/RetirarDineroContext";

function App() {

  const { theme } = useContext(ThemeContext)

  const GlobalStyle = createGlobalStyle`
	body{
    background-color: ${() => (theme === Theme.Dark ? Themes.dark.primaryBackgroundColor : Themes.light.primaryBackgroundColor)};
		color: ${() => (theme === Theme.Dark ? Themes.dark.primaryBackgroundColor : Themes.light.primaryBackgroundColor)};
	}
  #root{
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .css-r4hv2p-MuiButtonBase-root-MuiButton-root.Mui-disabled { 
    background-color: gray !important; 
    color: white !important;
  }
  ::-webkit-scrollbar {
    width: 15px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: ${() => (theme === Theme.Dark ? Themes.dark.primaryBackgroundColor : Themes.light.primaryBackgroundColor)};
    width: none;
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${() => (theme === Theme.Dark ? Themes.dark.secondaryBackgroundColor : Themes.light.grayToDarkGrayBackground)};
    border-radius: 6px; 
    width: none;
    display: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active,
  input:-webkit-autofill::first-line{
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${() => (theme === Theme.Dark ? Themes.dark.primaryColor : Themes.light.primaryColor)};
    box-shadow: inset 0 0 0px 0px #23232329;
    }

  `


  return (
    <div style={{ display: "flex", flexDirection: "column", height: '100%' }}>
      <ThemeProvider theme={Themes[theme]}>
        <GlobalStyle />
        <AuthProvider>
          <UserProvider>
            <NotificacionProvider>
              <MercadoProvider>
                <NavigationProvider>
                  <EspeciesProvider>
                    <DetalleProvider>
                      <OperarProvider>
                        <RetirarDineroProvider>
                          <AppRouter />
                        </RetirarDineroProvider>
                      </OperarProvider>
                    </DetalleProvider>
                  </EspeciesProvider>
                </NavigationProvider>
              </MercadoProvider>
            </NotificacionProvider>
          </UserProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
