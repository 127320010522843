import { useContext } from 'react'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import { OperarContext } from '../../context/operarContext/OperarContext';
import { ButtonAceptarConfirmar, ButtonCancelar, Container, ContainerButtons, ContainerCards, ContainerDDJJ, ContainerExitoError, ContainerLoader, ContainerPrincipal, MensajeError, MensajeExito, OperarTitle, TextoDDJJ, TituloError, TituloExito } from './elements';
import { Headers } from '../../components/HeadersComponents/Headers/Headers'
import { TipoMercado } from '../../enums/enums';
import { useComprarVenderHook } from '../../hooks/useComprarVenderHook';
import CardConfirmar from '../../components/operarComponents/CardConfirmar/CardConfirmar';
import { formatEspeciesExterior } from '../../helpers/formatEspeciesExterior';
import { formatCurrency } from '../../helpers/formatCurrency';
import { CircularProgress } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';

const ComprarScreen = () => {

    const { tipoMercado } = useContext(MercadoContext);
    const { importe, actualMenuLimit, actualOption } = useContext(OperarContext);
    const {
        condicionddjj,
        getTextoDDJJ,
        setAceptoDDJJ,
        setCancelo,
        confirmo,
        especieOperada,
        precio,
        cantidad,
        setConfirmo,
        loading,
        error,
        nroOrden,
        tituloError,
        mensajeError,
    } = useComprarVenderHook();

    const { width } = useWindowDimensions();

    const { loadingColor, primaryColor, rojoPorcentaje } = useContext(ThemeContext)

    function getSymbol(): string {
        return tipoMercado === TipoMercado.Exterior ? 'USD ' : '$';
    }

    function returnNameFormat(): string {
        let nombre = '';
        if (especieOperada) {
            nombre =
                tipoMercado === TipoMercado.Exterior
                    ? formatEspeciesExterior(especieOperada.especie)
                    : especieOperada.especie;
        }
        return nombre;
    }


    return (
        <Container>

            {
                width < 992 ?
                    <Headers titulo='Operar' showSwitch={true} />
                    :
                    <OperarTitle>Operar</OperarTitle>
            }

            {condicionddjj() ?
                (
                    <ContainerPrincipal>
                        <ContainerDDJJ>
                            <div style={{ borderRadius: '10px' }}>
                                {getTextoDDJJ().map((texto, i) => (
                                    <TextoDDJJ key={i}>{texto}</TextoDDJJ>
                                ))
                                }
                            </div>
                        </ContainerDDJJ>
                        {/* aca van los botones */}
                        <ContainerButtons>
                            <ButtonAceptarConfirmar
                                onClick={() => setAceptoDDJJ(true)}
                            >ACEPTO</ButtonAceptarConfirmar>
                            <ButtonCancelar
                                onClick={() => setCancelo(true)}
                            >CANCELAR</ButtonCancelar>
                        </ContainerButtons>
                    </ContainerPrincipal>
                )
                : !confirmo ?
                    (
                        <ContainerPrincipal>
                            <ContainerCards>
                                <CardConfirmar titulo="Tipo de operación" texto={actualOption} negrita={true} />
                                <CardConfirmar titulo="Especie" texto={returnNameFormat()} />
                                <CardConfirmar titulo="Plazo" texto="24 hs." />
                                <CardConfirmar titulo="Tipo de orden" texto={actualMenuLimit} />
                                <CardConfirmar titulo="Duración" texto="Diaria" />
                                <CardConfirmar
                                    titulo="Precio"
                                    texto={
                                        precio === '' || precio === '0'
                                            ? `${getSymbol()} -`
                                            : getSymbol() + formatCurrency(precio.replace(',', '.'))
                                    } />
                                <CardConfirmar titulo="Cantidad" texto={cantidad} />
                                <CardConfirmar titulo="Importe" texto={importe} />
                            </ContainerCards>
                            <ContainerButtons>
                                <ButtonAceptarConfirmar
                                    onClick={() => setConfirmo(true)}
                                >
                                    CONFIRMAR
                                </ButtonAceptarConfirmar>
                                <ButtonCancelar
                                    onClick={() => setCancelo(true)}
                                >
                                    CANCELAR
                                </ButtonCancelar>
                            </ContainerButtons>
                        </ContainerPrincipal>
                    )
                    : !loading ?
                        (
                            <div>
                                {!error ?
                                    <ContainerExitoError>
                                        <TaskAltIcon sx={{ fontSize: '50px', color: primaryColor }} />
                                        <TituloExito>Enviaste tu orden con éxito.</TituloExito>
                                        <MensajeExito>Estamos procesando tu orden.</MensajeExito>
                                        <MensajeExito>{`Orden N° ${nroOrden}`}</MensajeExito>
                                    </ContainerExitoError>
                                    :
                                    <ContainerExitoError>
                                        <CancelOutlinedIcon sx={{ fontSize: '50px', color: rojoPorcentaje }} />
                                        <TituloError>{tituloError}</TituloError>
                                        <MensajeError>{mensajeError}</MensajeError>
                                    </ContainerExitoError>
                                }
                            </div>
                        )
                        :
                        (
                            <ContainerLoader>
                                <CircularProgress size={80} thickness={4} style={{ color: loadingColor }} />
                            </ContainerLoader>
                        )
            }
        </Container >
    )
}

export default ComprarScreen