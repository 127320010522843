import { useContext, useEffect } from 'react'
import { Container, ContainerSearchLupa, Lupa, SearchInput } from './elements'
import SearchIcon from '@mui/icons-material/Search';
import { OperarContext } from '../../../context/operarContext/OperarContext';
import { useSearchEspecieOperarHook } from '../../../hooks/useSearchEspecieOperarHook';
import { MenuItem, Popover } from '@mui/material';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import { DatosClaveEspecie } from '../../../interfaces/nombreEspeciesInterfaces';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import { DetalleContext } from '../../../context/detalleEspecieContext/DetalleContext';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import { TipoMercado } from '../../../enums/enums';

const SearchOperar = () => {

    const { textoTitulos, onChangeTitulo, anchorElMenuOperar, openMenuSearchOperar, handleCloseMenuOperar, } = useContext(OperarContext);

    const {  getOfertasEspecie, setNombreEspecieOfertas } = useContext(DetalleContext);

    const { tipoMercado } = useContext(MercadoContext);

    const { filtrar, filtrado } = useSearchEspecieOperarHook();

    const { width } = useWindowDimensions();

    const { primaryColor, grayToDarkGrayBackground, hoverMenuBackrgound } = useContext(ThemeContext);

    useEffect(() => {
        filtrar(textoTitulos)
    }, [textoTitulos])

    const styles = {
        menuItems: {
            backgroundColor: grayToDarkGrayBackground,
            borderBottom: `0.2px solid ${grayToDarkGrayBackground}`,
            padding: '0.5em',
            width: '11em',
            color: primaryColor,
            '&:hover': {
                backgroundColor: `${hoverMenuBackrgound} !important`,
            }
        }
    }

    return (
        <Container>
            <ContainerSearchLupa>
                <SearchInput
                    onChange={(e) => onChangeTitulo(e)}
                    value={textoTitulos}
                    aria-label='titulos'
                />
                <Lupa>
                    <SearchIcon sx={{ fontSize: '1em', backgroundColor: 'transparent' }} />
                </Lupa>
            </ContainerSearchLupa>
            <Popover
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorElMenuOperar}
                open={openMenuSearchOperar && filtrado?.length > 0}
                onClose={() => handleCloseMenuOperar()}
                disableAutoFocus
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        marginTop: width < 992 ? '2.3em !important' : '1.2em !important',
                        maxHeight: width < 992 ? '25em' : '40%',
                        backgroundColor: 'transparent',
                        '& ul': {
                            padding: '0px',
                        },
                    }
                }}
            >
                {
                    filtrado?.map((lista: DatosClaveEspecie) =>
                        <MenuItem
                            key={lista.especie}
                            sx={styles.menuItems}
                            onClick={() => {
                                handleCloseMenuOperar(lista)
                                if (tipoMercado === TipoMercado.Local){
                                    setNombreEspecieOfertas(lista.especie)
                                    getOfertasEspecie(lista.especie_completa ? lista.especie_completa : lista.especie)
                                }
                            }}
                        >
                            {formatEspeciesExterior(lista.especie)}
                        </MenuItem>
                    )
                }
            </Popover>
        </Container>
    )
}

export default SearchOperar

