import { LocalizationProvider } from '@mui/x-date-pickers'
import CustomDatePicker from '../components/MovimientosComponents/CustomDataPicker/CustomDataPicker';
import withTheme from './withTheme';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import { esES } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import useWindowDimensions from '../hooks/useWindowDimensions';

const ThemedCustomDatePicker = withTheme(CustomDatePicker);

interface Props {
    fechaPrimaria: dayjs.Dayjs | null;
    fechaSecundariaDesde?: dayjs.Dayjs | null;
    fechaSecundariaHasta?: dayjs.Dayjs | null;
    seteoFecha: (value: React.SetStateAction<dayjs.Dayjs | null>) => void;
    tituloCalendario: string;
}

const CalendarioFechas = ({ fechaPrimaria, fechaSecundariaDesde, fechaSecundariaHasta, seteoFecha, tituloCalendario }: Props) => {

    const { primaryBackgroundColor, primaryColor, alternativeBorderColor, accentColor } = useContext(ThemeContext)

    const propsDataPicker = {
        '&.MuiPickersPopper-root .MuiPaper-root': {
            backgroundColor: primaryBackgroundColor,
            color: primaryColor,
        },
        '&.MuiPickersPopper-root .MuiPaper-root .MuiIconButton-sizeSmall': {
            color: primaryColor,
        },
        '&.MuiPickersPopper-root .MuiPaper-root .MuiPickersArrowSwitcher-button': {
            color: primaryColor,
            '&.Mui-disabled': {
                opacity: 0.5
            }
        },
        '&.MuiPickersPopper-root .MuiPaper-root .MuiTypography-root': {
            color: primaryColor,
            opacity: 0.9,
        },
        '&.MuiPickersPopper-root .MuiPaper-root .MuiPickersDay-root': {
            color: primaryColor,
            '&.Mui-disabled': {
                opacity: 0.5
            },
            '&.Mui-selected': {
                backgroundColor: alternativeBorderColor,
            }
        },
        '&.MuiPickersPopper-root .MuiPaper-root .MuiPickersDay-today': {
            border: `1px solid ${accentColor}`
        },
    }

    const { width } = useWindowDimensions()

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale='es'
            localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <ThemedCustomDatePicker
                label={tituloCalendario}
                defaultValue={dayjs()}
                format="DD/MM/YYYY"
                disableFuture
                value={fechaPrimaria}
                maxDate={fechaSecundariaDesde ? fechaSecundariaDesde : undefined}
                minDate={fechaSecundariaHasta ? fechaSecundariaHasta : undefined}
                onChange={(newValue) => seteoFecha(newValue)}
                slots={{
                    openPickerIcon: CalendarMonthOutlinedIcon,
                }}
                sx={{
                    width: width > 1200 ? '9em' : '8.5em',
                    textOverflow: 'ellipsis'
                }}
                slotProps={{
                    popper: {
                        sx: propsDataPicker
                    },
                    textField: {
                        size: 'small',
                    }
                }}
            />
        </LocalizationProvider>
    )
}

export default CalendarioFechas