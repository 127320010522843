import styled from "styled-components";


export const Container = styled.div`
    margin: 0.8em 0 0 0em;
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    border-radius: 5px;
    width: 75%;
    align-items: center;
    @media (min-width: 992px){
        width: auto;
        margin: 0;
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
    }
`

export const ContainerSearchLupa = styled.div`
    display: flex; 
    border-radius: 5px; 
    align-items: center; 
    @media (min-width: 992px) {
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
        width: 100%;
        }
`

export const SearchInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 400;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    color: ${({theme}) => theme.primaryColor};
    padding: 0.6em;
    @media (min-width: 992px) {
        padding: 0;
        margin-left: 1em;
        width: 90%;
        font-size: 14px;
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
	}
`

export const Lupa = styled.div`
    margin-right: 0.5em;
    color: ${({theme}) => theme.primaryColor};
    @media (min-width: 992px) {
        display: flex;
    }
`

