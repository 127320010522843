import { useContext, useState } from "react"
import { MercadoContext } from "../../../context/mercadoContext/MercadoContext"
import { UserContext } from "../../../context/userContext/UserContext"
import { TipoMercado } from "../../../enums/enums"
import { formatCurrency } from "../../../helpers/formatCurrency"
import { Container, Dolares, DolaresExterior, Pesos, Titulo, Veinticuatro, Vencido } from "./elements"
import { ThemeContext } from "../../../context/themeContext/ThemeContext"
import { Theme } from "../../../enums/theme"


export const SaldoCartera = () => {

    const { tenencia } = useContext(UserContext);
    const { tipoMercado } = useContext(MercadoContext);
    const [hidden, setHidden] = useState(true);
    const [hiddenBillete, setHiddenBillete] = useState(true);
    const { theme } = useContext(ThemeContext)

    return tipoMercado === TipoMercado.Local ?
        (
            <Container>
                <div>
                    <Titulo>Saldo disponible</Titulo>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                    <div style={{
                        backgroundColor: theme === Theme.Light ? "#F9F9F9" : "transparent", borderRadius: "5px",
                        transition: "opacity 0.3s ease, transform 0.3s ease",
                        opacity: hidden && hiddenBillete ? 0 : 1,
                        // transform: hidden ? "translateY(-10px)" : "translateY(0)",
                        pointerEvents: hidden && hiddenBillete ? "none" : "auto",
                    }}>
                        <Vencido>Vencido: {tenencia ? formatCurrency(!hidden ? tenencia.saldo.saldoCIPesos : tenencia.saldo.saldoCIDolarBi) : 0}</Vencido>
                        <Veinticuatro>24h.: {tenencia ? formatCurrency(!hidden ? tenencia.saldo.saldo24HsPesos : tenencia.saldo.saldo24HsDolarBi) : 0}</Veinticuatro>
                    </div>
                    {/* <div style={{
                        backgroundColor: theme === Theme.Light ? "#F9F9F9" : "transparent", borderRadius: "5px",
                        transition: "opacity 0.3s ease, transform 0.3s ease",
                        opacity: hiddenBillete ? 0 : 1,
                        // transform: hidden ? "translateY(-10px)" : "translateY(0)",
                        pointerEvents: hiddenBillete ? "none" : "auto",
                    }}>
                        <Vencido>Vencido: {tenencia ? formatCurrency(tenencia.saldo.saldoCIDolarBi) : 0}</Vencido>
                        <Veinticuatro>24h.: {tenencia ? formatCurrency(tenencia.saldo.saldo24HsDolarBi) : 0}</Veinticuatro>
                    </div> */}
                    <div>
                        <Pesos onMouseEnter={() => setHidden(false)}
                            onMouseLeave={() => setHidden(true)}>$ {tenencia ? formatCurrency(tenencia?.saldo.pesos) : 0}</Pesos>
                        <Dolares onMouseEnter={() => setHiddenBillete(false)}
                            onMouseLeave={() => setHiddenBillete(true)}>USD {tenencia ? formatCurrency(tenencia?.saldo.dolarBillete) : 0}</Dolares>
                    </div>
                </div>
            </Container>
        ) :
        (
            <Container>
                <div>
                    <Titulo>Saldo disponible</Titulo>
                </div>
                <div>
                    <DolaresExterior>USD {tenencia ? formatCurrency(tenencia?.saldo.dolarExterior) : 0}</DolaresExterior>
                </div>
            </Container>
        )
}
