import React, { useContext } from 'react'
import { ContainerTitulos, TextoDatos } from './elements';
import { TenenciaMercadoExterior, TenenciaMercadoLocal } from '../../../interfaces/tenenciaInterface';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import { ThemeContext } from 'styled-components';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import { TipoMercado } from '../../../enums/enums';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';
import { CircularProgress } from '@mui/material';
import { LoadingContainer } from '../../../elements/loadingContainer';

interface Props {
    data: TenenciaMercadoLocal[] | TenenciaMercadoExterior[] | undefined;
    confirmarDatos: boolean;
    cargarDatosOperar?: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    loadingRefresher: boolean;
    loading: boolean;
}
const ResultadoDelDia = ({ data, confirmarDatos, cargarDatosOperar, loadingRefresher, loading }: Props) => {

    const { primaryBackgroundColor, alterBackgroundCardsHome, buyColor, rojoPorcentaje, primaryColor, loadingColor } = useContext(ThemeContext);
    const { tipoMercado } = useContext(MercadoContext);

    return (
        <div>
            {
                loadingRefresher || loading
                    ? <LoadingContainer style={{ height: '100%', marginTop: '1.5em' }}>
                        <CircularProgress style={{ color: loadingColor, width: '20px', height: '20px' }} />
                    </LoadingContainer>
                    : data &&
                    data.map((d, i) =>
                        <ContainerTitulos
                            key={i}
                            style={{
                                backgroundColor: i % 2 !== 0
                                    ? primaryBackgroundColor
                                    : alterBackgroundCardsHome
                            }}
                        >
                            <TextoDatos
                                style={{
                                    textAlign: 'start',
                                    fontWeight: 'bold'
                                }}
                                onClick={(e) => {
                                    if (!confirmarDatos && cargarDatosOperar) {
                                        tipoMercado === TipoMercado.Local
                                            ? cargarDatosOperar(d.ticker, d.precioActual, d.cantidad, d.ticker + "-0002-C-CT-ARS")
                                            : cargarDatosOperar(formatEspeciesExterior(d.ticker), d.precioActual, d.cantidad)
                                    }
                                }}
                            >
                                {tipoMercado === TipoMercado.Local
                                    ? d.ticker
                                    : formatEspeciesExterior(d.ticker)
                                }
                            </TextoDatos>
                            <TextoDatos
                                onClick={(e) => {
                                    if (!confirmarDatos && cargarDatosOperar) {
                                        tipoMercado === TipoMercado.Local
                                            ? cargarDatosOperar(d.ticker, d.precioActual, d.cantidad)
                                            : cargarDatosOperar(formatEspeciesExterior(d.ticker), d.precioActual, d.cantidad)
                                    }
                                }}
                            >
                                {separacionDecimal(d.cantidad)}
                            </TextoDatos>
                            <TextoDatos
                                onClick={(e) => {
                                    if (!confirmarDatos && cargarDatosOperar) {
                                        tipoMercado === TipoMercado.Local
                                            ? cargarDatosOperar(d.ticker, d.costoCompra, d.cantidad)
                                            : cargarDatosOperar(formatEspeciesExterior(d.ticker), d.costoCompra, d.cantidad)
                                    }
                                }}
                            >
                                {formatCurrency(d.cierreAnterior)}
                            </TextoDatos>
                            <TextoDatos
                                onClick={(e) => {
                                    if (!confirmarDatos && cargarDatosOperar) {
                                        tipoMercado === TipoMercado.Local
                                            ? cargarDatosOperar(d.ticker, d.precioActual, d.cantidad)
                                            : cargarDatosOperar(formatEspeciesExterior(d.ticker), d.precioActual, d.cantidad)
                                    }
                                }}
                            >
                                {formatCurrency(d.precioActual)}
                            </TextoDatos>
                            <TextoDatos
                                style={{
                                    color: (d.variacionDia && d.variacionDia > 0)
                                        ? buyColor
                                        : (d.variacionDia && d.variacionDia < 0)
                                            ? rojoPorcentaje
                                            : primaryColor,
                                    cursor: 'default',
                                }}
                            >
                                {formatPorcentaje(d.variacionDia)}
                            </TextoDatos>
                        </ContainerTitulos>
                    )}
        </div>
    )
}

export default ResultadoDelDia