import { useContext } from 'react'
import { Container, ContainerDolarButton, ContainerSecundario, Texto, TextoEspecie } from './elements';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import { separacionDecimal } from '../../../helpers/separacionDecimal';
import { formatCurrency } from '../../../helpers/formatCurrency';
import useDetalleEspecieTenencias from '../../../hooks/useDetalleEspecieTenencias';
import { formatPorcentaje } from '../../../helpers/formatPorcentaje';
import CachedIcon from '@mui/icons-material/Cached';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import { useMenuOperarHook } from '../../../hooks/useMenuOperarHook';
import MenuPopUp from '../../MenuPopUp/MenuPopUp';

interface Props {
    especie: string;
    nombreEspecie: string;
    precio: number;
    cantidad: number;
    importe: string | number;
    porcentaje: number;
    key: string;
    pintar: boolean;
    costoHistorico: number;
    variacionDia: number;
    variacionHistorico: number | null;
    openModalDola?: boolean
    handleOpenModalDola?: () => void
    handleCloseModalDola?: () => void
    handleAceptarModalDola?: () => void
    setTipoDolarImporte?: React.Dispatch<React.SetStateAction<{
        cantidad: number;
        especie: string;
    }>>
    dolarRenta?: {
        dolar7000: boolean;
        dolar10000: boolean;
    }
    setTipoConversion7000?: React.Dispatch<React.SetStateAction<string>>
    tipoConversion7000?: string,
}

const CardDetalleEspecie = ({
    especie,
    nombreEspecie,
    precio,
    cantidad,
    importe,
    porcentaje,
    pintar,
    costoHistorico,
    variacionDia,
    variacionHistorico,
    openModalDola,
    handleOpenModalDola,
    handleCloseModalDola,
    handleAceptarModalDola,
    setTipoDolarImporte,
    dolarRenta,
    setTipoConversion7000,
    tipoConversion7000,
}: Props) => {

    const { width } = useWindowDimensions();

    const { primaryBackgroundColor, alterBackgroundCardsHome, rojoPorcentaje, primaryColor, secondaryBackgroundColor } = useContext(ThemeContext);

    const { onClickEspecie } = useDetalleEspecieTenencias();

    const esDolarDiezMil = (dolar: string, esModal?: boolean) => {
        return esModal
            ? dolar.includes('10000') ? 'DOLA 10000' : 'DOLA 7000'
            : dolar.includes('10000') ? 'DOLAR 10.000' : 'DOLAR 7.000';
    }

    const tienePedidoRenta = (dolar: string) => {
        if (dolar.includes('10000')) {
            return dolarRenta?.dolar10000
        }
        if (dolar.includes('7000')) {
            return dolarRenta?.dolar7000
        }
    }


    const textoTooltip = (
        <>
            Tu pedido de conversión de <strong>dólar renta</strong> esta pendiente. Puede tardar <strong>hasta 96hs. hábiles.</strong>
        </>
    )

    const {
        listaTipoPedidoDolar7000,
        handleOpenMenu: handleOpenMenuPedidoDolar,
        open: openTipoPedidoDolar,
        anchorEl: anchorElTipoPedidoDolar,
        handleCloseMenuTipoDolar,
    } = useMenuOperarHook(setTipoConversion7000, handleOpenModalDola);

    return (
        <Container
            style={{
                backgroundColor: width > 992 && pintar ? alterBackgroundCardsHome : primaryBackgroundColor,
            }}
        >

            {
                especie === "DOLA" ?
                    <ContainerSecundario>
                        <TextoEspecie style={{ textAlign: 'start', marginLeft: '1em', marginRight: '0.5em' }}>
                            {esDolarDiezMil(nombreEspecie)}
                        </TextoEspecie>
                        {!tienePedidoRenta(nombreEspecie)
                            ? <><ContainerDolarButton
                                onClick={(e) => {
                                    setTipoDolarImporte && setTipoDolarImporte((prev) => ({
                                        ...prev,
                                        especie: esDolarDiezMil(nombreEspecie, true),
                                        cantidad: Number(cantidad),
                                    }))
                                    if (nombreEspecie.includes('7000')) {
                                        handleOpenMenuPedidoDolar(e)
                                    } else {
                                        handleOpenModalDola && handleOpenModalDola();
                                    }
                                }}
                            >
                                <CachedIcon
                                    style={{
                                        color: primaryColor,
                                        fontSize: '1.2em',
                                        backgroundColor: secondaryBackgroundColor,
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </ContainerDolarButton>
                                <MenuPopUp
                                    open={openTipoPedidoDolar}
                                    anchorEl={anchorElTipoPedidoDolar}
                                    handleCloseMenu={handleCloseMenuTipoDolar}
                                    listaMenu={listaTipoPedidoDolar7000}
                                    actualMenu={tipoConversion7000}
                                    // esOperar={true}
                                />
                            </>
                            :
                            <TooltipComponent
                                text={textoTooltip}
                            >
                                <ContainerDolarButton>
                                    <ErrorOutlineIcon
                                        style={{
                                            color: primaryColor,
                                            fontSize: '1.2em',
                                            backgroundColor: secondaryBackgroundColor,
                                            borderRadius: '10px',
                                        }}
                                    />
                                </ContainerDolarButton>
                            </TooltipComponent>
                        }
                        <ModalConfirmacion
                            open={openModalDola ? openModalDola : false}
                            handleClose={handleCloseModalDola ? handleCloseModalDola : () => { }}
                            handleConfirmarModal={handleAceptarModalDola ? handleAceptarModalDola : () => { }}
                            tituloModal={tipoConversion7000 === "exterior" ? "¿Querés solicitar la conversión de la especie DOLAR 7.000 para invertir en el exterior?" :'¿Querés convertir a dólar billete?'}
                            textoModal={'El proceso puede tardar hasta 96hs. hábiles.'}
                            textoConfirmarModal={'Aceptar'}
                        />

                    </ContainerSecundario>
                    :
                    <div style={{ display: 'flex' }}>
                        <TextoEspecie style={{ textAlign: 'start', marginLeft: '1em' }}
                            onClick={() => onClickEspecie(especie)}
                        >
                            {especie}
                        </TextoEspecie>
                    </div>
            }

            {
                variacionDia === 0 || variacionDia === null ?
                    <Texto style={{ textAlign: 'end' }}>-</Texto>
                    : variacionDia > 0 ?
                        <Texto style={{ textAlign: 'end', color: '#03DAC5', }}>{formatPorcentaje(variacionDia)}</Texto>
                        :
                        <Texto style={{ textAlign: 'end', color: rojoPorcentaje, }}>{formatPorcentaje(variacionDia)}</Texto>

            }
            {/* {
                variacionHistorico === 0 || variacionHistorico === null ?
                    <Texto style={{ textAlign: 'end' }}>-</Texto>
                    : variacionHistorico > 0 ?
                        <Texto style={{ textAlign: 'end', color: '#03DAC5' }}>{formatPorcentaje(variacionHistorico)}</Texto>
                        :
                        <Texto style={{ textAlign: 'end', color: rojoPorcentaje }}>{formatPorcentaje(variacionHistorico)}</Texto>

            } */}
            <Texto
                style={{
                    textAlign: 'end',
                    marginRight: '0.3em'
                }}
            >
                {formatPorcentaje(porcentaje, 2)}
            </Texto>
            {/* <Texto>{`$${formatCurrency(costoHistorico)}`}</Texto> */}
            <Texto style={{ marginRight: '0em' }}>{especie === "DOLA" ? formatCurrency(cantidad) : separacionDecimal(cantidad)}</Texto>
            <Texto style={{ marginRight: '0em' }}>{`$${formatCurrency(precio)}`}</Texto>
            <Texto style={{ marginRight: '1em' }}>{`$${formatCurrency(importe)}`}</Texto>

        </Container>
    )
}

export default CardDetalleEspecie