import { useContext } from 'react';
import { UserContext } from '../../../context/userContext/UserContext'
import { useNavigate } from 'react-router-dom';
import { Buttons, Container, ContainerButtons, ContainerCuenta, ContainerTitulo, ContainerTituloTenencia, IconWithMediaQuery, NombreCliente, Tenencia, Titulo, TitulosDinero } from './elements';
import useWindowDimensions from '../../../hooks/useWindowDimensions';


interface Props {
    errorDatosPersonales?: boolean;
}

export const UserCardHome = ({ errorDatosPersonales }: Props) => {

    const navigate = useNavigate();

    const { width } = useWindowDimensions();

    const { userData } = useContext(UserContext);

    // const { fecha, setFecha, tenenciaPorFecha, loadingDescarga } = useTenenciaValorizadaHook();

    // const { open, handleOpen, handleClose, handleAceptarModal } = useModalHook(tenenciaPorFecha, true);


    const date = () => {
        let date = new Date();
        let correctMonth;
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = String(date.getFullYear()).slice(2, 4);

        if (month < 10) {
            correctMonth = `0${month}`;
        } else {
            correctMonth = `${month}`;
        }
        if (day < 10) {
            return `0${day}/${correctMonth}/${year}`;
        } else {
            return `${day}/${correctMonth}/${year}`;
        }
    };

    return (
        <Container>
            <ContainerCuenta>
                {/* Numero de cuenta y nombre del cliente */}
                {
                    errorDatosPersonales
                        ? <ContainerTitulo>
                            <Titulo>Error al cargar los datos del cliente</Titulo>
                            {/* <NombreCliente>Comunicate con tu asesor/a</NombreCliente> */}
                        </ContainerTitulo>
                        : <ContainerTitulo>
                            <ContainerTituloTenencia>
                                <Titulo>Cuenta Nº {userData?.nroCuenta}</Titulo>
                                <Tenencia>Tenencia hasta {date()}</Tenencia>
                            </ContainerTituloTenencia>
                            <NombreCliente>{userData?.nombre}<br />{userData?.empresaName}</NombreCliente>
                        </ContainerTitulo>
                }
                {/* <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}> */}
                    {/* {width > 992
                        ? <DownloadForOfflineOutlinedIcon
                            sx={{
                                color: 'white',
                                fontSize: '1.3em',
                                cursor: 'pointer',
                            }}
                            onClick={handleOpen}
                        />
                        : null
                    }
                    <ModalConfirmacion
                        open={open}
                        handleClose={handleClose}
                        handleConfirmarModal={handleAceptarModal}
                        textoModal={'Seleccioná la fecha en la que necesitás visualizar la información sobre tu tenencia.'}
                        tituloModal={'Tenencia valorizada'}
                        textoConfirmarModal={'Descargar'}
                        tenencia={true}
                        fechaTenencia={fecha}
                        setFechaTenencia={setFecha}
                    />
                    <AlertCopyToClipboard
                        open={loadingDescarga}
                        texto='Descargando documento...'
                    /> */}
                {/* </div> */}
            </ContainerCuenta>

            {/* Botones Ingresar y Retirar Dinero */}
            <ContainerButtons>
                <Buttons
                    onClick={() => navigate('/ingresar-dinero')}
                >
                    {
                        width > 992 ?
                            null
                            :
                            <IconWithMediaQuery icon="mdi:cash" />
                    }
                    <TitulosDinero>Ingresar dinero</TitulosDinero>
                </Buttons>
                <Buttons
                    onClick={() => navigate('/retirar-dinero')}
                >
                    {
                        width > 992 ?
                            null
                            :
                            <IconWithMediaQuery icon="mdi:cash" />
                    }
                    <TitulosDinero>Retirar dinero</TitulosDinero>
                </Buttons>

            </ContainerButtons>
        </Container>
    )
}



