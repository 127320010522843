import styled from "styled-components";
import { colors } from "../../../theme/Themes";
import { Icon } from "@iconify/react";


export const Container = styled.div`
    margin-left: 1em;
    margin-top: 0.7em;
    margin-right: 1em;
    border-radius: 10px;
    background-color: ${colors.blueRava};
    @media (min-width: 992px) {
        display: flex;
        height: 4.5em;
        justify-content: space-between;
        padding: 0.5em 0 0.5em 0;
	}
`

export const ContainerCuenta = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (min-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 0.8em 0 0.8em 1em;
	}
`;

export const ContainerTitulo = styled.div`
    margin-left: 1.25em; 
    margin-bottom: 0.6em; 
    margin-top: 0.6em;
    width: 100%;
    @media (min-width: 992px) {
        display: flex;
        margin: 0;
	}
`
export const ContainerTituloTenencia = styled.div`
    display: flex;
    justify-content: space-between;
    @media (min-width: 992px) {
        display: block;  
	}
`

export const Titulo = styled.p`
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: 0px;
`

export const NombreCliente = styled.p`
    color: white;
    font-size: 12px;
    margin: 0px;
    @media (min-width: 992px) {
        margin: 0.5em 0 0 1.5em;
	}
`

export const Tenencia = styled.p`
    font-size: 12px;
    color: white;
    margin: 0px;
    margin-right: 1.25em;
    @media (min-width: 992px) {
        margin: 0;
	}
`

export const ContainerButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    border-top-left-radius: 1.9em;
    border-top-right-radius: 1.9em;
    border-bottom-right-radius: 9px;
    border-bottom-left-radius: 9px;
    background-color: ${({ theme }) => theme.alternativeBackgroundColor};
    padding: 1em;
    @media (min-width: 992px) {
        background-color: ${colors.blueRava};
        height: 45%;
        margin: 0 2em;
	}
`

export const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 0.6em;
    padding: 0.2em;
    border: 0.1em solid ${({ theme }) => theme.secundaryBorderColor};
    background-color: ${({ theme }) => theme.primaryBackgroundColor};
    @media (min-width: 992px) {
        justify-content: center;
        border: 0.1em solid white;
        border-radius: 1em;
        width: 7em;
        margin: 0 0.5em;
        cursor: pointer;
        background-color: ${({ theme }) => theme.backgroundColorButtonsHome};
	}
`

export const TitulosDinero = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    text-align: center;
    font-size: 12px;
    width: 6.5em;
    margin: 0.3em;
    @media (min-width: 992px) {
        color: white;
        width: 100%;
        margin: 1em;
	}
`

export const IconWithMediaQuery = styled(Icon)`
    width: 1.8em;
    height: 1.8em;
    padding: 0.2em;
    color: ${({ theme }) => theme.primaryColor};
`
