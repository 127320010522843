import React, { useCallback, useContext } from 'react'
import { DataEspecie } from '../../../interfaces/mercadoInferfaces';
import { Container, ContainerSinInfo, MostrarFavs, MostrarFavsAca, NoFavsInfo } from './elements';
import { LoadingContainer } from '../../../elements/loadingContainer';
import { CircularProgress } from '@mui/material';
import { ThemeContext } from 'styled-components';
import CardEspecieLocalNuevo from '../CardEspecieLocalNuevo/CardEspecieLocalNuevo';
import { FixedSizeList as List } from 'react-window';

interface Props {
    dataEspecies: DataEspecie[] | null | undefined;
    loading: boolean;
    actualMenu: string;
    cargarDatosOperar: (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => void;
    noHayData: boolean;
    colorMap: {};
    cargarPreciosSinSocket: (actualMenu: string) => Promise<void>;
}

const CardInfoPanel = ({
    dataEspecies,
    loading,
    actualMenu,
    cargarDatosOperar,
    noHayData,
    colorMap,
    cargarPreciosSinSocket,
}: Props) => {

    const { loadingColor } = useContext(ThemeContext);

    const renderRow = useCallback(({ index, style, data }: { index: number, style: React.CSSProperties, data: any }) => {
        const { items, colorMap } = data
        if (items) {
            const item = items[index];
            const color = colorMap[item.especie];
            return (
                <CardEspecieLocalNuevo
                    style={style}
                    especie={item}
                    index={index}
                    cargarDatosOperar={cargarDatosOperar}
                    colorUltimo={color?.ultimo ? color.ultimo : undefined}
                    colorCantCompra={color?.cantCompra ? color.cantCompra : undefined}
                    colorPrecioCompra={color?.precioCompra ? color.precioCompra : undefined}
                    colorPrecioVenta={color?.precioVenta ? color.precioVenta : undefined}
                    colorCantVenta={color?.cantVenta ? color.cantVenta : undefined}
                    arrayLength={items.length}
                />
            );
        }
        return <></>
    }, [dataEspecies])

    return (
        <Container>
            {
                loading
                    ? <LoadingContainer style={{ height: '100%' }}>
                        <CircularProgress style={{ color: loadingColor }} />
                    </LoadingContainer>
                    : noHayData && actualMenu !== "FAVORITOS"
                        ? <ContainerSinInfo>
                            <MostrarFavs>Por el momento no podemos actualizar los precios en tiempo real. </MostrarFavs>
                            <div style={{ display: 'flex' }}>
                                <MostrarFavs>Por favor</MostrarFavs>
                                <MostrarFavsAca onClick={(e) => {
                                    cargarPreciosSinSocket(actualMenu)
                                }}>hacé click</MostrarFavsAca>
                                <MostrarFavs>para visualizarlos.</MostrarFavs>
                            </div>
                        </ContainerSinInfo>
                        : actualMenu === "FAVORITOS" && noHayData
                            ? <ContainerSinInfo>
                                
                                <MostrarFavs>Por el momento no podemos actualizar tus "favoritos" en tiempo real. </MostrarFavs>
                                <div style={{ display: 'flex' }}>
                                    <MostrarFavs>Por favor</MostrarFavs>
                                    <MostrarFavsAca onClick={(e) => {
                                        cargarPreciosSinSocket(actualMenu)
                                    }}>hacé click</MostrarFavsAca>
                                    <MostrarFavs>para visualizarlos.</MostrarFavs>
                                </div>
                            </ContainerSinInfo>
                                : actualMenu === "FAVORITOS" && !dataEspecies?.length
                                    ? <ContainerSinInfo>
                                        <NoFavsInfo>No tenes ningún favorito asignado.</NoFavsInfo>
                                    </ContainerSinInfo>
                                    : dataEspecies && dataEspecies?.length > 0 && (
                                        <List
                                            height={1000} // Altura del contenedor
                                            itemCount={dataEspecies.length} // Número de elementos
                                            itemSize={20} // Altura de cada elemento
                                            width={'100%'} // Ancho del contenedor
                                            overscanCount={20}
                                            style={{
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                            }}
                                            itemData={{ items: dataEspecies, colorMap }}
                                        >
                                            {renderRow}
                                        </List>
                                    )
            }
        </Container >
    )
}

export default CardInfoPanel