import React, { useContext } from 'react'
import { ButtonsPlazo, Container, ContainerButtonsPlazo, ContainerDiaria, ContainerSaldoDisponible, ContainerTenencia, ContainerTextoInput, Input, TenenciaTotalText, Texto, TextoDiaria } from './elements'
import SearchOperar from '../../operarComponents/SearchOperar/SearchOperar'
import BotonesComprarVenderNuevo from '../BotonesComprarVenderNuevo/BotonesComprarVenderNuevo'
import { LimiteOperacion, TipoMercado, TipoOperacion } from '../../../enums/enums'
import { ThemeContext } from 'styled-components'
import { separacionDecimal } from '../../../helpers/separacionDecimal'
import { UserContext } from '../../../context/userContext/UserContext'
import { formatCurrency } from '../../../helpers/formatCurrency'

interface Props {
    tipoMercado: TipoMercado;
    actualMenuLimit: LimiteOperacion;
    setActualMenuLimit: React.Dispatch<React.SetStateAction<LimiteOperacion>>;
    onChangeCantidad: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    onChangePrecio: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    onChangeImporte: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
    precioFormateado: string;
    importeFormateado: string;
    cantidadFormateada: string;
    setActualOption: React.Dispatch<React.SetStateAction<TipoOperacion | null>>
    continuar: (tipoOperacion: TipoOperacion) => Promise<void>
    tenenciaTotal: number;
    esDetalleEspecie?: boolean;
}

const IngresoDatosOperar = ({
    tipoMercado,
    actualMenuLimit,
    setActualMenuLimit,
    onChangeCantidad,
    onChangePrecio,
    onChangeImporte,
    precioFormateado,
    importeFormateado,
    cantidadFormateada,
    setActualOption,
    continuar,
    tenenciaTotal,
    esDetalleEspecie,
}: Props) => {

    const { primaryBackgroundColor, primaryColor } = useContext(ThemeContext)
    const { tenencia } = useContext(UserContext)

    return (
        <Container>
            <ContainerTextoInput style={{ paddingTop: '0.5em' }}>
                <Texto>Especie</Texto>
                <SearchOperar />
            </ContainerTextoInput>

            {tipoMercado === TipoMercado.Local
                ? <ContainerTextoInput>
                    <Texto>Tipo de orden</Texto>
                    <ContainerDiaria>
                        <TextoDiaria>{actualMenuLimit}</TextoDiaria>
                    </ContainerDiaria>
                </ContainerTextoInput>
                : <ContainerTextoInput>
                    <Texto>Tipo de orden</Texto>
                    <ContainerButtonsPlazo>
                        <ButtonsPlazo
                            style={{
                                backgroundColor: actualMenuLimit === LimiteOperacion.Limit ? primaryColor : primaryBackgroundColor,
                                color: actualMenuLimit === LimiteOperacion.Limit ? primaryBackgroundColor : primaryColor,
                            }}
                            onClick={() => setActualMenuLimit(LimiteOperacion.Limit)}
                        >Limit</ButtonsPlazo>
                        <ButtonsPlazo
                            style={{
                                backgroundColor: actualMenuLimit === LimiteOperacion.MARKET ? primaryColor : primaryBackgroundColor,
                                color: actualMenuLimit === LimiteOperacion.MARKET ? primaryBackgroundColor : primaryColor,
                            }}
                            onClick={() => setActualMenuLimit(LimiteOperacion.MARKET)}
                        >Market</ButtonsPlazo>
                    </ContainerButtonsPlazo>
                </ContainerTextoInput>
            }

            <ContainerTextoInput>
                <Texto>Cantidad</Texto>
                <ContainerDiaria>
                    <Input
                        placeholder='Cantidad'
                        onChange={onChangeCantidad}
                        value={cantidadFormateada}
                        type='text'
                    ></Input>
                </ContainerDiaria>
            </ContainerTextoInput>
            <ContainerTextoInput>
                <Texto>Precio</Texto>
                <ContainerDiaria>
                    {actualMenuLimit === LimiteOperacion.Limit
                        ?
                        <Input
                            placeholder='Precio'
                            onChange={onChangePrecio}
                            value={precioFormateado}
                            type='text'
                        ></Input>
                        : null
                    }
                </ContainerDiaria>
            </ContainerTextoInput>
            <ContainerTextoInput>
                <Texto>Importe</Texto>
                <ContainerDiaria>
                    {actualMenuLimit === LimiteOperacion.Limit
                        ?
                        <Input
                            placeholder='Importe'
                            type='text'
                            onChange={onChangeImporte}
                            value={importeFormateado}
                        ></Input>
                        : null
                    }
                </ContainerDiaria>
            </ContainerTextoInput>
            
            <ContainerSaldoDisponible>
                    <Texto style={{fontSize: "small"}}>
                        Saldo disponible: 
                        { tipoMercado === TipoMercado.Local 
                        ? ` $${tenencia ? formatCurrency(tenencia?.saldo.pesos) : 0}`
                        : ` USD ${tenencia ? formatCurrency(tenencia?.saldo.dolarExterior) : 0}`
                        }</Texto>
            </ContainerSaldoDisponible>

            {esDetalleEspecie &&
                <ContainerTenencia>
                    <TenenciaTotalText>
                        Tenencia actual: {separacionDecimal(tenenciaTotal)}
                    </TenenciaTotalText>
                </ContainerTenencia>
            }


            <BotonesComprarVenderNuevo
                setActualOption={setActualOption}
                continuar={continuar}
            />
        </Container>
    )
}

export default IngresoDatosOperar