import SearchBar from '../../components/mercadoComponents/SearchBar/SearchBar'
import { TipoMercado } from '../../enums/enums'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import { useContext, useEffect, useState } from 'react'
import CardEspecieLocal from '../../components/mercadoComponents/CardEspecieLocal/CardEspecieLocal'
import CardEspecieExterior from '../../components/mercadoComponents/CardEspecieExterior/CardEspecieExterior'
import { useMercadoHook } from '../../hooks/useMercadoHook'
import { getDataEspecie } from '../../api/mercadoApi'
import { DataEspecie } from '../../interfaces/mercadoInferfaces'
import { AuthContext } from '../../context/authContext/AuthContext'
import { Container, ContainerEspecie, ContenedorPrincipal, TextoError } from './elements'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { ContainerCardMercadoLocal, ContainerSearchListaEspecies, OperarContainer, RowContainer } from '../Mercado/elements'
import CardMercado from '../../components/mercadoComponents/CardMercado/CardMercado'
import Operar from '../Operar/Operar'
import ComprarScreen from '../ComprarScreen/ComprarScreen'
import { OperarContext } from '../../context/operarContext/OperarContext'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'

export const SearchEspecie = () => {

    const { actualMenuEspecies, dataDolar, dataMercadoCard, dataMercadoLocalEspecies, dataAvtivasCincoExt, dataTopCincoExt } = useMercadoHook();
    const { tokenPublico } = useContext(AuthContext);
    const { tipoMercado, especieSearch } = useContext(MercadoContext);
    const [dataEspecie, setDataEspecie] = useState<DataEspecie | null>(null);
    const [error, setError] = useState('');

    const { width } = useWindowDimensions();
    const { deskOperar } = useContext(OperarContext);

    useEffect(() => {
        getDataApi();
    }, [especieSearch]);

    async function getDataApi() {

        if (tokenPublico && especieSearch) {
            try {
                const resp = await getDataEspecie(tokenPublico, [
                    especieSearch.especie_completa ? especieSearch.especie_completa : especieSearch.especie,
                ]);
                if (resp.data.body && resp.data.body[0]) {
                    setDataEspecie(resp.data.body[0]);
                } else {
                    setDataEspecie(null)
                    setError('Actualmente no disponemos de datos sobre esta especie');
                }
            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <Container>

            <ResponsiveHeader title='Mercado' showSwitch={false} />

            {
                width > 992 ?
                    <ContenedorPrincipal>
                        {
                            tipoMercado === TipoMercado.Local ?
                                (
                                    <ContainerCardMercadoLocal>
                                        <CardMercado
                                            title="Mercado local"
                                            data={dataMercadoCard}
                                            img={require('../../images/bandera-mercado-local.png')}
                                        />
                                        <CardMercado
                                            title="Dólar"
                                            data={dataDolar}
                                            img={require('../../images/icono-dolar.png')}
                                        />
                                        <CardMercado
                                            title="Lo que mas subió"
                                            data={dataMercadoLocalEspecies}
                                            img={require('../../images/bandera-mercado-local.png')}
                                        />
                                    </ContainerCardMercadoLocal>
                                ) :
                                (
                                    <ContainerCardMercadoLocal>
                                        <CardMercado
                                            title='Principales índices de EE. UU.'
                                            data={dataMercadoCard}
                                            img={require('../../images/bandera-usa-small.png')}
                                        />
                                        <CardMercado
                                            title='Lo que mas subió'
                                            data={dataTopCincoExt ? dataTopCincoExt.slice(0, 3) : null}
                                            img={require('../../images/bandera-usa-small.png')}
                                        />
                                        <CardMercado
                                            title='Lo mas destacado'
                                            data={dataAvtivasCincoExt ? dataAvtivasCincoExt.slice(0, 3) : null}
                                            img={require('../../images/bandera-usa-small.png')}
                                        />
                                    </ContainerCardMercadoLocal>
                                )
                        }
                        <RowContainer>
                            <ContainerSearchListaEspecies>
                                <SearchBar especie={especieSearch?.especie} />
                                <ContainerEspecie>
                                    {dataEspecie !== null ?
                                        tipoMercado === TipoMercado.Local ? (
                                            <CardEspecieLocal
                                                especie={dataEspecie}
                                                actualMenuEspecies={actualMenuEspecies}
                                            />
                                        ) : (
                                            <CardEspecieExterior
                                                especie={dataEspecie}
                                            />
                                        ) :
                                        <TextoError>{error}</TextoError>
                                    }
                                </ContainerEspecie>
                            </ContainerSearchListaEspecies>
                            <OperarContainer>
                                {!deskOperar ?
                                    <Operar />
                                    :
                                    <ComprarScreen />
                                }
                            </OperarContainer>
                        </RowContainer>
                    </ContenedorPrincipal>
                    :
                    <div>
                        <SearchBar especie={especieSearch?.especie} />
                        <ContainerEspecie>
                            {dataEspecie !== null ?
                                tipoMercado === TipoMercado.Local ? (
                                    <CardEspecieLocal
                                        especie={dataEspecie}
                                        actualMenuEspecies={actualMenuEspecies}
                                    />
                                ) : (
                                    <CardEspecieExterior
                                        especie={dataEspecie}
                                    />
                                ) :
                                <TextoError>{error}</TextoError>
                            }
                        </ContainerEspecie>
                    </div>
            }
        </Container>
    )
}

