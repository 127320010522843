import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  width: (100% - 2em);
  display: grid;
  // grid-template-columns: 1.5fr repeat(2, 0.8fr) repeat(2, 1fr) 0.8fr 1fr 1.5fr;
  grid-template-columns: 1fr 0.8fr 1fr 0.8fr 1fr 1.5fr;
  text-align: end;
  margin: 0;
  border-radius: 10px;
  `;

export const ContainerExterior = styled.div`
  background-color: white;
  width: (100% - 2em);
  display: grid;
  // grid-template-columns: 1.3fr repeat(2, 0.8fr) 1fr 0.8fr 1fr 1.5fr;
  grid-template-columns: 1fr repeat(2, 0.8fr) 1fr 1.5fr;
  text-align: end;
  margin: 0;
  border-radius: 10px;
`;

export const TextoEspecie = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  text-align: center;
  margin: 0.2em 0em;
  align-self: center;
`;

export const Texto = styled.p`
  color: ${({ theme }) => theme.primaryColor};
  margin: 0em;
  align-self: center;
  font-size: 14px;
`;

export const ContainerSecundario = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerDolarButton = styled.div`
  display: flex;
`