import { Container, ContainerSecundario, ContainerTitulos, TextoTitulos } from './elements';
import DetalleRentas from '../DetalleRentas/DetalleRentas';
import { TenenciaMercadoLocal } from '../../../interfaces/tenenciaInterface';
import { CircularProgress } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useModalHook } from '../../../hooks/useModalHook';
import useRetirarDolaresHook from '../../../hooks/useRetirarDolaresHook';

interface Props {
    tenenciaVariable: TenenciaMercadoLocal[];
    tenenciaFija: TenenciaMercadoLocal[];
    tenenciaDolares: TenenciaMercadoLocal[];
    subtotalVariable: number;
    subtotalFija: number;
    subtotalDolares: number;
    subtotalPorcentajeVariable: number;
    subtotalPorcentajeFija: number;
    subtotalPorcentajeDolares: number;
    loading: boolean;
    dolarRenta: {
        dolar7000: boolean;
        dolar10000: boolean;
    }
}

const InfoDetallesHome = ({
    tenenciaVariable,
    tenenciaFija,
    tenenciaDolares,
    subtotalVariable,
    subtotalFija,
    subtotalDolares,
    subtotalPorcentajeVariable,
    subtotalPorcentajeFija,
    subtotalPorcentajeDolares,
    loading,
    dolarRenta,
}: Props) => {

    const { loadingColor } = useContext(ThemeContext)

    const { transferirDolares, setTipoDolarImporte, setTipoConversion7000, tipoConversion7000 } = useRetirarDolaresHook();

    const {
        open,
        handleOpen,
        handleClose,
        handleAceptarModal,
    } = useModalHook(transferirDolares)

    return (
        <Container>

            <ContainerSecundario>
                <ContainerTitulos>
                    <TextoTitulos style={{ textAlign: 'start', marginLeft: '1em' }}>Especie</TextoTitulos>
                    <TextoTitulos style={{ textAlign: 'end' }}>% variación actual</TextoTitulos>
                    {/* <TextoTitulos style={{ textAlign: 'end' }}>% variación histórica</TextoTitulos> */}
                    <TextoTitulos style={{ textAlign: 'end' }}>% de tenencia</TextoTitulos>
                    {/* <TextoTitulos style={{ textAlign: 'end' }}>Precio histórico</TextoTitulos> */}
                    <TextoTitulos style={{ textAlign: 'end' }}>Cantidad</TextoTitulos>
                    <TextoTitulos style={{ textAlign: 'end', }}>Precio</TextoTitulos>
                    <TextoTitulos style={{ textAlign: 'end', marginRight: '1em' }}>Importe</TextoTitulos>
                </ContainerTitulos>
            </ContainerSecundario>

            {loading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                    <CircularProgress style={{ color: loadingColor }} />
                </div>
                :
                <>
                    {tenenciaVariable.length ?
                        <DetalleRentas
                            title={'variable'}
                            tenencia={tenenciaVariable}
                            subtotal={subtotalVariable}
                            porcentaje={subtotalPorcentajeVariable}
                        />
                        : null
                    }

                    {tenenciaFija.length ?
                        <DetalleRentas
                            title={'fija'}
                            tenencia={tenenciaFija}
                            subtotal={subtotalFija}
                            porcentaje={subtotalPorcentajeFija}
                        />
                        : null
                    }

                    {tenenciaDolares.length ?
                        <DetalleRentas
                            tenencia={tenenciaDolares}
                            subtotal={subtotalDolares}
                            porcentaje={subtotalPorcentajeDolares}
                            openModalDola={open}
                            handleOpenModalDola={handleOpen}
                            handleCloseModalDola={handleClose}
                            handleAceptarModalDola={handleAceptarModal}
                            setTipoDolarImporte={setTipoDolarImporte}
                            dolarRenta={dolarRenta}
                            setTipoConversion7000={setTipoConversion7000}
                            tipoConversion7000={tipoConversion7000}
                        />
                        : null
                    }
                </>

            }
        </Container>
    )
}

export default InfoDetallesHome