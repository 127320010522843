import styled from "styled-components";

export const Container = styled.div`
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};;
    height: 92vh;
    display: flex;
    flex-direction: column;
    @media (min-width: 992px) {
        margin-top: 8vh;
        margin-left: 12vw;
        // height: 100%;
        height: unset;
        flex-grow: 1;
        padding: 0 0.5vw 0em 0.5vw;
	}
`;

export const RowContainer = styled.div`
    @media (min-width: 992px) {
        display: flex;
        height: 100%;
        width: 100%;
	}
`

export const PhoneContainer = styled.div`
    overflow-y: scroll;
    flex-grow: 1;
    margin-bottom: 8vh;
    padding-top: 6em;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`

export const HeaderContainer = styled.div`
    z-index: 1;
    position: fixed;
`

export const OperarContainer = styled.div`
    @media (min-width: 992px) {
        padding: 0.5em 0em 0em 0em;
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-right: 1em;
	}
`

export const ContenedorPrincipal = styled.div`
    display: flex;
    flex-direction: column;
    height: 99%;
`