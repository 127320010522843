import styled from "styled-components";

export const ContainerTitulos = styled.div`
    display: grid;
    grid-template-columns: 1fr repeat(4, 1.5fr);
    padding: 0em 0.5em 0 0.5em;
    gap: 0.3em;
`

export const TextoDatos = styled.p`
    color: ${({ theme }) => theme.primaryColor};
    font-size: 12px;
    text-align: end;
    margin: 0;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
`;