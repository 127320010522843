// export interface RespNombreEspecies {
//   body: DatosClaveEspecie[];
//   count: number;
//   exectime: number;
// }

// export interface DatosClaveEspecie {
//   especie: string;
//   tipo: Tipo;
//   especie_completa?: string;
//   ddjj_compra?: DdjjCompra;
//   ddjj_venta?: DdjjVenta;
// }

// export enum DdjjCompra {
//   CpraCedear = 'CPRA_CEDEAR',
//   CpraLiqUsd = 'CPRA_LIQ_USD',
// }

// export enum DdjjVenta {
//   VtaLiqUsd = 'VTA_LIQ_USD',
// }

// export enum Tipo {
//   Accion = 'accion',
//   Bono = 'bono',
//   Cedear = 'cedear',
//   Criptomoneda = 'criptomoneda',
//   Empresa = 'Empresa',
//   Empty = '',
//   Exterior = 'exterior',
//   Indice = 'indice',
//   Letras = 'Letras',
//   Opcion = 'opcion',
// }

// export interface RespNombreEspecies {
//   error: boolean;
//   status: number;
//   body: RespNombreEspeciesBody;
// }

// export interface RespNombreEspeciesBody {
//   data: DataNombreEspecies;
// }

// export interface DataNombreEspecies {
//   body: DatosClaveEspecie[];
//   count: number;
//   exectime: number;
// }

// export interface DatosClaveEspecie {
//   especie: string;
//   tipo: Tipo;
//   especie_completa?: string;
//   ddjj_compra?: DdjjCompra;
//   ddjj_venta?: DdjjVenta;
//   mercado?: string;
//   panel?: Panel;
// }

// export enum Panel {
//   Adr = "ADR",
//   Cedears = "Cedears",
//   Etf = "ETF",
//   Global = "Global",
//   Las30DelDow = "Las 30 del Dow",
//   Bonos = 'Bonos',
//   General = 'General',
//   Lider = 'Lider',
// }

// export enum Tipo {
//   Accion = 'accion',
//   Bono = 'bono',
//   Cedear = 'cedear',
//   Criptomoneda = 'criptomoneda',
//   Empresa = 'Empresa',
//   Empty = '',
//   Exterior = 'exterior',
//   Indice = 'indice',
//   Letras = 'Letras',
//   Opcion = 'opcion',
// }

export enum DdjjCompra {
  CpraCedear = 'CPRA_CEDEAR',
  CpraLiqUsd = 'CPRA_LIQ_USD',
}

export enum DdjjVenta {
  VtaLiqUsd = 'VTA_LIQ_USD',
}

export interface RespNombreEspecies {
  error:  boolean;
  status: number;
  body:   RespNombreEspeciesBody;
}

export interface RespNombreEspeciesBody {
  data:              Data;
  instrumentosGallo: InstrumentosGallo[];
}

export interface Data {
  body:     DatosClaveEspecie[];
  count:    number;
  exectime: number;
}

export interface DatosClaveEspecie {
  especie:           string;
  panel?:            Panel;
  tipo:              Tipo;
  especie_completa?: string;
  ddjj_compra?:      DdjjCompra;
  ddjj_venta?:       DdjjVenta;
  mercado?:          string;
}


export enum Panel {
  Bonos = "Bonos",
  Cedears = "Cedears",
  General = "General",
  Lider = "Lider",
}

export enum Tipo {
  Accion = "accion",
  Bono = "bono",
  Cedear = "cedear",
  Empresa = "Empresa",
  Empty = "",
  Indice = "indice",
  Letras = "Letras",
  Opcion = "opcion",
}

export interface InstrumentosGallo {
  especie:           string;
  TipoEspecie:       TipoEspecie;
  tipo:              Tipo;
  panel?:            Panel;
  especie_completa?: string;
  ddjj_compra?:      string;
  ddjj_venta?:       string;
  Mercado?:          string;
}

export enum TipoEspecie {
  CPD = "C.P.D.",
  Cedear = "CEDEAR",
  Cedin = "Cedin",
  FCI = "F.C.I.",
  Fideicomiso = "Fideicomiso",
  Indices = "Indices",
  Letras = "Letras",
  ON = "O.N.",
  Opciones = "Opciones",
  Privado = "Privado",
  Publico = "Publico",
}