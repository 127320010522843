import {useContext} from 'react'
import { Icon } from '@iconify/react';
import { TipoAlerta, TipoMercado } from '../../../enums/enums';
import {Alert as AlertInterface} from '../../../interfaces/alertaInterface';
import { Button, Container, EspecieText, FirstColumn, PrecioActualText, PrecioText, Row, SecondColumn } from './elements';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../../helpers/formatCurrency';
import { MercadoContext } from '../../../context/mercadoContext/MercadoContext';
import { useModalHook } from '../../../hooks/useModalHook';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { ThemeContext } from 'styled-components';
import { formatEspeciesExterior } from '../../../helpers/formatEspeciesExterior';

interface Props {
    alerta: AlertInterface;
    deleteAlert: (alert: AlertInterface) => Promise<void>;
    eliminarPrecio(indexDelete: number, tipoMercado: TipoMercado): void;
    idx: number;
    especie: string;
    precio: number;
    tipoAlerta: TipoAlerta;
    precioActual: number;
    panel: string;
    opciones: string;
    id: string;
}

export const AlertaCard = ({
    alerta,
    deleteAlert,
    eliminarPrecio,
    idx,
    especie,
    precio,
    tipoAlerta,
    precioActual,
    panel,
    opciones,
    id,
}: Props) => {

    const navigate = useNavigate();

    const handleDelete = () => {
        eliminarPrecio(idx, tipoMercado);
        deleteAlert(alerta);
    }

    const { open, handleOpen, handleClose, handleAceptarModal  } = useModalHook(handleDelete)

    const {tipoMercado} = useContext(MercadoContext);
    function getSymbol(): string {
        return tipoMercado === TipoMercado.Exterior ? 'USD ' : '$';
    }

    const { width } = useWindowDimensions();

    const {primaryBackgroundColor, alterHomeBackgrooundColor } = useContext(ThemeContext);


    const changeColor = (num : number) => {
        if((num % 2) === 0){
            return false
        } else {
            return true
        }
    }

    return (
        <Container style={{backgroundColor: width > 992 && changeColor(idx) ? alterHomeBackgrooundColor : primaryBackgroundColor,}}>
            <FirstColumn>
                <Row>
                    <EspecieText>{tipoMercado === TipoMercado.Exterior ? formatEspeciesExterior(especie) : especie}</EspecieText>
                    
                    <Icon icon={tipoAlerta === TipoAlerta.Resistencia
                        ? 'mdi:arrow-up-circle-outline'
                        : 'mdi:arrow-down-circle-outline'} 
                        style={{width: '1.5em', height: '1.5em',}}
                    />

                </Row>
                <Row>
                    <PrecioActualText>{`Precio actual ${getSymbol()} ${precioActual ? formatCurrency(precioActual) : ''}`}</PrecioActualText>
                </Row>
            </FirstColumn>
            <SecondColumn>
                <Row>
                    <PrecioText>{`${getSymbol()} ${formatCurrency(precio)}`}</PrecioText>
                    <Button
                        onClick={() => navigate("/agregar-alerta", {
                            state: {
                                editarAlerta: true,
                                alerta: alerta,
                            }
                        })}
                    >
                        <Icon icon="material-symbols:edit-outline-rounded" style={{width: '2.1em', height: '2.1em', cursor: 'pointer'}}/>
                    </Button>
                    <Button
                        onClick={handleOpen}
                    >
                        <Icon icon="mdi:trash-outline" style={{width: '2.1em', height: '2.1em', cursor: "pointer"}}/>
                    </Button>
                    <ModalConfirmacion
                        open={open}
                        handleClose={handleClose}
                        handleConfirmarModal={handleAceptarModal}
                        textoModal={`¿Quieres eliminar la alerta de ${especie}?`}
                        textoConfirmarModal={'Eliminar'}
                        tituloModal={'Eliminar'}
                    />
                </Row>
            </SecondColumn>
        </Container>
    )
}
