import { createContext, useEffect, useState } from 'react';
import { getPublicToken } from '../../api/publicTokenProvider';
import { TipoAccount } from '../../enums/enums';
import { CuentasClientesBody } from '../../interfaces/cuentasClientesInterface';

interface AuthContextProps {
    status:
    | 'authenticated'
    | 'not-authenticated'
    | 'changing-pin'
    | 'setting-pin'
    | 'loading';
    setStatus: React.Dispatch<React.SetStateAction<"authenticated" | "not-authenticated" | "changing-pin" | "setting-pin" | "loading">>,
    token: string,
    setLoading: (token: string) => void,
    tokenPublico: string | undefined,
    setTokenPublico: (tokenPublico: string | undefined) => void;
    setToken: (token: string) => void;
    logOut: () => void;
    cleanStorage: () => void;
    singIn: (token: string, userId: string, accountType?: string) => void;
    userId: string;
    setPinGeneradoRava: React.Dispatch<React.SetStateAction<string>>;
    pinGeneradoRava: string;
    accountType: TipoAccount | null;
    setAccountSearched: React.Dispatch<React.SetStateAction<string>>;
    accountSearched: string;
    cuentasClientes: CuentasClientesBody[] | null
    setCuentasClientes: React.Dispatch<React.SetStateAction<CuentasClientesBody[] | null>>
}

export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {

    const tokenStorage = localStorage.getItem("token");

    const [status, setStatus] =
        useState<| 'authenticated'
            | 'not-authenticated'
            | 'changing-pin'
            | 'setting-pin'
            | 'loading'>(tokenStorage ? "authenticated" : "not-authenticated")

    const [token, setToken] = useState('');
    const [tokenPublico, setTokenPublico] = useState<string | undefined>('')
    const [userId, setUserId] = useState('')
    const [pinGeneradoRava, setPinGeneradoRava] = useState('');

    const [accountType, setAccountType] = useState<TipoAccount | null>(null);

    const [accountSearched, setAccountSearched] = useState<string>(localStorage.getItem("accountSearched") || '4' )

    const [cuentasClientes, setCuentasClientes] = useState<CuentasClientesBody[] | null>(null)

    async function getToken() {
        try {
            const resp = await getPublicToken();
            if (resp) {
                setTokenPublico(resp?.data.access_token);
                localStorage.setItem("tokenPublico", resp?.data.access_token)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        status === 'authenticated' && getToken();
    }, [status]);

    const singIn = (token: string, userId: string, accountType?: string) => {
        localStorage.setItem('token', token);
        localStorage.setItem('userId', userId);
        localStorage.setItem('accountType', accountType === TipoAccount.Admin ? TipoAccount.Admin : TipoAccount.Normal);
        if (accountType === TipoAccount.Admin) {
            setAccountType(TipoAccount.Admin)
        }
        setUserId(userId);
        setToken(token)
        setStatus("authenticated");
    };

    const setLoading = (token: string) => {
        setStatus('loading')
        setToken(token);
    }

    const cleanStorage = () => {
        localStorage.setItem('token', "")
        localStorage.setItem('tokenPublico', "")
        localStorage.setItem('accountType', "")
    }

    const logOut = () => {
        cleanStorage();
        setToken('');
        setTokenPublico('');
        setStatus('not-authenticated');
    };

    return (
        <AuthContext.Provider
            value={{
                status,
                setStatus,
                token,
                setLoading,
                tokenPublico,
                setToken,
                logOut,
                cleanStorage,
                setTokenPublico,
                singIn,
                userId,
                setPinGeneradoRava,
                pinGeneradoRava,
                accountType,
                accountSearched,
                setAccountSearched,
                cuentasClientes,
                setCuentasClientes,
            }}>
            {children}
        </AuthContext.Provider>
    )
}