import { Box, InputAdornment, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useContext } from "react";
import { ThemeContext } from 'styled-components'
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import { useLoginHook } from "../../hooks/useLoginHook";
import { BotonAceptar, Container, Img, LabelForm, LinksContainer, OptionContainer, SecondContainer, Error, PrimeraVezContainer, TextoPrimeraVez, TextoPin, TextoOlvidastePin } from "./elements";
import { Link } from "react-router-dom";
import { CenterContainer } from "../../elements/centerContainer";
import { Themes } from "../../theme/Themes";
import SwitchTheme from "../../components/SwitchTheme/SwitchTheme";
import { ContainerSwitcher } from "../../elements/containerSwitcher";

export const Login = () => {

    const themeContext = useContext(ThemeContext)

    const { primaryColor } = themeContext;

    const [showPasswordPin, setShowPasswordPin] = React.useState(true);
    const [showPasswordDNI, setShowPasswordDNI] = React.useState(true);
    const handleClickShowPassword = (setShowPassword: React.Dispatch<React.SetStateAction<boolean>>) => setShowPassword((show) => !show);

    const { nroCuenta, setNroCuenta, dni, setDni, pin, setPin, error, validate } = useLoginHook();

    const customInputProps = {
        '& label.Mui-focused': {
            color: primaryColor,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: primaryColor,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: primaryColor,
            },
            '&:hover fieldset': {
                borderColor: primaryColor,
            },
            '&.Mui-focused fieldset': {
                borderColor: primaryColor,
            },
        },
    }

    const handleInputs = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        inputType: string
    ) => {
        e.preventDefault();
        switch (inputType) {
            case "NROCUENTA":
                setNroCuenta(e.target.value);
                break;
            case "DNI":
                setDni(e.target.value);
                break;
            case "PIN":
                setPin(e.target.value);
                break;
            default:
                break;
        }
    };

    return (
        <Container>
            <ContainerSwitcher>
                <SwitchTheme />
            </ContainerSwitcher>
            <SecondContainer>
                {
                    themeContext === Themes.light ?
                        <Img alt="Logo Rava" src={require("../../images/logo-rava.png")} />
                        :
                        <Img alt="Logo Rava" src={require("../../images/logo-rava-dark.png")} />
                }
                <Box style={{ width: "100%" }}>
                    <form onSubmit={(e) => validate(e)}>
                        <OptionContainer>
                            <LabelForm htmlFor="account_number">Nº de cuenta</LabelForm>
                            <TextField
                                type='text'
                                name="account_number"
                                value={nroCuenta}
                                aria-label="controlled"
                                onChange={(e) => handleInputs(e, "NROCUENTA")}
                                sx={customInputProps}
                                inputProps={{
                                    sx: {
                                        padding: 0,
                                    },
                                }}
                                InputProps={{
                                    sx: {
                                        color: primaryColor,
                                        padding: '0.5em 0.8em',
                                        borderRadius: '5px',
                                        fontSize: '1em',
                                    }
                                }}
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm htmlFor="id">Documento de identidad</LabelForm>
                            <TextField
                                type={showPasswordDNI ? "text" : "password"}
                                name="id"
                                value={dni}
                                aria-label="controlled"
                                onChange={(e) => handleInputs(e, "DNI")}
                                sx={{...customInputProps,
                                    '&:-webkit-autofill': {
                                        WebkitBoxSizing: '1em'
                                    }
                                }}
                                inputProps={{
                                    sx: {
                                        padding: 0,
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword(setShowPasswordDNI)}
                                                    edge="end"
                                                    tabIndex={-1}
                                                >
                                                    {!showPasswordDNI ? (
                                                        <VisibilityOffOutlined sx={{ color: primaryColor }} />
                                                    ) : (
                                                        <VisibilityOutlined sx={{ color: primaryColor }} />
                                                    )}
                                                </IconButton>
                                            </div>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        color: primaryColor,
                                        padding: '0.5em 0.8em',
                                        borderRadius: '5px',
                                    }
                                }}
                            />
                        </OptionContainer>
                        <OptionContainer>
                            <LabelForm >PIN</LabelForm>
                            <TextField
                                type={showPasswordPin ? "text" : "password"}
                                name="id"
                                value={pin}
                                aria-label="controlled"
                                autoComplete="off"
                                onChange={(e) => handleInputs(e, "PIN")}
                                sx={customInputProps}
                                inputProps={{
                                    sx: {
                                        padding: 0,
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <div>
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => handleClickShowPassword(setShowPasswordPin)}
                                                    edge="end"
                                                    tabIndex={-1}
                                                >
                                                    {!showPasswordPin ? (
                                                        <VisibilityOffOutlined sx={{ color: primaryColor }} />
                                                    ) : (
                                                        <VisibilityOutlined sx={{ color: primaryColor }} />
                                                    )}
                                                </IconButton>
                                            </div>
                                        </InputAdornment>
                                    ),
                                    sx: {
                                        color: primaryColor,
                                        padding: '0.5em 0.8em',
                                        borderRadius: '5px',
                                    }
                                }}
                            />
                            <LinksContainer>
                                <Link to={"/validar-cuenta"} style={{ textDecoration: 'none', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}>
                                    <PrimeraVezContainer>
                                        <TextoPrimeraVez>¿Primera vez que ingresás a la plataforma?</TextoPrimeraVez>
                                        <TextoPin>Generá tu PIN</TextoPin>
                                    </PrimeraVezContainer>
                                </Link>

                                <Link to={"/restablecer-pin"} style={{ textDecoration: 'none', WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)' }}>
                                    <TextoOlvidastePin>¿Olvidaste tu PIN?</TextoOlvidastePin>
                                </Link>
                            </LinksContainer>
                            {error.length ? (
                                <Error>{error}</Error>
                            ) : null}
                        </OptionContainer>
                        <CenterContainer>
                            <BotonAceptar />
                        </CenterContainer>
                    </form>
                </Box>
            </SecondContainer>
        </Container>
    );
};


