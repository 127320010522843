import { useContext } from 'react'
import { Icon } from '@iconify/react';
import { Button, Container, IconText, Img, ButtonsContainer, ContainerImg } from './elements'
import { NavigationContext } from '../../../context/navigationContext/NavigationContext';
import { useNavigationHook } from '../../../hooks/useNavigationHook';
import { TipoNavigate } from '../../../enums/enums';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../context/authContext/AuthContext';
import { useModalHook } from '../../../hooks/useModalHook';
import ModalConfirmacion from '../../Modal/ModalConfirmacion';
import BotonesAside from '../BotonesAside/BotonesAside';
import { ThemeContext } from 'styled-components';
import { Themes } from '../../../theme/Themes';


export const Aside = () => {

    const { selectedNavigate } = useContext(NavigationContext);
    const { handleNavigate } = useNavigationHook();
    const themeContext = useContext(ThemeContext)
    const { colorNotSelectedAside } = useContext(ThemeContext)


    const navigate = useNavigate();
    const { logOut } = useContext(AuthContext);

    const cerrarSesion = () => {
        logOut();
        navigate('/login')
        window.location.reload();
    };

    const { open, handleOpen, handleClose, handleAceptarModal } = useModalHook(cerrarSesion)

    const data = [
        {
            titulo: 'Inicio',
            icono: 'tabler:home',
            navegarhacia: TipoNavigate.Home,
        },
        {
            titulo: 'Mercado',
            icono: 'mdi:finance',
            navegarhacia: TipoNavigate.Mercado,
        },
        {
            titulo: 'Órdenes',
            icono: 'mdi:monitor-dashboard',
            navegarhacia: TipoNavigate.Ordenes,
        },
        {
            titulo: 'Movimientos',
            icono: 'tabler:arrows-exchange-2',
            navegarhacia: TipoNavigate.Movimientos,
        },
    ];

    return (
        <Container>
            <ContainerImg>

                {themeContext === Themes.light
                    ? <Img
                        src={require("../../../images/logo-rava.png")}
                        alt='logo-rava'
                    />
                    : <Img
                        src={require('../../../images/logo-rava-dark.png')}
                        alt='logo-rava'
                    />
                }
            </ContainerImg>
            <ButtonsContainer>
                {data.map(d =>
                    <BotonesAside
                        key={d.titulo}
                        handleNavigate={handleNavigate}
                        navegarHacia={d.navegarhacia}
                        selectedNavigate={selectedNavigate}
                        titulo={d.titulo}
                        icono={d.icono}
                    />
                )}
                <Button
                    onClick={handleOpen}
                >
                    <Icon
                        icon="mdi:logout-variant"
                        width={25}
                        color={colorNotSelectedAside}
                        style={{
                            minWidth: 25
                        }}
                    />
                    <IconText>Cerrar sesión</IconText>
                </Button>
                <ModalConfirmacion
                    open={open}
                    handleClose={handleClose}
                    handleConfirmarModal={handleAceptarModal}
                    tituloModal={'Cerrar sesión'}
                    textoModal={'¿Está seguro que desea cerrar sesión?'}
                    textoConfirmarModal={'Confirmar'}
                />
            </ButtonsContainer>
        </Container>
    )
}

