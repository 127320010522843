import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 0em 0.5em 0;
`;

export const BotonComprarVender = styled.button`
  font-weight: 600;
  font-size: 15px;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  border-radius: 5px;
  color: ${({ theme }) => theme.primaryColor};
  height: 1.8em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5em 0.5em 0em 0.5em;
  gap: 0.5em;
  padding-bottom: 0.5em;
  width: 100%;
`;