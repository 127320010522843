import styled from "styled-components";
import { colors } from "../../theme/Themes";


export const Container = styled.div`
    flex: 1;
    height: 100vh;
    background-color: ${({theme}) => theme.secondaryBackgroundColor};
    @media (min-width: 992px) {
        height: auto;
        background-color: ${({theme}) => theme.primaryBackgroundColor};
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        margin-top: 1.25em;
	}
`

export const ContainerOperacion = styled.div`
    margin-top: 4.5em;
    width: 75%;
    @media (min-width: 992px) {
        margin-top: 1.4em;
	}
`

export const ContainerOperacionTexto = styled.div`
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    width: 100%;
    margin: 0.6em 0 0 0;
    border-radius: 5px;
    height: 2.5em;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
	}
`

export const ContainerTitulo = styled.div`
    display: flex;
    width: 75%;
    align-items: center;
`

export const Circle = styled.div`
    background-color: ${({theme}) => theme.primaryColor}; 
    width: 0.6em;
    height: 0.6em;
    border-radius: 50px;
    margin: 0.8em 0 0 0em;
`

export const Titulo = styled.p`
    margin: 0.8em 0 0 0.6em;
    font-size: 15px;
    font-weight: 600;
    color: ${({theme}) => theme.primaryColor}; 
`

export const ContainerDiariaLimite = styled.div`
    display: flex;
    width: 75%;
    justify-content: space-between;
`
export const ContainerDiaria = styled.div`
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    width: 58%;
    justify-content: space-between;
    border-radius: 5px;
    align-items: center;
    margin: 0.6em 0 0 0;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
	}
`

export const ActualMenu = styled.p`
margin: 0;
padding: 0.5em;
color: ${({theme}) => theme.primaryColor};
`

export const Arrow = styled.div`
margin-right: 0.5em;
margin-top: 0.2em;
color: ${({theme}) => theme.primaryColor};
cursor: pointer;
`

export const ContainerLimit = styled.div`
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    width: 35%;
    margin: 0.6em 0 0 0;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
    }
`

export const ContainerLimitLocal = styled.div`
    display: flex;
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    width: 35%;
    margin: 0.6em 0 0 0;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
    }
`

export const Limit = styled.p`
margin: 0 0 0 0.8em;
color: ${({theme}) => theme.primaryColor};
`

export const ContainerTituloPrecioCantidadImporte = styled.div`
display: flex;
width: 75%;
justify-content: space-between;
`

export const TituloPrecioCantidad = styled.p`
margin: 1em 0 0 0;
width: 47%;
color: ${({theme}) => theme.primaryColor};
`

export const InputsPrecioCantidad = styled.input`
    margin-top: 0.4em;
    width: 41%;
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    font-weight: 400;
    color: ${({theme}) => theme.primaryColor};
    background-color:  ${({theme}) => theme.primaryBackgroundColor};
    padding: 0.6em;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 4px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alternativeBackgroundColor};
	}
`

export const InputsPrecioCantidadDisabled = styled.div`
    margin-top: 0.4em;
    width: 41%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0.6em;
    // background-color: red;
    @media (min-width: 992px) {
        box-shadow: inset -4px -4px 14px ${({theme}) => theme.secondaryBackgroundColor}, inset 4px 2px 14px ${({theme}) => theme.secondaryBackgroundColor};
        border: 1px solid rgba(74, 69, 85, 0.2);
        background-color: ${({theme}) => theme.alterHomeBackgrooundColor};
	}
`

export const TextoImporte = styled.p`
    margin: 0.7em 0.5em 0 0;
    font-weight: 700;
    font-size: 18px;
    color: ${({theme}) => theme.primaryColor};
    @media (min-width: 992px) {
        margin: 0.5em 0.5em 0 0;
	}
`

export const BotonComprar = styled.button`
    margin: 0.5em 0 0 0;
    height: 2.2em;
    font-weight: 600;
    font-size: 18px;
    background-color: white;
    border-radius: 4px;
    color: #03DAC5;
    border: 2px solid #03DAC5;
    width: 76%;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 16px;
        margin-top: 1em;
	}
`

export const BotonVender = styled.button`
    margin: 0.5em 0 0 0;
    height: 2.2em;
    font-weight: 600;
    font-size: 18px;
    background-color: white;
    border-radius: 4px;
    color: #B00020;
    border: 2px solid #B00020;
    width: 76%;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 16px;
        margin-top: 0.7em;
	}
`

export const BotonComprarVender = styled.button`
margin: 0.5em 0 0 0;
    height: 2.2em;
    font-weight: 600;
    font-size: 18px;
    background-color: ${colors.blueRava};
    border-radius: 4px;
    color: white;
    border: none;
    width: 76%;
    cursor: pointer;
    @media (min-width: 992px) {
        font-size: 16px;
        margin-top: 0.7em;
	}
`

export const BotonDisabled = styled.button`
    margin: 0.5em 0 0 0;
    height: 2.2em;
    font-weight: 600;
    font-size: 18px;
    background-color: white;
    border-radius: 4px;
    color: ${colors.grayRava};
    border: 1px solid ${colors.grayRava};
    width: 76%;
    @media (min-width: 992px) {
        font-size: 16px;
	}
`

export const ErrorText = styled.p`
    color: ${({theme}) => theme.rojoPorcentaje}; 
    text-align: center;  
    margin: 1em 0 0 0;
    @media (min-width: 992px) {
        margin: 0.5em 0.5em 0;
    }
`
export const TenenciaTotalText = styled.p`
    color: ${({theme}) => theme.primaryColor};
    width: 75%;
    font-size: 0.9em;
    margin: 0.1em 0 0 0;
`

export const OperarTitle = styled.p`
    margin: 1em 0 0 1.5em;
    font-size: 1.3em;
    color: ${({theme}) => theme.primaryColor};
    font-weight: bold;
`

export const ContainerCardOfertas = styled.div`
    padding: 1em 1.8em;
    display: flex;
    flex-direction: column;
`