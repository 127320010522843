import React, { ChangeEvent, useState } from "react";

const useAgregarCuentaMenusHook = (setForm: (e: React.ChangeEvent<HTMLInputElement> | string, tipo?: string) => void, tipoForm: string) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl)
    
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (op?: string) => {
        setAnchorEl(null);
        if (op) {
            setForm(op, tipoForm);
        }
    };

    return {
        handleOpenMenu,
        handleCloseMenu,
        open, 
        anchorEl,
    };
};

export default useAgregarCuentaMenusHook;
