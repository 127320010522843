import { useContext, useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { CircularProgress } from "@mui/material"
import { DataContainer, Button, ButtonText, Container, ContainerButtons, LastButton, Whatsapp, TituloWhatsapp, Row } from './elements'
import { AccordionDatos } from '../../components/Perfil/AccordionDatos';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/authContext/AuthContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { UserContext } from '../../context/userContext/UserContext';
import { SecondRow, Image, DatosColumn, WhiteText, NameEjecutivoText, IconsContainer } from '../../components/Perfil/elements';
import { Link } from 'react-router-dom';
import { getUserData } from '../../api/userApi';
import { useModalHook } from '../../hooks/useModalHook';
import ModalConfirmacion from '../../components/Modal/ModalConfirmacion';
import { isImageValidImageUrl } from '../../helpers/validateImage';
import { ThemeContext } from 'styled-components';
import { LoadingContainer } from '../../elements/loadingContainer';
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader';
import { TipoAccount } from '../../enums/enums';
import { formatearAccountSearched } from '../../helpers/formatearAccountSearched';

export const ModalOptions = {
    CerrarSesion: 'Cerrar Sesion',
    CambioPIN: 'Cambio de PIN',
}


export const Perfil = () => {

    const navigate = useNavigate();
    const { logOut } = useContext(AuthContext);
    const { width } = useWindowDimensions();
    const { userData, setUserData } = useContext(UserContext);
    const { setToken, accountType, accountSearched } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState<boolean>(false);

    const themeContext = useContext(ThemeContext)

    const { primaryColor, loadingColor } = themeContext;



    const accountTypeStorage = localStorage.getItem("accountType");


    const cerrarSesion = () => {
        logOut();
        navigate('/login');
        window.location.reload();
    };

    const restablecerPin = () => {
        // logOut();
        navigate('/cambiar-pin');
    }


    const { open: openCerrarSesion, handleOpen: handleOpenCerrarSesion, handleClose: handleCloseCerrarSesion, handleAceptarModal: handleAceptarCerrarSesion } = useModalHook(cerrarSesion)

    const { open: openCambioPIN, handleOpen: handleOpenCambioPIN, handleClose: handleCloseCambioPIN, handleAceptarModal: handleAceptarCambioPIN } = useModalHook(restablecerPin)

    useEffect(() => {
        const refresh = async () => {
            try {
                setLoading(true);
                const tokenStorage = localStorage.getItem("token");
                const accountTypeStorage = localStorage.getItem("accountType");
                tokenStorage && setToken(tokenStorage);
                if (tokenStorage) {
                    let resp
                    if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                        resp = await getUserData(tokenStorage, formatearAccountSearched(accountSearched));
                    } else {
                        resp = await getUserData(tokenStorage);
                    }
                    setUserData(resp.data.body.titular);
                    userData?.url_foto_manager && validateImage(userData.url_foto_manager);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        refresh();
    }, [accountSearched])

    function titleCase(str: string | undefined) {
        if (str !== undefined) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] =
                    splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        } else {
            return '';
        }
    }

    const validateImage = async (url: string) => {
        try {
            const res = await isImageValidImageUrl(url)
            setIsValid(res);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Container>

            <ResponsiveHeader title='Perfil' showSwitch={false} />

            {
                loading ?
                    <LoadingContainer>
                        <CircularProgress style={{ color: loadingColor }} />
                    </LoadingContainer>
                    :
                    <DataContainer>
                        <AccordionDatos />
                        {width > 992 ?
                            <div>
                                <SecondRow>
                                    <Row>
                                        {
                                            userData?.url_foto_manager && isValid ?
                                                <Image
                                                    src={userData?.url_foto_manager}
                                                    alt='foto-manager'
                                                />
                                                :
                                                <Icon icon="mdi:account-outline" style={{ ...styles.iconContactoDesk, color: 'white' }} />
                                        }
                                        <DatosColumn>
                                            <WhiteText>Ejecutivo/a de cuenta</WhiteText>
                                            <NameEjecutivoText>{titleCase(userData?.manager)}</NameEjecutivoText>
                                        </DatosColumn>
                                    </Row>
                                    <IconsContainer>
                                        <Link to={'https://wa.me/5491152197791'} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                            <Whatsapp>
                                                <Icon icon="ic:baseline-whatsapp" style={{ ...styles.whatsappIconDesk, color: 'white' }} />
                                                <TituloWhatsapp>Whatsapp</TituloWhatsapp>
                                            </Whatsapp>
                                        </Link>
                                    </IconsContainer>
                                </SecondRow>

                                <ContainerButtons>
                                    <Button
                                        onClick={() => navigate('/perfil/movimientos')}
                                    >
                                        <Row>
                                            <Icon icon="tabler:arrows-exchange-2" style={{ ...styles.iconsDesk, color: primaryColor }} />
                                            <ButtonText>Movimientos</ButtonText>
                                        </Row>
                                        <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '0.8em', height: '0.6em', marginRight: '0.5em', color: primaryColor }} />
                                    </Button>
                                    {
                                        (accountType !== TipoAccount.Admin && accountTypeStorage !== TipoAccount.Admin) ?
                                            < Button
                                                onClick={() => navigate('/perfil/mi-billetera')}
                                            >
                                                <Row>
                                                    <Icon icon="uil:wallet" style={{ ...styles.iconsDesk, color: primaryColor }} />
                                                    <ButtonText>Mi billetera</ButtonText>
                                                </Row>
                                                <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '0.8em', height: '0.6em', marginRight: '0.5em', color: primaryColor }} />
                                            </Button> : null
                                    }
                                    {((accountType !== TipoAccount.Admin) && localStorage.getItem("accountType") !== TipoAccount.Admin) ? <Button
                                        onClick={restablecerPin}
                                    >
                                        <Row>
                                            <Icon icon="tabler:lock-cog" style={{ ...styles.iconsDesk, color: primaryColor }} />
                                            <ButtonText>Cambiar PIN</ButtonText>
                                        </Row>
                                        <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '0.8em', height: '0.6em', marginRight: '0.5em', color: primaryColor }} />
                                    </Button> : null}
                                    <Button
                                        onClick={() => navigate('/perfil/preguntas-frecuentes')}
                                    >
                                        <Row>
                                            <Icon icon="mdi:chat-question-outline" style={{ ...styles.iconsDesk, color: primaryColor }} />
                                            <ButtonText>Preguntas frecuentes</ButtonText>
                                        </Row>
                                        <Icon icon="material-symbols:arrow-forward-ios-rounded" style={{ width: '0.8em', height: '0.6em', marginRight: '0.5em', color: primaryColor }} />
                                    </Button>
                                    {/* <ModalConfirmacion
                                        open={openCambioPIN}
                                        handleClose={handleCloseCambioPIN}
                                        handleConfirmarModal={handleAceptarCambioPIN}
                                        textoModal={'Está a punto de cerrar sesión ¿Desea continuar?'}
                                        textoConfirmarModal={'Confirmar'}
                                    /> */}
                                </ContainerButtons>
                            </div>
                            :
                            <ContainerButtons>
                                <Button
                                    onClick={() => navigate('/perfil/mi-billetera')}
                                >
                                    <Icon icon="uil:wallet" style={{ ...styles.icons, color: primaryColor }} />
                                    <ButtonText>Mi billetera</ButtonText>
                                </Button>
                                <Button
                                    onClick={() => navigate('/perfil/movimientos')}
                                >
                                    <Icon icon="tabler:arrows-exchange-2" style={{ ...styles.icons, color: primaryColor }} />
                                    <ButtonText>Movimientos</ButtonText>
                                </Button>
                                <Button
                                    onClick={handleOpenCambioPIN}
                                >
                                    <Icon icon="tabler:lock-cog" style={{ ...styles.icons, color: primaryColor }} />
                                    <ButtonText>Cambiar PIN</ButtonText>
                                </Button>
                                <Button
                                    onClick={() => navigate('/perfil/preguntas-frecuentes')}
                                >
                                    <Icon icon="mdi:chat-question-outline" style={{ ...styles.icons, color: primaryColor }} />
                                    <ButtonText>Preguntas frecuentes</ButtonText>
                                </Button>
                                <LastButton
                                    onClick={handleOpenCerrarSesion}
                                >
                                    <Icon icon="mdi:logout-variant" style={{ ...styles.icons, color: primaryColor }} />
                                    <ButtonText>Cerrar sesión</ButtonText>
                                </LastButton>
                                <ModalConfirmacion
                                    open={openCerrarSesion}
                                    handleClose={handleCloseCerrarSesion}
                                    handleConfirmarModal={handleAceptarCerrarSesion}
                                    textoModal={'¿Está seguro que desea cerrar sesión?'}
                                    textoConfirmarModal={'Confirmar'}
                                />
                                <ModalConfirmacion
                                    open={openCambioPIN}
                                    handleClose={handleCloseCambioPIN}
                                    handleConfirmarModal={handleAceptarCambioPIN}
                                    textoModal={'Está a punto de cerrar sesión ¿Desea continuar?'}
                                    textoConfirmarModal={'Confirmar'}
                                />
                            </ContainerButtons>
                        }
                    </DataContainer>
            }
        </Container >
    )
}


const styles = {
    icons: {
        height: '1.5em',
        width: '1.5em',
        padding: 4,
        margin: '0 0.2em 0 0.2em',
    },
    iconContacto: {
        height: '1.6em',
        width: '1.6em',
        padding: 4,
        color: 'white',
    },
    iconsDesk: {
        height: '1em',
        width: '1em',
        padding: 4,
        margin: '0 0.2em 0 0.2em',
    },
    iconContactoDesk: {
        height: '2em',
        width: '2em',
        padding: 4,
    },
    whatsappIconDesk: {
        height: '1.3em',
        width: '1.3em',
        padding: '0.3em 0',
        color: 'white',
        marginRight: '0.5em',

    }
}
