import React, { ChangeEvent, useContext } from 'react'
import { BotonContinuar, BotonContinuarDisabled, Container, ContainerBotonContinuar, ContainerButtonSeleccionar, ContainerInputs, Input, MensajeError, TextoButtonSeleccionado, TextoButtonSeleccionar } from './elements'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ThemeContext } from 'styled-components';
import MenuPopUp from '../../MenuPopUp/MenuPopUp';
import { FormAgregarCuentaProps } from '../../../interfaces/agregarCuentaInterface';
import useAgregarCuentaMenusHook from '../../../hooks/useAgregarCuentaMenusHook';

interface Props {
    form: FormAgregarCuentaProps;
    handleChangeInputs: (e: ChangeEvent<HTMLInputElement> | string, tipo?: string) => void;
    habilitarContinuar: boolean;
    error: string;
    continuar: () => void;
}


const IngresoDatosCuenta = ({
    form,
    handleChangeInputs,
    habilitarContinuar,
    error,
    continuar,
}: Props) => {

    const { primaryColor } = useContext(ThemeContext);

    const listaTipoMoneda = ["PESOS", "DÓLARES"];
    const listaTipoCuenta = ["CAJA DE AHORROS", "CUENTA CORRIENTE", "OTROS"];
    const listaTipoClave = ["CBU", "CVU"];

    const {
        open: openMoneda,
        anchorEl: anchorElMoneda,
        handleCloseMenu: handleCloseTipoMoneda,
        handleOpenMenu: handleOpenMenuMoneda,
    } = useAgregarCuentaMenusHook(handleChangeInputs, 'tipoMoneda');
    const {
        open: openCuenta,
        anchorEl: anchorElCuenta,
        handleOpenMenu: handleOpenMenuCuenta,
        handleCloseMenu: handleCloseTipoCuenta
    } = useAgregarCuentaMenusHook(handleChangeInputs, 'tipoCuenta');
    const {
        open: openClave,
        anchorEl: anchorElClave,
        handleOpenMenu: handleOpenMenuClave,
        handleCloseMenu: handleCloseTipoClave
    } = useAgregarCuentaMenusHook(handleChangeInputs, 'tipoClave');


    return (
        <Container>
            {/* MENU MONEDA */}
            <ContainerButtonSeleccionar onClick={handleOpenMenuMoneda}>
                {
                    !form.tipoMoneda.length
                        ? <TextoButtonSeleccionar>
                            Seleccione el tipo de moneda de la cuenta
                        </TextoButtonSeleccionar>
                        : <TextoButtonSeleccionado>
                            {form.tipoMoneda}
                        </TextoButtonSeleccionado>
                }
                <KeyboardArrowDownIcon
                    style={{
                        color: primaryColor,
                        fontSize: '1.8em',
                        paddingRight: '0.5em',
                    }}
                />
            </ContainerButtonSeleccionar>
            <MenuPopUp
                open={openMoneda}
                anchorEl={anchorElMoneda}
                handleCloseMenu={handleCloseTipoMoneda}
                listaMenu={listaTipoMoneda}
                actualMenu={form.tipoMoneda}
            />
            {/* MENU TIPO CBU/CVU */}
            <ContainerButtonSeleccionar onClick={handleOpenMenuClave}>
                {
                    !form.tipoClave.length
                        ? <TextoButtonSeleccionar>
                            Seleccione si la clave es bancaria (CBU) o virtual (CVU)
                        </TextoButtonSeleccionar>
                        : <TextoButtonSeleccionado>
                            {form.tipoClave}
                        </TextoButtonSeleccionado>
                }
                <KeyboardArrowDownIcon
                    style={{
                        color: primaryColor,
                        fontSize: '1.8em',
                        paddingRight: '0.5em',
                    }}
                />
            </ContainerButtonSeleccionar>
            <MenuPopUp
                open={openClave}
                anchorEl={anchorElClave}
                handleCloseMenu={handleCloseTipoClave}
                listaMenu={listaTipoClave}
                actualMenu={form.tipoClave}
            />
            {/* INPUT CBU/CVU */}
            <ContainerInputs>
                <Input
                    type='text'
                    name='cbu'
                    placeholder='Ingresá el número de CBU/CVU'
                    maxLength={22}
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.cbu}
                />
            </ContainerInputs>
            {/* MENU TIPO CUENTA */}
            <ContainerButtonSeleccionar onClick={handleOpenMenuCuenta}>
                {
                    !form.tipoCuenta.length
                        ? <TextoButtonSeleccionar>
                            Seleccione el tipo de cuenta
                        </TextoButtonSeleccionar>
                        : <TextoButtonSeleccionado>
                            {form.tipoCuenta}
                        </TextoButtonSeleccionado>
                }
                <KeyboardArrowDownIcon
                    style={{
                        color: primaryColor,
                        fontSize: '1.8em',
                        paddingRight: '0.5em',
                    }}
                />
            </ContainerButtonSeleccionar>
            <MenuPopUp
                open={openCuenta}
                anchorEl={anchorElCuenta}
                handleCloseMenu={handleCloseTipoCuenta}
                listaMenu={listaTipoCuenta}
                actualMenu={form.tipoCuenta}
            />
            {/* INPUT NRO CUENTA */}
            {
                form.tipoClave !== "CVU"
                    ? <ContainerInputs>
                        <Input
                            type='text'
                            name='nroCuenta'
                            placeholder='Ingresá el número de cuenta'
                            onChange={(e) => { handleChangeInputs(e) }}
                            value={form.nroCuenta}
                        />
                    </ContainerInputs>
                    : null
            }
            {/* INPUT CUIT */}
            <ContainerInputs>
                <Input
                    type='text'
                    name='cuit'
                    placeholder='Ingresá el cuit'
                    maxLength={11}
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.cuit}
                />
            </ContainerInputs>
            {/* INPUT ALIAS */}
            <ContainerInputs>
                <Input
                    type='text'
                    name='alias'
                    placeholder='Ingresá el alias'
                    onChange={(e) => { handleChangeInputs(e) }}
                    value={form.alias}
                />
            </ContainerInputs>

            {/* BOTON CONTINUAR */}
            {
                habilitarContinuar
                    ? <ContainerBotonContinuar>
                        <BotonContinuar
                            onClick={continuar}
                        >CONTINUAR</BotonContinuar>
                    </ContainerBotonContinuar>
                    : <ContainerBotonContinuar>
                        <BotonContinuarDisabled>CONTINUAR</BotonContinuarDisabled>
                    </ContainerBotonContinuar>
            }
            {/* MENSAJE DE ERROR */}
            {
                error && <MensajeError>{error}</MensajeError>
            }
        </Container >
    )
}

export default IngresoDatosCuenta