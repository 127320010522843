import { useContext, useEffect, useState } from "react";
import { EspeciesFavoritasContext } from "../context/especiesFavoritasContext/EspeciesFavoritasContext";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { TipoMercado } from "../enums/enums";
import { DataEspecie } from "../interfaces/mercadoInferfaces";
import io from "socket.io-client";
import { ThemeContext } from "styled-components";
import { TitulosOrdenarPor } from "../interfaces/TitulosIntreface";
import { getDataPanel, getDataPanelOpciones } from "../api/mercadoApi";
import { AuthContext } from "../context/authContext/AuthContext";
import { arrMenuListMercadoInternacional, arrMenuListMercadoLocal } from "../helpers/arrayEspecies";

export const useEspeciesHook = (actualMenuEspecies: string) => {

    const { tipoMercado } = useContext(MercadoContext);
    const [dataListaEspecies, setDataListaEspecies] = useState<DataEspecie[] | null>(null);
    const [colorMap, setColorMap] = useState({});

    const [favoritos, setFavoritos] = useState<DataEspecie[] | null | undefined>([]);
    const [filtrado, setFiltrado] = useState<DataEspecie[] | null | undefined>(favoritos);

    const [noHayData, setNoHayData] = useState<boolean>(false);
    const [errorSocket, setErrorSocket] = useState(false)

    const [loadingEspecies, setLoadingEspecies] = useState(false);
    const [resultadoAnterior, setResultadoAnterior] = useState<DataEspecie[] | null | undefined>();
    const [actualizarAnterior, setActualizarAnterior] = useState(false)

    const { setEspeciesFromApi, especiesExteriorFavoritas, especiesLocalesFavoritas } = useContext(EspeciesFavoritasContext);
    const { tokenPublico } = useContext(AuthContext);

    const [textoSearchBar, setTextoSearchBar] = useState<string>('');

    const [mensajeSocket, setMensajeSocket] = useState<DataEspecie[] | null | undefined>([]);
    const [skipFiltrar, setSkipFiltrar] = useState<boolean>(false);

    const [checkedOperadas, setCheckedOperadas] = useState<boolean>(true);

    const [ordenarPor, setOrdenarPor] = useState<{ titulo: keyof TitulosOrdenarPor, ascendente: boolean }>({ titulo: 'Especie', ascendente: true })

    const { verdeCambioPrecios, rojoCambioPrecios, azulCambioCantidades } = useContext(ThemeContext);

    const OrdenarPorTitulo: TitulosOrdenarPor = {
        'Especie': 'especie',
        'Precio': 'ultimo',
        '% día': 'variacion',
        'Cant. Compra': 'cantcompra',
        'Precio Compra': 'preciocompra',
        'Precio Venta': 'precioventa',
        'Cant. Venta': 'cantventa',
        'Volumen': 'volefectivo',
    }

    const serverUrl = "wss://www.servicio.precios.rava.tv/"; // Reemplaza con la URL de tu servidor WebSocket
    // const serverUrl = "http://localhost:4000/"; // Reemplaza con la URL de tu servidor WebSocket
    const socket = io(serverUrl, {
        transports: ["websocket"],
        path: "/socket.io",
        autoConnect: false,
        reconnection: false,
    });// Conecta al servidor WebSocket

    //agregar variable pa ver si esta conectado el scoket, sacar el socket del use effect

    useEffect(() => {
        // Comparar los datos actuales con los datos anteriores
        if (filtrado && resultadoAnterior) {
            const newColorMap: any = {};
            filtrado.forEach((datoActual) => {
                const datoAnterior = resultadoAnterior?.find(r => r.especie === datoActual.especie);

                // Validar si hay cambios en los campos específicos
                if (datoAnterior) {
                    const cambios = [
                        datoActual.ultimo !== datoAnterior.ultimo,
                        datoActual.cantcompra !== datoAnterior.cantcompra,
                        datoActual.cantventa !== datoAnterior.cantventa,
                        datoActual.preciocompra !== datoAnterior.preciocompra,
                        datoActual.precioventa !== datoAnterior.precioventa,
                    ];
                    const haCambiado = cambios.some(cambio => cambio);

                    if (haCambiado) {
                        newColorMap[datoActual.especie] = {};
                    }
                    // Cambiar el color del contenedor según el cambio en cada campo
                    if (datoActual.ultimo !== datoAnterior.ultimo) {
                        newColorMap[datoActual.especie].ultimo =
                            datoActual.ultimo > datoAnterior.ultimo
                                ? verdeCambioPrecios
                                : rojoCambioPrecios;
                    }
                    if (datoActual.cantcompra !== datoAnterior.cantcompra) {
                        newColorMap[datoActual.especie].cantCompra = azulCambioCantidades;

                    }
                    if (datoActual.cantventa !== datoAnterior.cantventa) {
                        newColorMap[datoActual.especie].cantVenta = azulCambioCantidades;
                    }
                    if (datoActual.preciocompra !== datoAnterior.preciocompra) {
                        newColorMap[datoActual.especie].precioCompra =
                            datoActual.ultimo > datoAnterior.ultimo
                                ? verdeCambioPrecios
                                : rojoCambioPrecios;
                    }
                    if (datoActual.precioventa !== datoAnterior.precioventa) {
                        newColorMap[datoActual.especie].precioVenta =
                            datoActual.ultimo > datoAnterior.ultimo
                                ? verdeCambioPrecios
                                : rojoCambioPrecios;
                    }
                }
            });
            setColorMap(newColorMap);
            // Mantener el color durante 2 segundos
            setTimeout(() => {
                setColorMap({})
            }, 1000);
            // Actualizar el estado de los datos anteriores

        }
        filtrado && setResultadoAnterior([...filtrado]);
    }, [filtrado]);


    useEffect(() => {
        if (actualizarAnterior) {
            setResultadoAnterior(filtrado)
        }
    }, [actualizarAnterior])

    const ordenarPorTitulos = (titulo: string) => {
        if (titulo === ordenarPor.titulo) {
            setOrdenarPor({ titulo, ascendente: !ordenarPor.ascendente })
        } else {
            setOrdenarPor({ titulo: titulo as keyof TitulosOrdenarPor, ascendente: true })
        }
    }

    const ordenarMensajeAscendente = (mensaje: any) => {
        return mensaje?.sort((a: any, b: any) => {
            if (ordenarPor.titulo === "Especie") {
                if (a[OrdenarPorTitulo[ordenarPor.titulo]] > b[OrdenarPorTitulo[ordenarPor.titulo]]) {
                    return 1
                }
                if (a[OrdenarPorTitulo[ordenarPor.titulo]] < b[OrdenarPorTitulo[ordenarPor.titulo]]) {
                    return -1
                }
                return 0
            } else {
                if (Number(a[OrdenarPorTitulo[ordenarPor.titulo]]) > Number(b[OrdenarPorTitulo[ordenarPor.titulo]])) {
                    return 1
                }
                if (Number(a[OrdenarPorTitulo[ordenarPor.titulo]]) < Number(b[OrdenarPorTitulo[ordenarPor.titulo]])) {
                    return -1
                }
                return 0
            }
        })
    }

    const ordenarMensajeDescendente = (mensaje: any) => {
        return mensaje?.sort((a: any, b: any) => {
            if (ordenarPor.titulo === "Especie") {
                if (a[OrdenarPorTitulo[ordenarPor.titulo]] < b[OrdenarPorTitulo[ordenarPor.titulo]]) {
                    return 1
                }
                if (a[OrdenarPorTitulo[ordenarPor.titulo]] > b[OrdenarPorTitulo[ordenarPor.titulo]]) {
                    return -1
                }
                return 0
            } else {
                if (Number(a[OrdenarPorTitulo[ordenarPor.titulo]]) < Number(b[OrdenarPorTitulo[ordenarPor.titulo]])) {
                    return 1
                }
                if (Number(a[OrdenarPorTitulo[ordenarPor.titulo]]) > Number(b[OrdenarPorTitulo[ordenarPor.titulo]])) {
                    return -1
                }
                return 0
            }
        })
    }

    const filtrarPorTextoSearchBar = (data: any) => {
        return data?.filter((d: any) => d?.especie.toLocaleLowerCase().startsWith(textoSearchBar.toLocaleLowerCase()))
    }

    const revisarOrdenamientos = (data: any) => {
        if (filtrado?.length) {
            const dataOrdenada: any = []
            filtrado?.forEach(f => {
                let dataActualizada = data?.find((d: any) => f.especie === d.especie)
                dataOrdenada.push(dataActualizada)
            })
            return filtrarPorTextoSearchBar(dataOrdenada)
        }
        return filtrarPorTextoSearchBar(data)
    }

    const filtrarDataSocket = (mensaje: any) => {
        let operadas = mensaje
        if (checkedOperadas) {
            operadas = operadas?.filter((d: any) => d.ultimo.length)
        }
        setFiltrado(revisarOrdenamientos(operadas))
        setActualizarAnterior(true)
        setLoadingEspecies(false);
    }

    useEffect(() => {
        if (!skipFiltrar) {
            filtrarDataSocket(mensajeSocket);
        } else {
            setSkipFiltrar(false);
        }
    }, [mensajeSocket])

    useEffect(() => {
        let operadas = []
        if (ordenarPor.ascendente) {
            operadas = ordenarMensajeAscendente(filtrado)
        } else {
            operadas = ordenarMensajeDescendente(filtrado)
        }
        setFiltrado(filtrarPorTextoSearchBar(operadas));
    }, [ordenarPor])

    useEffect(() => {
        let filtradas = mensajeSocket
        // let operadas
        if (checkedOperadas) {
            filtradas = mensajeSocket?.filter((d: any) => d.ultimo.length)
        }
        if (ordenarPor.ascendente) {
            filtradas = ordenarMensajeAscendente(filtradas)
        } else {
            filtradas = ordenarMensajeDescendente(filtradas)
        }
        setFiltrado(filtrarPorTextoSearchBar(filtradas))
        setResultadoAnterior(null)
    }, [textoSearchBar, checkedOperadas, ordenarPor])

    // useEffect(() => {
    //     width < 992 &&
    //         obtenerData();
    // }, [tipoMercado]);


    const handleChangeTextoSearchBar = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTextoSearchBar(e.target.value.toLocaleUpperCase());
    }

    // const asegurarseQueNoHayDatos = (data: any) => {
    //     if (data === null || data.length === 0) {
    //         setNoHayData(true);
    //     }
    // }

    const formatearPaneles = (panel: string) => {
        if (panel === "FAVORITOS") {
            return 'favoritesRoom';
        } else if (panel === "LÍDER") {
            return 'lider';
        } else if (panel === "30 del DOW") {
            return 'dow';
        }
        return panel.toLocaleLowerCase();
    }

    useEffect(() => {
        if (actualMenuEspecies !== "FAVORITOS") {

            setResultadoAnterior(null)
            setFiltrado([])
            setMensajeSocket([])
            setLoadingEspecies(true);
            setNoHayData(false);
            setSkipFiltrar(true);
            setTextoSearchBar('')
            setOrdenarPor({ titulo: 'Especie', ascendente: true })
            setEspeciesFromApi();
            setErrorSocket(false)

            //crea la conexion al socket
            socket.connect();
            // maneja el evento de conexion 
            const onConnect = () => {

            }
            //maneja el evento de conexion en el panel favoritos
            const onConnectFavoritos = () => {
                const roomId = `${socket.id}`
                const userId = localStorage.getItem("userId");
                socket.emit("joinRoom", roomId);
                socket.on(formatearPaneles(actualMenuEspecies), getDataSocket);
                socket.emit(formatearPaneles(actualMenuEspecies), roomId, userId);
            }
            // maneja el evento de desconexion 
            const onDisconnect = () => {
                setFiltrado([])
                setLoadingEspecies(false);
            }

            // maneja el evento para recibir los mensajes del panel que este solicitando 
            const getDataSocket = (mensaje: any) => {
                if (actualMenuEspecies === 'FAVORITOS') {
                    const data = mensaje.filter((m: any) => {
                        return tipoMercado === TipoMercado.Local
                            ? !m.especie.includes('_US')
                                ? m
                                : undefined
                            : m.especie.includes('_US')
                                ? m
                                : undefined
                    })
                    setMensajeSocket(data)
                } else {
                    const data: DataEspecie[] = JSON.parse(JSON.parse(mensaje))
                    setMensajeSocket(data);
                }
            }

            const onConnectError = (err: Error) => {
                setLoadingEspecies(false);
                setFiltrado([])
                setNoHayData(true);
                socket.on("disconnect", onDisconnect);
                socket.disconnect();
                setErrorSocket(true)
                // if (err.message === "websocket error") {
                //     tryReconnect();
                // }
            }

            const tryReconnect = () => {
                setTimeout(() => {
                    socket.connect();
                }, 5000)
            }

            //llamado a los eventos
            if (actualMenuEspecies !== "FAVORITOS") {
                socket.on('connect', onConnect);
                socket.on(formatearPaneles(actualMenuEspecies), getDataSocket);
            } else {
                // socket.on('connect', onConnectFavoritos);
                socket.on("disconnect", onDisconnect);
                socket.disconnect();
            }
            socket.on("disconnect", onDisconnect);
            // socket.on("close", tryReconnect);
            setActualizarAnterior(false)
            socket.on("connect_error", onConnectError);

            return () => {
                // destruye la conexion al socket
                socket.disconnect();
                // remueve los 'listeners' de los eventos
                socket.off("disconnect", onDisconnect)
                socket.off("connect", onConnect)
                socket.off(formatearPaneles(actualMenuEspecies), getDataSocket)
                setLoadingEspecies(false);
            }
        }

    }, [actualMenuEspecies, tipoMercado]);

    function searchPanelParam(panel: string) {
        const found =
            tipoMercado === TipoMercado.Local
                ? arrMenuListMercadoLocal.filter(e => e.title === panel)
                : arrMenuListMercadoInternacional.filter(e => e.title === panel);
        return found[0].panel;
    }

    const cargarPreciosSinSocket = async (actualMenu: string) => {
        setLoadingEspecies(true);
        setNoHayData(false)
        if (tokenPublico) {
            try {
                const param = searchPanelParam(actualMenu);
                let resp;
                if (param !== 'OPC') {
                    resp = await getDataPanel(tokenPublico, param!, tipoMercado);
                    // asegurarseQueNoHayDatos(resp.data.body)
                    setFiltrado(resp.data.body);
                } else {
                    resp = await getDataPanelOpciones(tokenPublico);
                    // asegurarseQueNoHayDatos(resp.data.body)
                    // setDataOpciones(resp.data.body);
                    // setDataOpcionesFiltrado(resp.data.body);
                    setFiltrado(resp.data.body)
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoadingEspecies(false);
            }
        }
    }

    // WebSocketComponent()
    // useEffect(() => {
    //     if (esMercadoLocal()) {
    //         let favoritoEliminado = favoritos?.filter(
    //             favs => !especiesLocalesFavoritas.includes(favs.especie),
    //         );
    //         if (favoritoEliminado && favoritoEliminado[0] !== undefined) {
    //             let idx = favoritos?.findIndex(
    //                 fav => fav.especie === favoritoEliminado![0].especie,
    //             );
    //             if (typeof idx === 'number') {
    //                 setFavoritos(favoritos =>
    //                     favoritos?.filter((_, index) => index !== idx),
    //                 );
    //             }
    //         }
    //     } else {
    //         let favoritoEliminado = favoritos?.filter(
    //             favs => !especiesExteriorFavoritas.includes(favs.especie),
    //         );
    //         if (favoritoEliminado && favoritoEliminado[0] !== undefined) {
    //             let idx = favoritos?.findIndex(
    //                 fav => fav.especie === favoritoEliminado![0].especie,
    //             );
    //             if (typeof idx === 'number') {
    //                 setFavoritos(favoritos =>
    //                     favoritos?.filter((_, index) => index !== idx),
    //                 );
    //             }
    //         }
    //     }
    // }, [especiesLocalesFavoritas, especiesExteriorFavoritas]);

    // useEffect(() => {
    //     const refresh = async () => {
    //         try {
    //             setReloaded(true)
    //             setSelectedNavigate(TipoNavigate.Mercado)
    //             if (width < 992) {
    //                 setLoadingEspecies(true);
    //             }
    //             const tokenStorage = localStorage.getItem("token");
    //             const tokenPublicoStorage = localStorage.getItem("tokenPublico");
    //             tokenStorage && setToken(tokenStorage);
    //             tokenPublicoStorage && setTokenPublico(tokenPublicoStorage);
    //             if (tokenPublicoStorage && tokenStorage) {
    //                 const resp = await getFavourites(tokenStorage);
    //                 let arrLocal: string[] = [];
    //                 let arrExterior: string[] = [];
    //                 resp.data.body.favouriteList.forEach(element => {
    //                     if (element.tipoMercado === 'LOCAL') {
    //                         arrLocal.push(element.especie);
    //                     } else {
    //                         arrExterior.push(element.especie);
    //                     }
    //                 });
    //                 setEspeciesFromApi(resp.data.body.favouriteList);
    //                 if (tipoMercado === TipoMercado.Local) {
    //                     if (arrLocal.length !== 0) {
    //                         const respFavsLocal = await getDataEspecie(tokenPublicoStorage, arrLocal);
    //                         setFavoritos(respFavsLocal.data.body);
    //                         if (width < 992) {
    //                             setFiltrado(respFavsLocal.data.body);
    //                         }
    //                     }
    //                 } else {
    //                     if (arrExterior.length !== 0) {
    //                         const respFavsExt = await getDataEspecie(tokenPublicoStorage, arrExterior);
    //                         setFavoritos(respFavsExt.data.body);
    //                         if (width < 992) {
    //                             setFiltrado(respFavsExt.data.body);
    //                         }
    //                     }
    //                 }
    //             }
    //         } catch (error) {
    //             console.log(error);
    //         } finally {
    //             if (width < 992) {
    //                 setLoadingEspecies(false);
    //             }
    //         }
    //     }
    //     if (!reloaded) {
    //         refresh();
    //     }
    // }, [filtrado])



    // useEffect(() => {
    //     if (width < 992) {
    //         setearFiltradoData();
    //     }
    // }, [actualMenuEspecies, dataListaEspecies, favoritos]);

    // useEffect(() => {
    //     if (width < 992) {
    //         setDataListaEspecies([]);
    //         obtenerData();
    //     }
    // }, [actualMenuEspecies]);


    function esMercadoLocal() {
        return tipoMercado === TipoMercado.Local;
    }

    function hayFavoritos() {
        return esMercadoLocal()
            ? especiesLocalesFavoritas.length !== 0
            : especiesExteriorFavoritas.length !== 0;
    }

    // function searchPanelParam(panel: string) {
    //     const found =
    //         tipoMercado === TipoMercado.Local
    //             ? arrMenuListMercadoLocal.filter(e => e.title === panel)
    //             : arrMenuListMercadoInternacional.filter(e => e.title === panel);
    //     return found[0].panel;
    // }

    // function getHour() {
    //     return moment().format('HH:mm:ss');
    // }

    // async function obtenerData() {
    //     setLoadingEspecies(true)
    //     setNoHayData(false)
    //     ultimaHora.current = getHour();
    //     if (tokenPublico) {
    //         if (actualMenuEspecies === 'FAVORITOS') {
    //             if (esMercadoLocal()) {
    //                 if (especiesLocalesFavoritas.length !== 0) {
    //                     try {
    //                         let resp = await getDataEspecie(
    //                             tokenPublico,
    //                             especiesLocalesFavoritas,
    //                         );
    //                         asegurarseQueNoHayDatos(resp.data.body)
    //                         setFavoritos(resp.data.body);
    //                     } catch (error) {
    //                         console.log(error);
    //                     } finally {
    //                         setLoadingEspecies(false)
    //                     }
    //                 } else {
    //                 }
    //             } else {
    //                 if (especiesExteriorFavoritas.length !== 0) {
    //                     try {
    //                         let resp = await getDataEspecie(
    //                             tokenPublico,
    //                             especiesExteriorFavoritas,
    //                         );
    //                         asegurarseQueNoHayDatos(resp.data.body)
    //                         setFavoritos(resp.data.body);
    //                     } catch (error) {
    //                         console.log(error);
    //                     } finally {
    //                         setLoadingEspecies(false);
    //                     }
    //                 } else {
    //                 }
    //             }
    //         } else {
    //             try {
    //                 const param = searchPanelParam(actualMenuEspecies);
    //                 let resp;
    //                 if (param !== 'OPC') {
    //                     resp = await getDataPanel(tokenPublico, param!, tipoMercado);
    //                     asegurarseQueNoHayDatos(resp.data.body)
    //                     setDataListaEspecies(resp.data.body);
    //                 } else {
    //                     resp = await getDataPanelOpciones(tokenPublico);
    //                     asegurarseQueNoHayDatos(resp.data.body)
    //                     setDataOpciones(resp.data.body);
    //                     setDataOpcionesFiltrado(resp.data.body);
    //                 }
    //             } catch (error) {
    //                 console.log(error);
    //             } finally {
    //                 setLoadingEspecies(false);
    //             }
    //         }
    //     }
    //     setLoadingEspecies(false)
    // }

    // function setearFiltradoData() {
    //     if (width < 992) {
    //         if (actualMenuEspecies === 'FAVORITOS') {
    //             setFiltrado(favoritos);
    //         } else if (actualMenuEspecies === 'OPCIONES') {
    //             setDataOpcionesFiltrado(dataOpciones);
    //         } else {
    //             setFiltrado(dataListaEspecies);
    //         }
    //     }
    // }
    // const filtradoSearhBar = (especie: string) => {
    //     if (width < 992) {
    //         if (especie) {
    //             if (actualMenuEspecies === 'OPCIONES') {
    //                 setDataOpcionesFiltrado(dataOpciones?.filter(d => d.especie.toLocaleLowerCase().startsWith(especie.toLocaleLowerCase())))
    //             } else {
    //                 setFiltrado(dataListaEspecies?.filter(d => d.especie.toLocaleLowerCase().startsWith(especie.toLocaleLowerCase())));
    //             }
    //         } else if (actualMenuEspecies !== 'OPCIONES') {
    //             setFiltrado(dataListaEspecies);
    //         } else {
    //             setDataOpcionesFiltrado(dataOpciones);
    //         }
    //     }
    // }



    return {
        hayFavoritos,
        dataListaEspecies,
        filtrado,
        setFavoritos,
        setFiltrado,
        setDataListaEspecies,
        loadingEspecies,
        noHayData,
        colorMap,
        handleChangeTextoSearchBar,
        setCheckedOperadas,
        checkedOperadas,
        ordenarPorTitulos,
        ordenarPor,
        cargarPreciosSinSocket, 
        errorSocket,
    }
}

