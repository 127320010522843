import { Alert } from "../../interfaces/alertaInterface";
import { BodyDatosPersonales, Titular, TitularConEmpresa } from "../../interfaces/datosPersonalesInterface"
import { Cuenta } from "../../interfaces/getCuentasInterface";
import { Sent } from "../../interfaces/notificacionInterface";
import { Order } from "../../interfaces/ordersInterface";
import { PreguntaFrecuente } from "../../interfaces/pregFrecuentesInterface";

import { Tenencia, Cartera } from "../../interfaces/tenenciaInterface"

export interface UserState {
    userData: TitularConEmpresa | null,
    tenencia: Tenencia | null,
    cartera: Cartera | null,
    porcentajeLocal: number | null;
    porcentajeExterior: number | null;
    orders: Order[];
    settedAlerts: Alert[];
    sentAlerts: Alert[];
    notifications: Sent[];
    pregFrecuentes: PreguntaFrecuente[];
    cuentas: Cuenta[];
}

type UserAction =
    | { type: 'loadUserData'; payload: { titularConEmpresa: TitularConEmpresa | null} }
    | { type: 'loadTenencia'; payload: { orderedData: Tenencia | null } }
    | { type: 'loadCartera'; payload: { data: Cartera | null } }
    | { type: 'loadPorcentajes'; payload: { porcentajeLocal: number | null; porcentajeExterior: number | null; } }
    | { type: 'loadOrders'; payload: { data: Order[] } }
    | { type: 'cancelOrder'; payload: { nroOrden: string } }
    | { type: 'loadSettedAlerts'; payload: { data: Alert[] } }
    | { type: 'loadSentAlerts'; payload: { data: Alert[] } }
    | { type: 'deleteAlert'; payload: { alert: Alert } }
    | { type: 'loadNotifications'; payload: { data: Sent[] } }
    | { type: 'loadPreguntas'; payload: { data: PreguntaFrecuente[] } }
    | { type: 'loadCuentas'; payload: { data: Cuenta[] } }
    ;


export const userReducer = (state: UserState, action: UserAction): UserState => {

    switch (action.type) {

        case 'loadUserData':
            return { ...state, userData: action.payload.titularConEmpresa }

        case 'loadTenencia':
            return { ...state, tenencia: action.payload.orderedData }

        case 'loadCartera':
            return { ...state, cartera: action.payload.data }

        case 'loadPorcentajes':
            return { ...state, porcentajeLocal: action.payload.porcentajeLocal, porcentajeExterior: action.payload.porcentajeExterior }

        case 'loadOrders':
            return { ...state, orders: action.payload.data };

        case 'cancelOrder':
            let ordersArr = state.orders;
            let orderFinded = ordersArr.findIndex(
                o => o.nroOrden === action.payload.nroOrden,
            );
            let orderUpdated = ordersArr[orderFinded];
            orderUpdated.estado = 'CANCELADA';
            return {
                ...state,
                orders: ordersArr,
            };

        case 'loadSettedAlerts':
            return {
                ...state,
                settedAlerts: action.payload.data,
            };

        case 'loadSentAlerts':
            return {
                ...state,
                sentAlerts: action.payload.data,
            };

        case 'deleteAlert':
            const indexDelete = state.settedAlerts
                .map(function (x) {
                    return x;
                })
                .indexOf(action.payload.alert);
            return {
                ...state,
                settedAlerts: state.settedAlerts.filter((_, index) => index !== indexDelete),
            };

        case 'loadNotifications':
            return {
                ...state,
                notifications: action.payload.data,
            };

        case 'loadPreguntas':
            return {
                ...state,
                pregFrecuentes: action.payload.data,
            };
        
        case 'loadCuentas':
            return {
                ...state,
                cuentas: action.payload.data,
            }

        default:
            return state
    }
}