import { Container, Texto, Titulo } from './elements'

interface Props {
    titulo: string;
    texto: string | null | undefined;
    negrita?: boolean;
}

const CardConfirmar = ({ titulo, texto, negrita }: Props) => {
    return (
        <Container
            style={{
                marginTop: titulo === "Especie" ? "0.5em" : "0.1em"
            }}
        >
            <Titulo
                style={{
                    fontWeight: negrita ? 'bold' : undefined, 
                }}
            >
                {titulo}
            </Titulo>
            <Texto
                style={{
                    fontWeight: negrita ? 'bold' : undefined 
                }}
            >{texto}</Texto>
        </Container>
    )
}

export default CardConfirmar