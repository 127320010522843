import { useContext, useEffect, useState } from 'react'
import { Tenencia, TenenciaMercadoLocal, TenenciasSeparadas } from '../interfaces/tenenciaInterface'
import { AuthContext } from '../context/authContext/AuthContext';
import { getHorarioBolsa, getNombreEspecies } from '../api/mercadoApi';
import { TipoAccount, TipoMercado, TipoNavigate } from '../enums/enums';
import { UserContext } from '../context/userContext/UserContext';
import { MercadoContext } from '../context/mercadoContext/MercadoContext';
import { NavigationContext } from '../context/navigationContext/NavigationContext';
import { getTenencia, getUserCuentas, getUserData } from '../api/userApi';
import { DatosClaveEspecie } from '../interfaces/nombreEspeciesInterfaces';
import { formatearAccountSearched } from '../helpers/formatearAccountSearched';

const useTenenciasHook = () => {

    const { setToken, accountType, accountSearched } = useContext(AuthContext);

    const { setUserData, setTenencia, setCartera, setUserCuentas } = useContext(UserContext);
    const { onChangeHorariosExterior, onChangeHorariosLocal, dataLocal } = useContext(MercadoContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [errorTenencia, setErrorTenencia] = useState<boolean>(false);
    const { setSelectedNavigate } = useContext(NavigationContext);



    const [tenenciasSeparadas, setTenenciasSeparadas] = useState<TenenciasSeparadas>({
        localRentaVariable: {
            tenencia: [],
            subtotalRentaVariable: 0,
            subtotalPorcentajeTenencia: 0,
        },
        localRentaFija: {
            tenencia: [],
            subtotalRentaFija: 0,
            subtotalPorcentajeTenencia: 0,
        },
        exterior: [],
        dolares: {
            tenencia: [],
            subtotalDolares: 0,
            subtotalPorcentajeTenencia: 0,
        },
    })

    const [loadingTenenciasSeparadas, setLoadingTenenciaSeparadas] = useState(true);

    useEffect(() => {
        const refresh = async () => {
            try {
                setErrorTenencia(false)
                setLoading(true);
                setSelectedNavigate(TipoNavigate.Home)
                const tokenStorage = localStorage.getItem("token");
                const accountTypeStorage = localStorage.getItem("accountType");
                tokenStorage && setToken(tokenStorage);
                if (tokenStorage) {
                    try {
                        const horariosBolsa = await getHorarioBolsa(tokenStorage);
                        onChangeHorariosLocal(
                            horariosBolsa.data.body[0].opening,
                            horariosBolsa.data.body[0].closing,
                        );
                        onChangeHorariosExterior(
                            horariosBolsa.data.body[1].opening,
                            horariosBolsa.data.body[1].closing,
                        );
                        let respUserData 
                        let tenencia
                        let getCuentas
                        if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){      
                            const accountFormateada = formatearAccountSearched(accountSearched)
                            respUserData = await getUserData(tokenStorage, accountFormateada);
                            tenencia = await getTenencia(tokenStorage, accountFormateada);
                            getCuentas = await getUserCuentas(tokenStorage, accountFormateada);
                        } else {
                            respUserData = await getUserData(tokenStorage);
                            tenencia = await getTenencia(tokenStorage);
                            getCuentas = await getUserCuentas(tokenStorage);
                        }
                        setUserData(respUserData?.data?.body?.titular);
                        setTenencia(tenencia?.data?.body);
                        ordenarTenencias(tenencia?.data?.body)
                        setCartera(tenencia?.data?.body?.cartera);
                        setUserCuentas(getCuentas?.data?.body?.cuentas);
                    } catch (error) {
                        setErrorTenencia(true);
                        console.log(error);
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
        refresh();

    }, [accountSearched])

    const ordenarTenencias = async (tenencia: Tenencia) => {
        setLoadingTenenciaSeparadas(true)
        let arrayBonos: DatosClaveEspecie[] = [];
        if (dataLocal) {
                arrayBonos = dataLocal.filter((r) => {
                    return r.tipo === 'bono'
                })
        } else {
            try {
                const resp = await getNombreEspecies(TipoMercado.Local)
                arrayBonos = resp.data.body.data.body.filter((r) => {
                    return r.tipo === 'bono'
                })
            } catch (error) {
                console.log(error);
                
            }
        }

        if (tenencia) {
            const tenenciaFija: TenenciaMercadoLocal[] = [];
            const tenenciaVariable: TenenciaMercadoLocal[] = [];
            const tenenciaDol: TenenciaMercadoLocal[] = [];
            let subtotalFija: number = 0;
            let subtotalVariable: number = 0;
            let subtotalDol: number = 0;
            let subtotalPorcentajeTenenciaFija: number = 0;
            let subtotalPorcentajeTenenciaVariable: number = 0;
            let subtotalPorcentajeTenenciaDolares: number = 0;

            tenencia.tenenciaMercadoLocal.forEach(t => {

                // agrego el porcentaje de la tenenciaen cada especie
                let porcentaje = tenencia.saldo.pesos < 0
                    ? (Number(t.importe) * 100) / (tenencia.cartera.actualWallet.pesos - tenencia.saldo.pesos)
                    : (Number(t.importe) * 100) / tenencia.cartera.actualWallet.pesos
                t.porcentajeTenencia = porcentaje

                // ordeno las especies por renta fija, variable y dolar
                const esBono = arrayBonos.find(b => b.especie === t.ticker)
                if (esBono) {
                    tenenciaFija.push(t);
                    subtotalFija += Number(t.importe)
                    subtotalPorcentajeTenenciaFija += t.porcentajeTenencia;
                } else if (t.nombreEspecie.toLocaleLowerCase().includes('dolares')) {
                    tenenciaDol.push(t)
                    subtotalDol += Number(t.importe)
                    subtotalPorcentajeTenenciaDolares += t.porcentajeTenencia;
                } else {
                    tenenciaVariable.push(t)
                    subtotalVariable += Number(t.importe)
                    subtotalPorcentajeTenenciaVariable += t.porcentajeTenencia
                }
            })

            //seteo las especies
            setTenenciasSeparadas(prevState => ({
                ...prevState,
                localRentaVariable: {
                    ...prevState.localRentaVariable,
                    tenencia: tenenciaVariable,
                    subtotalRentaVariable: subtotalVariable,
                    subtotalPorcentajeTenencia: subtotalPorcentajeTenenciaVariable,
                },
                localRentaFija: {
                    ...prevState.localRentaFija,
                    tenencia: tenenciaFija,
                    subtotalRentaFija: subtotalFija,
                    subtotalPorcentajeTenencia: subtotalPorcentajeTenenciaFija,
                },
                exterior: [...tenencia.tenenciaMercadoExterior],
                dolares: {
                    ...prevState.dolares,
                    tenencia: tenenciaDol,
                    subtotalDolares: subtotalDol,
                    subtotalPorcentajeTenencia: subtotalPorcentajeTenenciaDolares,
                },
            }));
            }
        setLoadingTenenciaSeparadas(false)
    }


    return {
        tenenciasSeparadas,
        loadingTenenciasSeparadas,
        loading,
        errorTenencia,
    }
}

export default useTenenciasHook