export interface RespTenencia {
  error: boolean;
  status: number;
  body: Tenencia;
}

export interface Tenencia {
  tenenciaMercadoLocal: TenenciaMercadoLocal[];
  tenenciaMercadoExterior: TenenciaMercadoExterior[];
  saldo: Saldo;
  fechaTenencia: string;
  cartera: Cartera;
  variacionMercadoExterior: number | null;
  variacionMercadoLocal: number | null;
  variacionPorcentajeExterior: number | null;
  variacionPorcentajeLocal: number | null;
  pedidosDolarRenta: DolarRenta[];
}

export interface Cartera {
  actualWallet: Saldo;
  previousWallet: Saldo | undefined | null;
}

export interface DolarRenta {
  especie: string,
  estado: string,
}

export interface Saldo {
  pesos: number;
  dolarExterior: number;
  dolarBillete: number;
  fecha?: string;
  saldoCIPesos: string;
  saldo24HsPesos: string;
  saldo24HsDolarBi?: number;
  saldoCIDolarBi?:  number;
}

export interface TenenciaMercadoExterior {
  ticker: string;
  costoCompra: number;
  precioActual: number;
  variacionCosto: number;
  cantidad: number;
  variacionDia: number;
  nombreEspecie: string;
  importe: number;
}

export interface TenenciaMercadoLocal {
  ticker: string;
  costoCompra: number;
  precioActual: number;
  variacionCosto: number | null;
  cantidad: number;
  variacionDia: number;
  nombreEspecie: string;
  importe: string;
  porcentajeTenencia: number;
}

export interface TenenciasSeparadas {
  localRentaVariable: {
    tenencia: TenenciaMercadoLocal[],
    subtotalRentaVariable: number,
    subtotalPorcentajeTenencia: number,
  }
  localRentaFija: {
    tenencia: TenenciaMercadoLocal[],
    subtotalRentaFija: number,
    subtotalPorcentajeTenencia: number,
  }
  exterior: TenenciaMercadoExterior[],
  dolares: {
    tenencia: TenenciaMercadoLocal[],
    subtotalDolares: number,
    subtotalPorcentajeTenencia: number,
  }
}

export interface EmptyBody { }

export function isTenencia(obj: any): obj is Tenencia {
  return (
    typeof obj === 'object' &&
    'tenenciaMercadoLocal' in obj &&
    'tenenciaMercadoExterior' in obj &&
    'saldo' in obj &&
    'fechaTenencia' in obj &&
    'cartera' in obj
  );
}