import React, { useContext } from 'react'
import { Container, ContainerSecundario, ContainerTitulos, ContainerTitulosExterior, TextoTitulos } from './elements'
import { TenenciaMercadoExterior } from '../../../interfaces/tenenciaInterface'
import { CircularProgress } from '@mui/material';
import { ThemeContext } from 'styled-components';
import DetalleRentasExterior from '../DetalleRentas/DetalleRentasExterior';

interface Props {
    tenenciaExterior: TenenciaMercadoExterior[];
    loading: boolean;
}

const InfoDetallesHomeExterior = ({ tenenciaExterior, loading }: Props) => {

    const { loadingColor } = useContext(ThemeContext);

    return (
        <Container>
            <ContainerSecundario>
                <ContainerTitulosExterior>
                    <TextoTitulos style={{ textAlign: 'start', marginLeft: '1em' }}>Especie</TextoTitulos>
                    <TextoTitulos style={{ textAlign: 'end' }}>% variación actual</TextoTitulos>
                    {/* <TextoTitulos style={{ textAlign: 'end' }}>% variación histórica</TextoTitulos> */}
                    {/* <TextoTitulos style={{ textAlign: 'end' }}>Precio histórico</TextoTitulos> */}
                    <TextoTitulos style={{ textAlign: 'end' }}>Cantidad</TextoTitulos>
                    <TextoTitulos style={{ textAlign: 'end', }}>Precio</TextoTitulos>
                    <TextoTitulos style={{ textAlign: 'end', marginRight: '1em' }}>Importe</TextoTitulos>
                </ContainerTitulosExterior>
            </ContainerSecundario>

            {loading ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                    <CircularProgress style={{ color: loadingColor }} />
                </div>
                :
                    <DetalleRentasExterior
                        tenencia={tenenciaExterior}
                    />
            }
        </Container>
    )
}

export default InfoDetallesHomeExterior