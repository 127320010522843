import React, { useContext, useEffect, useState } from "react";
import { Order } from "../interfaces/ordersInterface";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { TipoAccount, TipoMercado } from "../enums/enums";
import { getOrders, getTenencia } from "../api/userApi";
import { UserContext } from "../context/userContext/UserContext";
import { AuthContext } from "../context/authContext/AuthContext";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";

const useOrdenesDelDiaHook = () => {

    const [ordenesOrdenadasFiltradas, setOrdenesOrdenadasFiltradas] = useState<Order[]>([])
    const [loadingRefresher, setLoadingRefresher] = useState(false);
    const { tipoMercado } = useContext(MercadoContext);

    const {setTenencia, orders, setOrders} = useContext(UserContext);

    const { accountSearched, accountType } = useContext(AuthContext)

    useEffect(() => {
        reloadOrders()
    }, [accountSearched])

    useEffect(() => {
        const interval = setInterval(reloadOrders, 60000);
        return () => clearInterval(interval)
    }, [accountSearched])

    useEffect(() => {
        const CompararEstados = (a: Order, b: Order) => {
            const ordenEspecifico = ["Recibida", "Pendiente Operador", "Cumplida", "Anulada"];
            return ordenEspecifico.indexOf(a.estado) - ordenEspecifico.indexOf(b.estado);
        }
        let ordenesSort
        if (orders) {
            ordenesSort = orders.sort(CompararEstados);
        }
        filtrarPorMercado(ordenesSort)
    }, [orders, tipoMercado]);

    const refrescarTenencia = async () => {
        setLoadingRefresher(true)
        const tokenStorage = localStorage.getItem("token");
        const accountTypeStorage = localStorage.getItem("accountType");
        if (tokenStorage){
            try {
                let tenencia 
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
                    tenencia = await getTenencia(tokenStorage, formatearAccountSearched(accountSearched));
                } else {
                    tenencia = await getTenencia(tokenStorage);
                }
                setTenencia(tenencia.data.body);
            } catch (error) {
                console.log(error);
            } finally { 
                setLoadingRefresher(false);
            }
        }
    }

    const reloadOrders = async () => {
        const accountTypeStorage = localStorage.getItem("accountType")
        const tokenStorage = localStorage.getItem("token")
        if (tokenStorage) {
            try {
                let resp
                if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
                    resp = await getOrders(tokenStorage, formatearAccountSearched(accountSearched));
                } else {
                    resp = await getOrders(tokenStorage);
                }
                setOrders(resp.data.body.orders);
            } catch (error) {
                console.log(error);
            }
        }
    }

    function filtrarPorMercado(ordenesSort: Order[] | undefined) {
        let ordenesPorMercado;
        if (ordenesSort) {
            if (tipoMercado === TipoMercado.Exterior) {
                ordenesPorMercado = ordenesSort.filter(e => esExterior(e.especie));
            } else {
                ordenesPorMercado = ordenesSort.filter(e => !esExterior(e.especie));
            }
            setOrdenesOrdenadasFiltradas(ordenesPorMercado);
        }
    }
    function esExterior(nombre: string): boolean {
        return nombre.endsWith('_US');
    }

    function formatEstado(estado: string) {
        let formatValue;
        switch (estado) {
            case 'Pendiente Operador':
                formatValue = 'E/P';
                break;
            case 'Recibida':
                formatValue = 'E/P';
                break;
            case 'Anulada':
                formatValue = 'C';
                break;
            case 'CANCELADA':
                formatValue = 'C';
                break;
            case 'Cumplida':
                formatValue = 'T';
                break;
            default:
                formatValue = estado.toUpperCase();
                break;
        }
        return formatValue;
    }


    return {
        ordenesOrdenadasFiltradas,
        formatEstado,
        loadingRefresher,
        refrescarTenencia,
    };
};

export default useOrdenesDelDiaHook;
