import { useContext, useEffect, useState } from "react";
import { getOrders, getTenencia } from "../api/userApi";
import {
  TenenciaMercadoExterior,
  TenenciaMercadoLocal,
} from "../interfaces/tenenciaInterface";
import { MercadoContext } from "../context/mercadoContext/MercadoContext";
import { TipoAccount, TipoMercado } from "../enums/enums";
import { UserContext } from "../context/userContext/UserContext";
import { AuthContext } from "../context/authContext/AuthContext";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";
import { Order } from "../interfaces/ordersInterface";

const useInfoMercadoHook = () => {
  const [tenenciaHook, setTenenciaHook] = useState<
    TenenciaMercadoLocal[] | TenenciaMercadoExterior[] | undefined
  >();
  const { tipoMercado } = useContext(MercadoContext);
  const { tenencia, setTenencia, setCartera, loadingTenencia, setLoadingTenencia, orders, setOrders } = useContext(UserContext);
  const { accountType, accountSearched } = useContext(AuthContext);

  const [errorOrdersTenencia, setErrorOrdersTenencia] = useState({errorTenencia: false, errorOrders: false});

  const [ordenesOrdenadasFiltradas, setOrdenesOrdenadasFiltradas] = useState<Order[]>([])
  const [loadingRefresher, setLoadingRefresher] = useState(false);


  useEffect(() => {
      reloadOrders()
  }, [accountSearched])

  useEffect(() => {
      const interval = setInterval(reloadOrders, 60000);
      return () => clearInterval(interval)
  }, [accountSearched])

  useEffect(() => {
      const CompararEstados = (a: Order, b: Order) => {
          const ordenEspecifico = ["Recibida", "Pendiente Operador", "Cumplida", "Anulada"];
          return ordenEspecifico.indexOf(a.estado) - ordenEspecifico.indexOf(b.estado);
      }
      let ordenesSort
      if (orders) {
          ordenesSort = orders.sort(CompararEstados);
      }
      filtrarPorMercado(ordenesSort)
  }, [orders, tipoMercado]);



  useEffect(() => {
    cargarInfo();
  }, [accountSearched]);


  useEffect(() => {
    tipoMercado === TipoMercado.Local
      ? setTenenciaHook(tenencia?.tenenciaMercadoLocal)
      : setTenenciaHook(tenencia?.tenenciaMercadoExterior);
  }, [tipoMercado]);

  const cargarInfo = async () => {
    try {
      setLoadingTenencia(true)
      setErrorOrdersTenencia({...errorOrdersTenencia, errorTenencia: false})
      const tokenStorage = localStorage.getItem("token");
      const accountTypeStorage = localStorage.getItem("accountType");
      if (tokenStorage) {
        let resp
        if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
          resp = await getTenencia(tokenStorage, formatearAccountSearched(accountSearched));
        } else {
          resp = await getTenencia(tokenStorage);
        }
        setTenencia(resp.data.body)
        setCartera(resp?.data?.body?.cartera);
        tipoMercado === TipoMercado.Local
          ? setTenenciaHook(
              resp.data.body.tenenciaMercadoLocal.sort((a, b) => {
                if (a.ticker < b.ticker) return -1;
                if (a.ticker > b.ticker) return 1;
                return 0;
              })
            )
          : setTenenciaHook(
              resp.data.body.tenenciaMercadoExterior.sort((a, b) => {
                if (a.ticker < b.ticker) return -1;
                if (a.ticker > b.ticker) return 1;
                return 0;
              })
            );
      }
    } catch (error) {
      setErrorOrdersTenencia({...errorOrdersTenencia, errorTenencia: true})

      console.log(error);
    } finally {
      setLoadingTenencia(false)
    }
  };

  const refrescarTenencia = async () => {
    setLoadingRefresher(true)
    setTenencia(null)
    setErrorOrdersTenencia({...errorOrdersTenencia, errorTenencia: false})
    const tokenStorage = localStorage.getItem("token");
    const accountTypeStorage = localStorage.getItem("accountType");
    if (tokenStorage){
        try {
            let tenencia 
            if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
                tenencia = await getTenencia(tokenStorage, formatearAccountSearched(accountSearched));
            } else {
                tenencia = await getTenencia(tokenStorage);
            }
            setTenencia(tenencia.data.body);
        } catch (error) {
            setErrorOrdersTenencia({...errorOrdersTenencia, errorTenencia: true})
            console.log(error);
        } finally { 
            setLoadingRefresher(false);
        }
    }
}

const reloadOrders = async () => {
    const accountTypeStorage = localStorage.getItem("accountType")
    const tokenStorage = localStorage.getItem("token")
    setErrorOrdersTenencia({...errorOrdersTenencia, errorOrders: false})
    if (tokenStorage) {
        try {
            let resp
            if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin){
                resp = await getOrders(tokenStorage, formatearAccountSearched(accountSearched));
            } else {
                resp = await getOrders(tokenStorage);
            }
            let orders = resp.data.body.orders.concat(resp.data.body.standingOrders)
            setOrders(orders);
        } catch (error) {
            setErrorOrdersTenencia({...errorOrdersTenencia, errorOrders: true})
            console.log(error);
        }
    }
}

function filtrarPorMercado(ordenesSort: Order[] | undefined) {
    let ordenesPorMercado;
    if (ordenesSort) {
        if (tipoMercado === TipoMercado.Exterior) {
            ordenesPorMercado = ordenesSort.filter(e => esExterior(e.especie));
        } else {
            ordenesPorMercado = ordenesSort.filter(e => !esExterior(e.especie));
        }
        setOrdenesOrdenadasFiltradas(ordenesPorMercado);
    }
}
function esExterior(nombre: string): boolean {
    return nombre.endsWith('_US');
}

function formatEstado(estado: string) {
    let formatValue;
    switch (estado) {
        case 'Pendiente Operador':
            formatValue = 'E/P';
            break;
        case 'Recibida':
            formatValue = 'E/P';
            break;
        case 'Anulada':
            formatValue = 'C';
            break;
        case 'CANCELADA':
            formatValue = 'C';
            break;
        case 'Cumplida':
            formatValue = 'T';
            break;
        default:
            formatValue = estado.toUpperCase();
            break;
    }
    return formatValue;
}

  return {
    tenenciaHook,
    loadingTenencia,
    errorOrdersTenencia,
    refrescarTenencia,
    formatEstado,
    ordenesOrdenadasFiltradas,
    loadingRefresher,
  };
};

export default useInfoMercadoHook;
