import { useContext, useEffect } from 'react'
import { Container, ContainerDerecha, ContainerIzquierda, ContainerSearchBarOperadas, SecondContainer } from './elements'
import { ResponsiveHeader } from '../../components/HeadersComponents/ResponsiveHeader'
import SearchBar from '../../components/mercadoComponents/SearchBar/SearchBar'
import { useEspeciesHook } from '../../hooks/useEspeciesHook'
import { MercadoContext } from '../../context/mercadoContext/MercadoContext'
import OperarNuevo from '../OperarNuevo/OperarNuevo'
import OfertasNuevo from '../../components/mercadoNuevoComponents/OfertasNuevo/OfertasNuevo'
import { DetalleContext } from '../../context/detalleEspecieContext/DetalleContext'
import CardInfoMercadoNuevo from '../../components/mercadoNuevoComponents/CardInfoMercadoNuevo/CardInfoMercadoNuevo'
import { OperarContext } from '../../context/operarContext/OperarContext'
import { formatCurrency } from '../../helpers/formatCurrency'
import BotonesPanelesNuevo from '../../components/mercadoNuevoComponents/BotonesPanelesNuevo/BotonesPanelesNuevo'
import CardContainerEspecies from '../../components/mercadoNuevoComponents/CardContainerEspecies/CardContainerEspecies'
import CheckboxOperadas from '../../components/mercadoNuevoComponents/CheckboxOperadas/CheckboxOperadas'
import { TipoInfoMercado, TipoNavigate } from '../../enums/enums'
import useMercadoNuevoHook from '../../hooks/useMercadoNuevoHook'
import { NavigationContext } from '../../context/navigationContext/NavigationContext'
import useInfoMercadoHook from '../../hooks/useInfoMercadoHook'
import { EspeciesFavoritasContext } from '../../context/especiesFavoritasContext/EspeciesFavoritasContext'

const MercadoNuevo = () => {

    const { setActualMenuEspecies, actualMenuEspecies, listaMenuEspecies } = useMercadoNuevoHook();
    const {
        filtrado,
        loadingEspecies,
        noHayData,
        colorMap,
        handleChangeTextoSearchBar,
        setCheckedOperadas,
        checkedOperadas,
        ordenarPorTitulos,
        ordenarPor,
        cargarPreciosSinSocket,
        errorSocket,
        envioUndefinedRef,
    } = useEspeciesHook(actualMenuEspecies);
    const { tipoMercado } = useContext(MercadoContext);
    const { ofertas,
        loadingOfertas,
        setOfertas,
        getOfertasEspecie,
        noHayOfertas,
        setNombreEspecieOfertas,
        nombreEspecieOfertas,
        getOfertasEspeciesNuevo
    } = useContext(DetalleContext);
    const { setTextoTitulos, onChangePrecio, onChangeCantidad, deskOperar } = useContext(OperarContext);
    const { setSelectedNavigate } = useContext(NavigationContext);
    const { setEspeciesFromApi } = useContext(EspeciesFavoritasContext);
    const {
        tenenciaHook,
        loadingTenencia,
        errorOrdersTenencia,
        ordenesOrdenadasFiltradas,
        formatEstado,
        loadingRefresher,
        refrescarTenencia
    } = useInfoMercadoHook()

    const titulosResultadoDelDia = [
        'Especie',
        'Cantidad',
        'Precio ant.',
        'Ult. Precio',
        '% variación',
    ];

    const titulosOrdenesDelDia = [
        'Especie',
        'Tipo',
        'Cantidad',
        'Precio',
        'Estado',
    ];

    const cargarDatosOperar = (simbolo: string, precio: string | number, cantidad: string | number, especie?: string) => {
        if (!deskOperar) {
            if (especie) {
                // getOfertasEspecie(especie);
                getOfertasEspeciesNuevo(simbolo);
                setNombreEspecieOfertas(simbolo);
                setTextoTitulos(simbolo);
                onChangePrecio(formatCurrency(precio));
                onChangeCantidad(String(cantidad));
            } else {
                setTextoTitulos(simbolo);
                onChangePrecio(formatCurrency(precio));
                onChangeCantidad(String(cantidad));
            }
        }
    }

    useEffect(() => {
        setEspeciesFromApi()
        setSelectedNavigate(TipoNavigate.Mercado)
        setNombreEspecieOfertas('');
        setOfertas(undefined);
    }, [])

    return (
        <Container>
            <ResponsiveHeader
                title='Mercado'
                showSwitch={true}
            />
            <SecondContainer>
                <ContainerIzquierda>
                    <ContainerSearchBarOperadas>
                        <SearchBar
                            textoSearchBar={handleChangeTextoSearchBar}
                            actualMenu={actualMenuEspecies}
                        />
                        {!errorSocket
                            ? <CheckboxOperadas
                                setCheckedOperadas={setCheckedOperadas}
                                checkedOperadas={checkedOperadas}
                            />
                            : null
                        }
                    </ContainerSearchBarOperadas>
                    <BotonesPanelesNuevo
                        listaBotones={listaMenuEspecies}
                        botonActual={actualMenuEspecies}
                        setBotonActual={setActualMenuEspecies}
                    />
                    <CardContainerEspecies
                        tipoMercado={tipoMercado}
                        filtrado={filtrado}
                        loadingEspecies={loadingEspecies}
                        actualMenuEspecies={actualMenuEspecies}
                        cargarDatosOperar={cargarDatosOperar}
                        noHayData={noHayData}
                        colorMap={colorMap}
                        ordenarPorTitulos={ordenarPorTitulos}
                        ordenarPor={ordenarPor}
                        cargarPreciosSinSocket={cargarPreciosSinSocket}
                        filtraOpciones={envioUndefinedRef}
                    />
                </ContainerIzquierda>
                <ContainerDerecha style={{ flexGrow: 1 }}>
                    <OperarNuevo
                    />
                    <OfertasNuevo
                        ofertas={ofertas}
                        nombreEspecie={nombreEspecieOfertas}
                        loadingOfertas={loadingOfertas}
                        setPrecioOperar={onChangePrecio}
                        setCantidadOperar={onChangeCantidad}
                        confirmarDatos={deskOperar}
                        setTextoTitulos={setTextoTitulos}
                        tipoMercado={tipoMercado}
                        noHayOfertas={noHayOfertas}
                    />
                    <CardInfoMercadoNuevo
                        titulo={TipoInfoMercado.Resultados}
                        subtitulos={titulosResultadoDelDia}
                        confirmarDatos={deskOperar}
                        cargarDatosOperar={cargarDatosOperar}
                        tenenciaHook={tenenciaHook}
                        loadingTenencia={loadingTenencia}
                        ordenesOrdenadasFiltradas={ordenesOrdenadasFiltradas}
                        formatEstado={formatEstado}
                        loadingRefresher={loadingRefresher}
                        refrescarTenencia={refrescarTenencia}
                        errorTenencia={errorOrdersTenencia.errorTenencia}
                    />
                    <CardInfoMercadoNuevo
                        titulo={TipoInfoMercado.Ordenes}
                        subtitulos={titulosOrdenesDelDia}
                        tenenciaHook={tenenciaHook}
                        loadingTenencia={loadingTenencia}
                        ordenesOrdenadasFiltradas={ordenesOrdenadasFiltradas}
                        formatEstado={formatEstado}
                        loadingRefresher={loadingRefresher}
                        refrescarTenencia={refrescarTenencia}
                        errorOrders={errorOrdersTenencia.errorOrders}
                    />
                </ContainerDerecha>
            </SecondContainer>
        </Container>
    )
}

export default MercadoNuevo
