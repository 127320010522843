import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { BotonBuscar, BotonDescargar, Container, ContainerTextFields } from './elements';
import { MenuItem, TextField } from '@mui/material';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Moneda } from '../../../interfaces/monedasMovimientos';
import AutocompleteEspecies from '../AutocompleteEspecies/AutocompleteEspecies';
import CalendarioFechas from '../../../elements/CalendarioFechas';
import { TiposObjeto } from '../../../interfaces/MovimientosInterface';
import { InlineIcon } from '@iconify/react';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

interface Props {
    fechaDesde: dayjs.Dayjs | null;
    fechaHasta: dayjs.Dayjs | null;
    setFechaDesde: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
    setFechaHasta: React.Dispatch<React.SetStateAction<dayjs.Dayjs | null>>;
    buscarMovimientos: () => void;
    monedas?: Moneda[];
    tipos?: TiposObjeto;
    handleMenus?: (value: Moneda | string, menu: string) => void;
    especies: string[];
    setEspecieSeleccionada: React.Dispatch<React.SetStateAction<string>>;
    exportarAExcel?: () => void;
}

const HeaderMovimientos = ({
    fechaDesde,
    fechaHasta,
    setFechaDesde,
    setFechaHasta,
    buscarMovimientos,
    monedas,
    tipos,
    handleMenus,
    especies,
    setEspecieSeleccionada,
    exportarAExcel,
}: Props) => {

    const { primaryColor, primaryBackgroundColor, alternativeBorderColor } = useContext(ThemeContext)

    const { width } = useWindowDimensions()

    const propsSelect = {
        "& .MuiSvgIcon-root": {
            color: primaryColor,
        }
    }

    const propsInput = {
        '& .MuiOutlinedInput-root': {
            // height: '2em',
            '& fieldset': {
                borderColor: primaryColor, // Border color when not focused
            },
            '&:hover fieldset': {
                borderColor: primaryColor, // Border color on hover
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${primaryColor}`
            },
        },
        '& .MuiInputBase-input': {
            color: primaryColor, // Text color
        },
        '& .MuiInputLabel-outlined': {
            color: primaryColor,
            '&.Mui-focused': {
                color: primaryColor,
            }
        },
    }

    const propsMenu = {
        '& .MuiPaper-root': {
            backgroundColor: primaryBackgroundColor, // Background color of the menu
        },
        '& .MuiMenuItem-root': {
            color: primaryColor, // Text color of the menu items
            '&.Mui-selected': {
                backgroundColor: alternativeBorderColor, // Background color when selected
                color: primaryColor, // Text color when selected
            }
        }
    }

    return (
        <Container>
            <ContainerTextFields>
                {
                    monedas &&
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Moneda"
                        size='small'
                        defaultValue={'Pesos'}
                        sx={{
                            width: width > 1200 ? '9em' : '7em',
                            ...propsInput
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowRightRoundedIcon,
                            sx: propsSelect,
                            MenuProps: {
                                sx: propsMenu,
                            }
                        }}
                    >
                        {
                            monedas.map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => { handleMenus && handleMenus(option, 'Moneda') }}
                                >
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                }
                <CalendarioFechas
                    fechaPrimaria={fechaDesde}
                    fechaSecundariaDesde={fechaHasta}
                    seteoFecha={setFechaDesde}
                    tituloCalendario='Fecha desde'
                />
                <CalendarioFechas
                    fechaPrimaria={fechaHasta}
                    fechaSecundariaHasta={fechaDesde}
                    seteoFecha={setFechaHasta}
                    tituloCalendario='Fecha hasta'
                />
                {
                    tipos &&
                    <TextField
                        id="outlined-select-currency"
                        select
                        label="Tipo"
                        defaultValue={'Todos'}
                        size='small'
                        sx={{
                            width: width > 1200 ? '9em' : '7em',
                            ...propsInput
                        }}
                        SelectProps={{
                            IconComponent: KeyboardArrowRightRoundedIcon,
                            sx: propsSelect,
                            MenuProps: {
                                sx: propsMenu,
                            }
                        }}
                    >
                        {
                            Object.keys(tipos).map((option) => (
                                <MenuItem
                                    key={option}
                                    value={option}
                                    onClick={() => { handleMenus && handleMenus(option, 'Tipo') }}
                                >
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                }
                <AutocompleteEspecies
                    data={especies}
                    setDataSeleccionada={setEspecieSeleccionada}
                    title="Especies"
                />
                <BotonBuscar
                    onClick={buscarMovimientos}
                >BUSCAR</BotonBuscar>
            </ContainerTextFields>

            <div>
                <BotonDescargar onClick={exportarAExcel}>
                    <InlineIcon
                        icon="pajamas:export"
                        style={styles.exportIcon}
                    />
                    DESCARGAR
                </BotonDescargar>
            </div>

        </Container>
    )
}

export default HeaderMovimientos

const styles = {
    exportIcon: {
        // width: "2em",
        // height: "1.2em",
        marginRight: "0.3em",
    },
}