import { useContext, useEffect, useState } from 'react'
import { MercadoContext } from '../context/mercadoContext/MercadoContext'
import { DataEspecieReducida } from '../interfaces/dataCardsHome';
import { TipoMercado, TipoNavigate } from '../enums/enums';
import { getDataActivasCincoExt, getDataDolar, getDataMercadoLocal, getDataMercadoLocalCard, getDataMercadoUSA, getDataTopCincoExt, getEspecieOpciones } from '../api/mercadoApi';
import { AuthContext } from '../context/authContext/AuthContext';
import { arrMenuListMercadoInternacionalSoloTitulos, arrMenuListMercadoLocalSoloTitulos } from '../helpers/arrayEspecies';
import { NavigationContext } from '../context/navigationContext/NavigationContext';
import useWindowDimensions from './useWindowDimensions';
import { getUserData } from '../api/userApi';
import { UserContext } from '../context/userContext/UserContext';

export const useMercadoHook = () => {

    const { tipoMercado, especieOpciones, setLoading, setEspecieOpciones } = useContext(MercadoContext);
    const { setUserData } = useContext(UserContext);
    const [dataMercadoCard, setDataMercadoCard] = useState<DataEspecieReducida[]>([]);
    const [dataDolar, setDataDolar] = useState<DataEspecieReducida[]>([]);
    const [dataMercadoLocalEspecies, setDataMercadoLocalEspecies] = useState<DataEspecieReducida[]>([])
    const [dataTopCincoExt, setDataTopCincoExt] = useState<DataEspecieReducida[]>([])
    const [dataAvtivasCincoExt, setDataAvtivasCincoExt] = useState<DataEspecieReducida[]>([])

    const [listaMenuEspecies, setListaMenuEspecies] = useState(
        tipoMercado === TipoMercado.Local
            ? arrMenuListMercadoLocalSoloTitulos
            : arrMenuListMercadoInternacionalSoloTitulos,
    );
    const [actualMenuEspecies, setActualMenuEspecies] = useState('FAVORITOS');
    const [actualMenuOpciones, setActualMenuOpciones] = useState(especieOpciones ? especieOpciones[0].especie : '',);

    const { tokenPublico, setToken, setTokenPublico } = useContext(AuthContext);

    const { setSelectedNavigate } = useContext(NavigationContext);

    const { width } = useWindowDimensions();

    // carga todos los datos necesarios cuando hacen refresh de la pagina
    useEffect(() => {
        const refresh = async () => {
            try {
                setSelectedNavigate(TipoNavigate.Mercado)
                const tokenStorage = localStorage.getItem("token");
                const tokenPublicoStorage = localStorage.getItem("tokenPublico");
                tokenStorage && setToken(tokenStorage);
                tokenPublicoStorage && setTokenPublico(tokenPublicoStorage);
                if (tokenPublicoStorage && tokenStorage) {
                    const resp = await getEspecieOpciones(tokenPublicoStorage);
                    setEspecieOpciones(resp.data.body);
                    if (tipoMercado === TipoMercado.Local) {
                        const resp = await Promise.all([
                            getDataMercadoLocal(tokenPublicoStorage),
                            getDataDolar(tokenPublicoStorage, true),
                            getDataMercadoLocalCard(tokenPublicoStorage),
                            getUserData(tokenStorage),
                            // getOrders(tokenStorage),
                        ]);
                        setDataMercadoCard(resp[0].data.body);
                        setDataDolar(resp[1].data.body);
                        setDataMercadoLocalEspecies(resp[2].data.body);
                        setUserData(resp[3].data.body.titular);
                        // setOrders(resp[4].data.body.orders);
                    } else {
                        const resp = await Promise.all([
                            getDataMercadoUSA(tokenPublicoStorage),
                            getDataTopCincoExt(tokenPublicoStorage),
                            getDataActivasCincoExt(tokenPublicoStorage),
                            getUserData(tokenStorage),
                        ]);
                        setDataMercadoCard(resp[0].data.body);
                        setDataTopCincoExt(resp[1].data.body);
                        setDataAvtivasCincoExt(resp[2].data.body);
                        setUserData(resp[3].data.body.titular);
                    }
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        if (dataMercadoCard.length === 0 || dataDolar.length === 0) {
            refresh();
        }
    }, [dataMercadoCard, dataDolar])


    // hace las peticiones de la data de las cards de cada mercado
    async function obtenerDataCards() {
        if (tokenPublico) {
            try {
                setLoading(true)
                if (tipoMercado === TipoMercado.Local) {
                    const resp = await Promise.all([
                        getDataMercadoLocal(tokenPublico),
                        getDataDolar(tokenPublico, true),
                        getDataMercadoLocalCard(tokenPublico),
                    ]);
                    setDataMercadoCard(resp[0].data.body);
                    setDataDolar(resp[1].data.body);
                } else {
                    const resp = await Promise.all([
                        getDataMercadoUSA(tokenPublico),
                        getDataTopCincoExt(tokenPublico),
                        getDataActivasCincoExt(tokenPublico),
                    ]);
                    setDataMercadoCard(resp[0].data.body);
                    setDataTopCincoExt(resp[1].data.body);
                    setDataAvtivasCincoExt(resp[2].data.body);
                }
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
    // setea el menu en Favoritos cuando cambia el mercado
        setActualMenuEspecies('FAVORITOS');
    //setea la data de las cards cuando cambia el mercado
        obtenerDataCards();
    }, [tipoMercado]);


    // Cambia el menu segun lo que este seleccionado o el tipo de mercado
    useEffect(() => {
        setListaMenuEspecies(
            tipoMercado === TipoMercado.Local
                ? arrMenuListMercadoLocalSoloTitulos
                : arrMenuListMercadoInternacionalSoloTitulos,
        );
        width < 992 && limpiarListaMenu();
    }, [actualMenuEspecies, tipoMercado]);



    // Limpia el menu desplegable
    function limpiarListaMenu() {
        let arr =
            tipoMercado === TipoMercado.Local
                ? arrMenuListMercadoLocalSoloTitulos
                : arrMenuListMercadoInternacionalSoloTitulos;
        const indexDelete = arr
            .map(function (x) {
                return x;
            })
            .indexOf(actualMenuEspecies);

        setListaMenuEspecies(itemMenu =>
            itemMenu.filter((_, index) => index !== indexDelete),
        );
    }

    // setea la opcion seleccionada en el menu
    function onClickPopUpMenu(op: string) {
        setActualMenuEspecies(op);
    }

    // setea la opcion seleccionada en el menu de opciones
    function onClickPopUpMenuOpciones(op: string) {
        setActualMenuOpciones(op);
    }

    return {
        dataMercadoCard,
        dataDolar,
        dataMercadoLocalEspecies,
        listaMenuEspecies,
        actualMenuOpciones,
        actualMenuEspecies,
        dataTopCincoExt,
        dataAvtivasCincoExt,
        onClickPopUpMenu,
        onClickPopUpMenuOpciones,
        setActualMenuEspecies,
    }
}

