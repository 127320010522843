import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 1em;
    width: 100%;
    flex-wrap: nowrap;
`

export const ContainerTextFields = styled.div`
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    @media (max-width: 1200px) {
        gap: 0.5em;
    }
`

export const BotonBuscar = styled.button`
    background-color: ${({theme}) => theme.primaryBackgroundColor};
    color: ${({theme}) => theme.primaryColor};
    border: 2px solid ${({theme}) => theme.primaryColor};
    font-size: 15px;
    font-weight: 700;
    border-radius: 5px;
    width: 6em;
    cursor: pointer;
    padding: 0;
    @media (max-width: 1200px) {
        width: 5em;
        font-size: 13px;
    }
`

export const BotonDescargar= styled.button`
    background-color: transparent;
    color: ${({theme}) => theme.primaryColor};
    border: none;
    font-size: 15px;
    border-radius: 5px;
    width: 9em;
    cursor: pointer;
    padding: 0.5em;
`
