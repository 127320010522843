import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { FormAgregarCuentaProps } from "../interfaces/agregarCuentaInterface";
import { UserContext } from "../context/userContext/UserContext";
import { AuthContext } from "../context/authContext/AuthContext";
import { agregarCuenta } from "../api/userApi";
import { useNavigate } from "react-router-dom";
import { TipoAccount } from "../enums/enums";
import { formatearAccountSearched } from "../helpers/formatearAccountSearched";

const useAgregarCuentaHook = () => {
  const { userData } = useContext(UserContext);
  const [form, setForm] = useState<FormAgregarCuentaProps>({
    titular: userData?.nombre ? userData.nombre : "",
    cbu: "",
    nroCuenta: "",
    cuit: "",
    alias: "",
    tipoMoneda: "",
    tipoCuenta: "",
  });
  const { tokenPublico, accountSearched, accountType } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [errorCrearCuenta, setErrorCrearCuenta] = useState(false);
  const [confirmoCuenta, setConfirmoCuenta] = useState(false);
  const [habilitarContinuar, setHabilitarContinuar] = useState(false);
  const [confirmarScreen, setConfirmarScreen] = useState(false);
  const navigate = useNavigate();

  const [error, setError] = useState("");

  const handleChangeInputs = (
    e: ChangeEvent<HTMLInputElement> | string,
    tipo?: string
  ) => {
    if (typeof e === "string") {
      tipo &&
        setForm((prevForm) => ({
          ...prevForm,
          [tipo]: e,
        }));
    } else {
      const { name, value } = e.target;
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const { cbu, nroCuenta, cuit, tipoMoneda, tipoCuenta } = form;
    if (
      cbu.length &&
      nroCuenta.length &&
      cuit.length &&
      tipoMoneda.length &&
      tipoCuenta.length
    ) {
      setHabilitarContinuar(true);
    } else {
      setHabilitarContinuar(false);
    }
  }, [form]);

  function esSoloNumeros(str: string) {
    return /^[0-9]*$/.test(str);
  }

  const validarDatos = () => {
    const { cbu, cuit, nroCuenta } = form;
    if (cbu.length < 22) {
      setError("El cbu debe tener 22 números.");
      return false;
    } else if (cuit.length < 11) {
      setError("El cuit debe tener 11 números.");
      return false;
    } else if (!esSoloNumeros(cuit)) {
      setError("Ingrese el cuit sin guiones ni espacios.");
      return false;
    } else if (!esSoloNumeros(nroCuenta)) {
      setError("Ingrese el número de cuenta sin guiones ni espacios.");
      return false;
    } else if (!esSoloNumeros(cbu)) {
      setError("Ingrese el CBU sin guiones ni espacios.");
      return false;
    }
    setError("");
    return true;
  };

  const cancelar = () => {
    setConfirmarScreen(false);
  };

  const continuar = () => {
    if (validarDatos()) {
      setConfirmarScreen(true);
    }
  };

  useEffect(() => {
    if (confirmoCuenta) {
      confirmarCuenta();
    }
  }, [confirmoCuenta]);

  const close = () => {
    setTimeout(() => {
      navigate('/perfil/mi-billetera')
    }, 3000);
  }

  const confirmarCuenta = async () => {
    setLoading(true);
    const formParaEnviar = {
      token: tokenPublico ? tokenPublico : "",
      moneda: form.tipoMoneda === "PESOS" ? 0 : 1,
      cbu: form.cbu,
      tipoCuenta: form.tipoCuenta === "CAJA DE AHORROS" ? 0 : 1,
      nroCuenta: Number(form.nroCuenta),
      cuit: Number(form.cuit),
      alias: form.alias,
    };
    try {
      if (accountType === TipoAccount.Admin) {
        const accountFormateada = formatearAccountSearched(accountSearched)
        const resp = await agregarCuenta({...formParaEnviar, accountSearched: accountFormateada})
      } else{
        const resp = await agregarCuenta(formParaEnviar)
      }
    } catch (error) {
      console.log(error);
      setErrorCrearCuenta(true);
    } finally {
      setLoading(false);
      close();
    }
  };

  return {
    form,
    handleChangeInputs,
    error,
    habilitarContinuar,
    continuar,
    confirmarScreen,
    cancelar,
    setConfirmoCuenta,
    confirmoCuenta,
    loading,
    errorCrearCuenta,
  };
};

export default useAgregarCuentaHook;
