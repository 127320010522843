import { useContext, useEffect, useState } from 'react'
import { OperarContext } from '../context/operarContext/OperarContext';
import { AuthContext } from '../context/authContext/AuthContext';
import { UserContext } from '../context/userContext/UserContext';
import { TipoAccount, TipoNavigate, TipoOperacion } from '../enums/enums';
import { createOrder, getOrders, sellOrder } from '../api/userApi';
import axios, { AxiosError } from 'axios';
import { useNavigationHook } from './useNavigationHook';
import useWindowDimensions from './useWindowDimensions';
import { addUs } from '../helpers/formatEspeciesExterior';
import { formatearAccountSearched } from '../helpers/formatearAccountSearched';

export const useComprarVenderHook = () => {

    const [confirmo, setConfirmo] = useState(false);
    const [cancelo, setCancelo] = useState(false);
    const [aceptoDDJJ, setAceptoDDJJ] = useState(false);
    const [error, setError] = useState(false);
    const [tituloError, setTituloError] = useState('');
    const [mensajeError, setMensajeError] = useState('');
    const [nroOrden, setNroOrden] = useState('');
    const [loading, setLoading] = useState(false);
    const {
        especieOperada,
        actualOption,
        textosDDJJ,
        cantidad,
        precio,
        onChangeCantidad,
        onChangePrecio,
        onChangeTitulo,
        setDeskOperar,
        onChangeImporte,
        textoTitulos,
    } = useContext(OperarContext);
    const { token, accountType, accountSearched } = useContext(AuthContext);

    const { userData, setOrders } = useContext(UserContext);
    const { handleNavigate } = useNavigationHook();

    const { width } = useWindowDimensions();


    const handleConfirmarOperar = () => {
        setLoading(true)
        setConfirmo(true)
    }

    useEffect(() => {
        if (confirmo) {
            operar();
        } else if (cancelo) {
            close()
        }
    }, [confirmo, cancelo]);


    // ejecuta las peticiones necesarias para operar la especie
    const operar = async () => {
        setLoading(true);
        let success = false;
        let data: any = '';
        const accountTypeStorage = localStorage.getItem("accountType")
        try {
            if (confirmo && userData) {
                if (token) {
                    let accountFormateada
                    if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                        accountFormateada = formatearAccountSearched(accountSearched)

                    }
                    if (actualOption === TipoOperacion.Compra) {
                        if (especieOperada) {
                            const resp = await createOrder(
                                token,
                                userData?.nroCuenta,
                                especieOperada?.especie_completa
                                    ? especieOperada.especie_completa
                                    : especieOperada.especie,
                                especieOperada?.especie,
                                parseInt(cantidad),
                                Number(precio.replace(',', '.')),
                                accountFormateada,
                            );
                            success =
                                resp.data.body.responseServer.Success === 'false' ? false : true;
                            setNroOrden(resp.data.body.responseServer.Orden)
                            data = resp.data.body.responseServer
                        } else {
                            const resp = await createOrder(
                                token,
                                userData?.nroCuenta,
                                addUs(textoTitulos),
                                addUs(textoTitulos),
                                parseInt(cantidad),
                                Number(precio.replace(',', '.')),
                                accountFormateada,
                            );
                            success =
                                resp.data.body.responseServer.Success === 'false' ? false : true;
                            setNroOrden(resp.data.body.responseServer.Orden)
                            data = resp.data.body.responseServer
                        }
                    } else {
                        if (especieOperada) {
                            const resp = await sellOrder(
                                token,
                                userData?.nroCuenta,
                                especieOperada.especie_completa
                                    ? especieOperada.especie_completa
                                    : especieOperada.especie,
                                especieOperada.especie,
                                parseInt(cantidad),
                                Number(precio.replace(',', '.')),
                                accountFormateada,
                            );
                            success = resp.data.body.responseServer.Success === "true" ? true : false
                            setNroOrden(resp.data.body.responseServer.Orden)
                            data = resp.data.body.responseServer
                        } else {
                            const resp = await sellOrder(
                                token,
                                userData?.nroCuenta,
                                addUs(textoTitulos),
                                addUs(textoTitulos),
                                parseInt(cantidad),
                                Number(precio.replace(',', '.')),
                                accountFormateada,
                            );
                            success = resp.data.body.responseServer.Success === "true" ? true : false
                            setNroOrden(resp.data.body.responseServer.Orden)
                            data = resp.data.body.responseServer
                        }
                    }
                    if (success) {
                        setLoading(false);
                        // const respOrders = await getOrders(token);
                        let respOrders
                        if (accountType === TipoAccount.Admin || accountTypeStorage === TipoAccount.Admin) {
                            respOrders = await getOrders(token, formatearAccountSearched(accountSearched));
                        } else {
                            respOrders = await getOrders(token);
                        }
                        setOrders(respOrders.data.body.orders);
                    } else {
                        setLoading(false);
                        setError(true);
                        setTituloError('Orden Rechazada.');
                        setMensajeError(data.Detalle.split(' ').splice(2).join(' '));
                    }
                    setTimeout(() => {
                        close();
                    }, 5000);
                } else {
                    setError(true);
                    setLoading(false);
                    setTimeout(() => {
                        close();
                    }, 4000);
                }
            } else {
                setLoading(false);
                setError(true);
                setTituloError('Se produjo un error al generar la orden.');
                setMensajeError('Comunicate con tu asesor/a.');
                setTimeout(() => {
                    close();
                }, 4000);
            }
        } catch (err: any | AxiosError) {
            setLoading(false);
            if (axios.isAxiosError(err)) {
                console.log('catch', err.response?.data);
                const errAxios = err.response?.data ? err.response.data : null
                setError(true);
                setTituloError('Se produjo un error al generar la orden.');
                setMensajeError(errAxios ? errAxios : 'Comunicate con tu asesor/a.');
                setTimeout(() => {
                    close();
                }, 4000);
            }
            else {
                setError(true);
                console.log(err);
                setTimeout(() => {
                    close();
                }, 4000);
            }
        }
    };


    // limpia todos los inputs una vez finaliza de operar
    function close() {
        setCancelo(false);
        onChangeCantidad('');
        onChangePrecio('');
        onChangeTitulo('');
        onChangeImporte('');
        setError(false);
        if (width > 992) {
            setDeskOperar(false);
        } else {
            handleNavigate(TipoNavigate.Home);
        }
    }


    // ordenar y obtener el texto de la DDJJ
    const getTextoDDJJ = () => {
        let texto = [''];
        if (especieOperada?.ddjj_compra) {
            const result = textosDDJJ?.filter(
                txt => txt.tipo === especieOperada.ddjj_compra,
            );
            texto = result ? result[0].texto : [''];
        } else if (especieOperada?.ddjj_venta) {
            const result = textosDDJJ?.filter(
                txt => txt.tipo === especieOperada.ddjj_venta,
            );
            texto = result ? result[0].texto : [''];
        }
        return texto;
    };


    // Evalua si se acepto o no la DDJJ
    const condicionddjj = () => {
        if (
            (actualOption === TipoOperacion.Compra && especieOperada?.ddjj_compra) ||
            (actualOption === TipoOperacion.Venta && especieOperada?.ddjj_venta)
        ) {
            if (aceptoDDJJ) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    return {
        condicionddjj,
        getTextoDDJJ,
        setAceptoDDJJ,
        setCancelo,
        confirmo,
        especieOperada,
        precio,
        cantidad,
        setConfirmo,
        loading,
        error,
        nroOrden,
        tituloError,
        mensajeError,
        handleConfirmarOperar
    };
}

